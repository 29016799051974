import React from 'react';
import { CheckboxInput, CheckboxLbl, CheckboxMark } from "./style";

interface Props {
  onClick: () => void;
  value: boolean,
  defaultChecked?: boolean
}

const Checkbox: React.FunctionComponent<Props> = (props) => {
  return (
        <CheckboxLbl>
          <CheckboxInput type="checkbox" checked={props.value} defaultChecked={props.defaultChecked} onChange={() => {}} />
          <CheckboxMark onClick={props.onClick} />
        </CheckboxLbl>
  );
};

export default Checkbox;
