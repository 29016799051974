import styled from "styled-components";
import { poppinsFont } from "../../../styles/fonts";
import { grey } from "../../../styles/colors";

export const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 24px;
  
  @media (max-width: 500px) {
    margin-top: 50px;
  }
`

export const FooterCopyrightText = styled.span`
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${grey};
`
