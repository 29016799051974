import styled from "styled-components";
import { lightLightBlue } from "../../../../styles/colors";

export const TableHeadContainer = styled.th<{ showSort?: boolean}>`  
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${lightLightBlue};
  padding-bottom: 13px;
  text-align: left;    
  outline: none;  
  user-select: none;
  
  :first-of-type {    
    padding-left: 24px;
  }
  
  :last-of-type {
    text-align: right;
    padding-right: 24px;
  }
  
  :hover {    
    cursor: ${props => props.showSort ? "pointer" : ""};
  }
  
  img {
    margin-left: 6px;
  }
  
`
