import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormTitle, HomeFormContainer, ModalLayout } from "../../../../styles";
import ButtonSubmit from "../../../ui/ButtonSubmit";
import { getProducts } from "../../../../slices/productSlice";
import { RootState } from "../../../../store";
import { baseUrl } from "../../../../constants/api";
import {
  UserStartProductItem,
  UserStartProductText,
  UserStartProductWrapper,
} from "./style";
// @ts-ignore
import snsWebSdk from "@sumsub/websdk";
import UserForm from "../UserForm";
import UserFormProgress from "../UserProgress";
import UserFormFee from "../UserFormFee";
import {
  getRestoreUserLead,
  userAddLead,
  userAddLeadFee,
  userAddLeadProduct,
} from "../../../../slices/userLeadSlice";
import { validateEmail } from "../../../../constants/heplers";
import UserPaymentInfoContainer from "../../../../containers/UserPaymentInfo";
import RadioGroupMulti from "../../../ui/RadioGroupMulti";
import { UserType } from "../../../../types";
import { REGISTER } from "../../../../constants/routes";
import { SUM_SUB_API_URL } from "../../../../constants/sumSub";

const SWITCH_ITEMS = [
  {
    id: 1,
    name: "Individuals",
  },
  {
    id: 2,
    name: "Business entity",
  },
  {
    id: 4,
    name: "Token Holders",
  },
];

const UserStart = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const allSteps = 5;

  const { hash } = useParams<{ hash: string | undefined }>();

  let { id } = useParams<{ id: string | undefined }>();

  const [step, setStep] = useState(1);
  const [typeLead, setTypeLead] = useState(0);
  const [product, setProduct] = useState<Array<Number>>([]);

  const [email, setEmail] = useState<{ value: string; isError: boolean }>({
    value: "",
    isError: false,
  });

  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [wathsUp, setWhatsUp] = useState(false);
  const [telegram, setTelegram] = useState(false);
  const [onboardingFee, setOnboardingFee] = useState(0);
  const [transferFee, setTransferFee] = useState(0);
  const [monthFee, setMonthFee] = useState(0);
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);

  const { data } = useSelector((state: RootState) => state.product);
  const { sumsub_token, user_lead } = useSelector(
    (state: RootState) => state.userLead
  );

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  useEffect(
    () => {
      if (hash) {
        dispatch(getRestoreUserLead({ hash }));
        setStep(user_lead.step);
        setProduct(user_lead.product);
        setEmail({ value: user_lead.user.email, isError: false });
        if(user_lead.user) {
          setTypeLead(() => user_lead.user.type_lead);
        }

        if (user_lead.product) {
          dispatch(
            userAddLeadProduct({
              product_ids: user_lead.product,
              email: user_lead.user.email,
            })
          );
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [hash, dispatch, user_lead.step, user_lead.user.email]
  );

  const handleCallbackNext = (nextStep: number) => {
    setShowPaymentInfo(false);
    setStep(nextStep);
  };

  const handlerNext = async (callbackStep?: number) => {
    let nextStep = 1 + step;

    if (typeLead === UserType.TOKEN_HOLDER) {
      history.push(REGISTER + "/token");
    }

    if (nextStep === 3) {
      if (!validateEmail(email.value)) {
        setEmail({ ...email, isError: true });

        return;
      }

      await dispatch(
        userAddLead({
          type_lead: typeLead,
          ref_id: id,
          name,
          email: email.value,
          phone,
          company_name: companyName,
          company_website: companyWebsite,
          whatsup: wathsUp,
          telegram,
          linkedin: linkedIn
        })
      );

      if (typeLead === 1 && !showPaymentInfo) {
        return setShowPaymentInfo(true);
      } else {
        setShowPaymentInfo(false);
      }
    }

    if (nextStep === 4) {
      await dispatch(
        userAddLeadFee({
          onboarding_fee: onboardingFee,
          transaction_fee: transferFee,
          month_maintenance: monthFee,
          email: email.value,
        })
      );
    }

    if (nextStep === 5) {
      await dispatch(
        userAddLeadProduct({
          product_ids: product,
          email: email.value,
        })
      );
    }

    if (nextStep < allSteps) setStep(nextStep);
  };

  const onInputHandler = (
    type: string,
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value;

    if (type === "email") {
      setEmail({ ...email, value: value, isError: false });
    }

    if (type === "name") {
      setName(value);
    }

    if (type === "phone") {
      setPhone(value);
    }

    if (type === "company") {
      setCompanyName(value);
    }

    if (type === "companyWebsite") {
      setCompanyWebsite("https://" + value);
    }

    if (type === "linked") {
      setLinkedIn(value);
    }
  };

  const onFeeHandler = (type: string, value: number) => {
    if (type === "onBoarding") {
      setOnboardingFee(value);
    }

    if (type === "transfer") {
      setTransferFee(value);
    }

    if (type === "month") {
      setMonthFee(value);
    }
  };

  function launchWebSdk(
    apiUrl: string,
    flowName: string,
    accessToken: string,
    applicantEmail: string,
    applicantPhone: string,
    customI18nMessages: string
  ) {
    let snsWebSdkInstance = snsWebSdk
      .Builder(apiUrl, flowName)
      .withAccessToken(accessToken, (newAccessTokenCallback: any) => {
        // @ts-ignore
        let newAccessToken = this.newAccessToken(); // get a new token from your backend
        newAccessTokenCallback(newAccessToken);
      })
      .withConf({
        lang: "en",
        email: applicantEmail,
        phone: applicantPhone,
        i18n: customI18nMessages,
        onMessage: (type: any, payload: any) => {
          if (type === "idCheck.applicantStatus") {
            if (payload.reviewStatus === "completed") {
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1500);
            }
          }
        },
        uiConf: {
          customCss: "",
        },
        onError: (error: any) => {
          console.error("WebSDK onError", error);
        },
      })
      .build();
    snsWebSdkInstance.launch("#sumsub-websdk-container");

  }

  if (sumsub_token.token) {
    // @ts-ignore
    launchWebSdk(
      SUM_SUB_API_URL,
      typeLead === 2 ? "KYB_test" : "KYC-KB",
      sumsub_token.token
    );
  }

  if (hash && step > 4) {
    return (
      <>
        <UserFormProgress
          step={step}
          allSteps={allSteps}
        />
        <HomeFormContainer
          id="sumsub-websdk-container"
          style={{ marginTop: "28px", justifyContent: "center" , width: "100%", alignItems: "center"}}
        >
        </HomeFormContainer>
      </>
    );
  }

  return (
    <>
      <UserFormProgress
        step={step}
        allSteps={sumsub_token.token ? allSteps - 1 : allSteps}
      />
      <HomeFormContainer
        id="sumsub-websdk-container"
        style={{ marginTop: "28px" }}
      >
        {step === 1 && (
          <div>
            <FormTitle style={{ textAlign: "left" }}>
              Apply to the top banks and payment service providers worldwide in
              one click
            </FormTitle>

            <div style={{ marginBottom: "36px" }}>
              <RadioGroupMulti
                onChangeCallback={setTypeLead}
                items={SWITCH_ITEMS}
                initial={typeLead}
              />
            </div>
          </div>
        )}

        {showPaymentInfo && (
          <UserPaymentInfoContainer
            onNextCallback={handleCallbackNext}
            email={email.value}
          />
        )}

        {step === 2 && !showPaymentInfo && (
          <UserForm
            type={typeLead}
            onEmailChange={(e) => onInputHandler("email", e)}
            onNameChange={(e) => onInputHandler("name", e)}
            onPhoneChange={(e) => onInputHandler("phone", e)}
            onCompanyChange={(e) => onInputHandler("company", e)}
            onCompanyWebsiteChange={(e) => onInputHandler("companyWebsite", e)}
            onLinkedInChange={(e) => onInputHandler("linked", e)}
            onWhatsUpChange={() => setWhatsUp(!wathsUp)}
            onTelegramChange={() => setTelegram(!telegram)}
            email={email}
            name={name}
            phone={phone}
            company={companyName}
            companyWebsite={companyWebsite}
            whatsUp={wathsUp}
            telegram={telegram}
          />
        )}

        {step === 3 && (
          <UserFormFee
            onBoardingValue={onboardingFee}
            transferFeeValue={transferFee}
            monthFeeValue={monthFee}
            onChangeOnBoarding={(value) => onFeeHandler("onBoarding", value)}
            onChangeTransfer={(value) => onFeeHandler("transfer", value)}
            onChangeMonth={(value) => onFeeHandler("month", value)}
          />
        )}

        {step === 4 && (
          <div>
            <FormTitle style={{ textAlign: "left" }}>I want to get</FormTitle>
            <UserStartProductWrapper>
              {data.map((item) => {
                return (
                  <UserStartProductItem
                    key={item.id + "_product"}
                    onClick={() => {
                      if (product.includes(item.id)) {
                        setProduct(product.filter((p) => p !== item.id));
                      } else {
                        setProduct((p) => [...p, item.id]);
                      }
                    }}
                    isSelected={product.includes(item.id)}
                  >
                    <img
                      height={48}
                      width={48}
                      src={`${baseUrl}${item.icon}`}
                      alt={item.name}
                    />
                    <UserStartProductText>{item.name}</UserStartProductText>
                  </UserStartProductItem>
                );
              })}
            </UserStartProductWrapper>
          </div>
        )}

        {step === 5 && <div />}

        {!showPaymentInfo && (
          <div style={{ width: "100%" }}>
            <ButtonSubmit
              title="Next"
              onClick={handlerNext}
              isDisable={typeLead === 0}
            />
          </div>
        )}

        <ModalLayout isShow={step === 14}>
          <div style={{ margin: "0 auto" }}>
            <HomeFormContainer>
              <FormTitle style={{ textAlign: "left" }}>
                Thank you, your application is under consideration.
              </FormTitle>
              <ButtonSubmit title="Close" onClick={() => setStep(1)} />
            </HomeFormContainer>
          </div>
        </ModalLayout>
      </HomeFormContainer>
    </>
  );
};

export default UserStart;
