import styled from "styled-components";
import { poppinsFont } from "../../../styles/fonts";

export const RadioGroupContainer = styled.div<{ isActive: boolean}>`
  border: ${props => props.isActive ? "1px solid #253B86" : "1px solid rgba(146, 157, 194, 0.2)"} ;
  box-sizing: border-box;
  border-radius: 12px;
  height: 58px;
  margin-bottom: 16px;
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  
  :last-of-type {
    margin-bottom: 0;
  }
  
  :hover {
    cursor: pointer;
    border: ${ props => props.isActive ? "1px solid #253B86" : "1px solid rgba(146, 157, 194, 1)"} ;
    
    div {
      div {
        border: 1px solid rgba(127, 127, 127, 0.4);
      }
    }
  }
`

export const RadioGroupWrapper = styled.div`
  display: flex;
  align-items: center;

`

export const RadioGroupText = styled.p`
  font-family: ${poppinsFont};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #171717;
  margin: 0;
  padding: 0;
  
  span {
    margin: 0;
    margin-left: 1px;
    padding: 0;
    color: #FE2828;
  }
`

export const RadioGroupBgCheck= styled.div<{ isActive: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: ${props => props.isActive ? "1px solid rgba(127, 127, 127, 0.4)" : "1px solid rgba(127, 127, 127, 0.2)"};
  box-sizing: border-box;
  border-radius: 34px;
  margin-right: 12px;
  background-color: #FFFFFF;

  :hover {
    border: 1px solid rgba(127, 127, 127, 0.4);
  }
`

export const RadioGroupCheck= styled.div`
  height: 10px;
  width: 10px;
  background: #1A1E5D;
  border-radius: 34px;
`
