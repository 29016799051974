import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Status,
  UserLeadData,
  UserLeadFeeData,
  UserLeadProductData,
} from "../types";
import {
  userAddLeadApi,
  userAddLeadFeeApi,
  userAddLeadProductApi, userLeadPaymentInfoApi,
  userLeadRestoreApi,
} from "../api/userLead";

export interface UserLeadState {
  error: boolean;
  message: string;
  status: Status;
  sumsub_token: { token: string; userId: string };
  user_lead: {
    product: Array<number>,
    user: UserLeadData,
    step: number
  }
}

const initialState: UserLeadState = {
  error: false,
  message: "",
  status: Status.IDLE,
  sumsub_token: { token: "", userId: "" },
  user_lead: {
    product: [],
    step: 1,
    user: {
      email: "",
      phone: "",
      company_website: "",
      company_name: "",
      name: "",
      whatsup: false,
      telegram: false,
      type_lead: 0,
      ref_id: "0"
    }
  }
};

export const userAddLead = createAsyncThunk(
  "userLead/add-lead",
  async (data: UserLeadData) => {
    return await userAddLeadApi(data);
  }
);

export const userAddLeadFee = createAsyncThunk(
  "userLead/add-lead-fee",
  async (data: UserLeadFeeData) => {
    return await userAddLeadFeeApi(data);
  }
);

export const userAddLeadProduct = createAsyncThunk(
  "userLead/add-lead-product",
  async (data: UserLeadProductData) => {
    return await userAddLeadProductApi(data);
  }
);

export const getRestoreUserLead = createAsyncThunk(
  "userLead/get-restore-user-lead",
  async (data: { hash: string }) => {
    return await userLeadRestoreApi(data);
  }
);

export const userAddPaymentIfo = createAsyncThunk(
    "userLead/add-lead-payment-info",
    async (data: {
      citizenship: number;
      residence: number;
      in_payment: string;
      out_payment: string;
      month_payment: string;
      email: string;
    } ) => {
      return await userLeadPaymentInfoApi(data);
    }
);

export const userLeadSlice = createSlice({
  name: "userLead",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userAddLead.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
    });
    builder.addCase(userAddLeadFee.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
    });
    builder.addCase(userAddLeadProduct.fulfilled, (state, action) => {
      state.sumsub_token = action.payload?.sumsub_token;
    });

    builder.addCase(getRestoreUserLead.fulfilled, (state, action) => {
      state.user_lead = action.payload;
      if(action.payload.sumsub_token) {
        state.sumsub_token = action.payload.sumsub_token
      }
    });
  },
});

export default userLeadSlice.reducer;
