import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { DashboardContent, DashboardLayout } from "../../../../../styles";
import DashboardHeader from "../../../../../components/layout/Dashboard/Header";
import DashboardManagerAgentLeads from "../../../../../components/layout/Dashboard/Manager/AgentLeads";
import { useParams } from "react-router-dom";

const DashboardManagerAgentLeadPage = () => {
  let { userId } = useParams<{ userId: string | undefined }>();

  const {
    data: { name, ref_link, balance },
  } = useSelector((state: RootState) => state.user);

  return (
    <DashboardLayout>
      <DashboardContent>
        <DashboardHeader
          refLink={ref_link}
          showRef
          showBalance
          name={name}
          balance={balance}
        />
        <DashboardManagerAgentLeads userLeadId={parseInt(userId || "0")} />
      </DashboardContent>
    </DashboardLayout>
  );
};

export default DashboardManagerAgentLeadPage;
