import styled from "styled-components";
import { poppinsFont } from "../../../styles/fonts";
import { black, blue, grey } from "../../../styles/colors";
import { Fade } from "../../../styles/animations";
import Icon from "../Icon";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
`;

export const InputRowWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  font-family: ${poppinsFont};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${black};
  margin-bottom: 12px;
  
  span {
    margin: 0;
    margin-left: 1px;
    padding: 0;
    color: #FE2828;
  }
  }
`;

export const InputElement = styled.input<{
  isError?: boolean;
  isPrePend?: boolean;
}>`
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid rgba(127, 127, 127, 0.3);
  box-sizing: border-box;
  border-radius: 6px;
  border-bottom-left-radius: ${(props) => (props.isPrePend ? "0" : "6px")};
  border-top-left-radius: ${(props) => (props.isPrePend ? "0" : "6px")};
  width: 100%;
  color: ${black};
  height: 46px;
  padding: 13px;
  box-shadow: ${(props) =>
    props.isError ? "0 0 0 2px rgba(146, 157, 194, 0.3)" : "none"};
  outline: none;
  transition: all 0.3s cubic-bezier(0.497, 0.51, 0.25, 1) 0s;

  :focus {
    box-shadow: ${(props) =>
      props.isPrePend ? "none" : "0 0 0 2px rgba(146, 157, 194, 0.3)"};
  }

  ::placeholder {
    color: ${grey};
  }
`;

export const InputForgotLink = styled.a`
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${blue};
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;

export const InputErrorText = styled.a<{ isHint?: boolean }>`
  display: flex;
  align-items: flex-start;
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => (props.isHint ? "#171717" : "#FE2828")};
  margin: 0;
  margin-top: 6px;
  animation: ${Fade};
  animation-duration: 0.3s;
  animation-fill-mode: forwards;

  img {
    margin-right: 6px;
  }
`;

export const InputPrePend = styled.div<{ isFocus: boolean }>`
  font-family: ${poppinsFont};
  padding: 12px;
  border-bottom: 1px solid rgba(127, 127, 127, 0.3);
  border-top: 1px solid rgba(127, 127, 127, 0.3);
  border-left: 1px solid rgba(127, 127, 127, 0.3);
  background-color: rgba(224, 232, 254, 0.3);
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  color: #929dc2;
  height: 46px;
`;

export const InputPrePendWrap = styled.div<{ isFocus: boolean }>`
  display: flex;
  align-items: flex-start;
  width: 100%;
  box-shadow: ${(props) =>
    props.isFocus ? "0 0 0 2px rgba(146, 157, 194, 0.3)" : "none"};
  transition: all 0.3s cubic-bezier(0.497, 0.51, 0.25, 1) 0s;
  border-radius: 6px;
`;

export const InputEyeWrapper = styled.a`
  position: absolute;
  right: 13px;
  top: 12.5px;
  vertical-align: bottom;

  svg {
    fill: #929dc2;
  }

  :hover {
    cursor: pointer;
    svg {
      fill: rgba(146, 157, 194, 0.6);
    }
  }
`;

const Svg = styled(Icon)`
  height: 20px;
  width: 20px;
`;

export const InputEyeOff = () => (
  <Svg>
    <path d="M3.76663 4.94481L1.1608 2.33981L2.33996 1.16064L18.8391 17.6606L17.66 18.839L14.9016 16.0806C13.436 17.0103 11.7356 17.5026 9.99996 17.4998C5.50663 17.4998 1.7683 14.2665 0.984131 9.99981C1.34249 8.05874 2.31882 6.28556 3.76746 4.94481H3.76663ZM12.2975 13.4765L11.0775 12.2565C10.6108 12.4797 10.0864 12.5528 9.57657 12.4655C9.0667 12.3782 8.59647 12.1349 8.23069 11.7691C7.86491 11.4033 7.62161 10.9331 7.53431 10.4232C7.44702 9.91333 7.52003 9.38894 7.7433 8.92231L6.5233 7.70231C5.99306 8.50355 5.75602 9.46342 5.85232 10.4194C5.94862 11.3753 6.37234 12.2687 7.05173 12.948C7.73112 13.6274 8.62443 14.0512 9.58039 14.1475C10.5364 14.2438 11.4962 14.0067 12.2975 13.4765ZM6.64496 3.13314C7.68413 2.72481 8.81663 2.49981 9.99996 2.49981C14.4933 2.49981 18.2316 5.73314 19.0158 9.99981C18.7605 11.3879 18.1865 12.6978 17.3391 13.8265L14.1225 10.6098C14.218 9.96616 14.1615 9.30907 13.9574 8.69119C13.7533 8.07331 13.4074 7.51182 12.9472 7.0517C12.4871 6.59158 11.9256 6.24562 11.3078 6.04154C10.6899 5.83746 10.0328 5.78093 9.38913 5.87648L6.64496 3.13398V3.13314Z" />
  </Svg>
);

export const InputEyeOn = () => (
  <Svg>
    <path d="M0.984375 10C1.76771 5.73333 5.50688 2.5 10.0002 2.5C14.4935 2.5 18.2319 5.73333 19.016 10C18.2327 14.2667 14.4935 17.5 10.0002 17.5C5.50688 17.5 1.76854 14.2667 0.984375 10ZM10.0002 14.1667C11.1053 14.1667 12.1651 13.7277 12.9465 12.9463C13.7279 12.1649 14.1669 11.1051 14.1669 10C14.1669 8.89493 13.7279 7.83512 12.9465 7.05372C12.1651 6.27232 11.1053 5.83333 10.0002 5.83333C8.89514 5.83333 7.83533 6.27232 7.05393 7.05372C6.27253 7.83512 5.83354 8.89493 5.83354 10C5.83354 11.1051 6.27253 12.1649 7.05393 12.9463C7.83533 13.7277 8.89514 14.1667 10.0002 14.1667ZM10.0002 12.5C9.33717 12.5 8.70128 12.2366 8.23244 11.7678C7.7636 11.2989 7.50021 10.663 7.50021 10C7.50021 9.33696 7.7636 8.70107 8.23244 8.23223C8.70128 7.76339 9.33717 7.5 10.0002 7.5C10.6632 7.5 11.2991 7.76339 11.768 8.23223C12.2368 8.70107 12.5002 9.33696 12.5002 10C12.5002 10.663 12.2368 11.2989 11.768 11.7678C11.2991 12.2366 10.6632 12.5 10.0002 12.5Z" />
  </Svg>
);
