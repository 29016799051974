import styled from "styled-components";
import { black, grey } from "../../../styles/colors";
import { poppinsFont } from "../../../styles/fonts";
import arrowDownImg from "../../../assets/select-arrow-down.svg";
import closeIcon from "../../../assets/select-close-icon.svg";
import checkedIcon from "../../../assets/checked-icon.svg";

export const MultiSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const MultiSelectInput = styled.input<{ customPlaceholder?: boolean}>`
  border: 1px solid rgba(127, 127, 127, 0.3);
  box-sizing: border-box;
  border-radius: 6px;
  height: 46px;
  width: 100%;
  padding: 13px 13px 13px 16px;
  outline: none;
  font-weight: normal;
  font-family: ${poppinsFont};
  font-size: 14px;
  line-height: 20px;
  color: ${black};
  transition: all 0.3s cubic-bezier(0.497, 0.51, 0.25, 1) 0s;

  ::placeholder {
    color: ${props => props.customPlaceholder ? "#1A1E5D" : grey};
  }
  
  :hover {
    box-shadow: 0 0 0 2px rgba(146, 157, 194, 0.3);
  }
  
  :focus {
    box-shadow: 0 0 0 2px rgba(146, 157, 194, 0.3);
  }
`

export const MultiSelectUpDown = styled.img.attrs({
  src: arrowDownImg,
  alt: "Open / Close"
})<{ isOpen: boolean}>`
  position: absolute;
  top: 50%;
  right: 13px;
  transform: ${props => props.isOpen ? "translateY(-50%) rotate(180deg)" : "translateY(-50%)" };
  transition: all 0.2s linear;
  
  :hover {
    cursor: pointer;
  }
`

export const MultiSelectedItems = styled.div`
  display: flex;  
  flex-wrap: wrap;  
`

export const MultiSelectedItem = styled.div`  
  display: flex;
  align-items: center;
  height: 28px;
  padding: 6px;
  background: rgba(224, 232, 254, 0.6);  
  border-radius: 4px;
  margin-right: 6px;
  margin-top: 6px;
  
  :last-of-type {
    margin-right: 0;
  }
`
export const MultiSelectedImg = styled.img`
  width: 24px;
  max-height: 16px;
`

export const MultiSelectedText = styled.p`
  margin: 0;
  padding: 0;
  margin-left: 10px;
  margin-right: 10px;
  font-family: ${poppinsFont};
  font-size: 14px;
  line-height: 20px;
  color: #171717;
`

export const MultiSelectedClose = styled.img.attrs({
  src: closeIcon,
  alt: "Delete"
})`  
  :hover {
    cursor: pointer;
  }
`
export const MultiSelectList = styled.ul<{ isVisible: boolean }>`
  display: ${props => props.isVisible ? "block" : "none"};
  position: absolute;
  top: 46px;
  list-style-type: none;
  background: #FFFFFF;
  box-shadow: 2px 2px 8px rgba(37, 59, 134, 0.1);
  border-radius: 6px;
  z-index: 2;
  padding: 0;
  margin: 0;
  margin-top: 4px;
  width: 100%; 
  max-height: 190px;  
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    background: #E9EBF3;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #253B86;
  }
`

export const MultiSelectListItem = styled.li<{ isSelected: boolean }>`
  display: flex;
  align-items: center;  
  justify-content: space-between;
  padding: 6px 16px;  
  height: 32px;
  background-color: ${props => props.isSelected ? "rgba(224, 232, 254, 0.6)" : ""};
  
  p {
    color: ${props => props.isSelected ? "#7F7F7F" : ""};
  }
  
  :hover {
    cursor: pointer;
    background-color: rgba(224, 232, 254, 0.6);
    
    p {
      color: #7F7F7F;
    }
  }
`
export const MultiSelectListItemWrap = styled.div`
  display: flex;
  align-items: center;
`

export const MultiSelectedItemChecked = styled.img.attrs({
  src: checkedIcon,
  alt: "Delete"
})`  
`
