import React from "react";
import {
  ErrorMessageIcon,
  ErrorMessageText,
  ErrorMessageWrapper,
} from "./styles";

interface Props {
  errorText: string | undefined;
}

const ErrorMessage: React.FunctionComponent<Props> = ({ errorText }) => {
  return (
    <ErrorMessageWrapper>
      <ErrorMessageIcon />
      <ErrorMessageText>{errorText}</ErrorMessageText>
    </ErrorMessageWrapper>
  );
};

export default ErrorMessage;
