import styled from "styled-components";
import { poppinsFont } from "../../../styles/fonts";

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
`;

export const FilterItem = styled.div<{ isSelect: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  background: ${(props) => (props.isSelect ? "#EFF3FE" : "#FFFFFF")};
  border-radius: 6px;
  margin-right: 8px;

  :hover {
    cursor: pointer;
    
    p {
      color: ${(props) => (props.isSelect ? "#171717" : "#929dc2")};  
    }
  }

  :last-of-type {
    margin-right: 0;
  }
`;

export const FilterText = styled.p`
  font-family: ${poppinsFont};
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #171717;

`;

export const FilterTitle = styled.p`
  font-family: ${poppinsFont};
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #929dc2;
  margin-right: 16px;
`;
