import React from "react";
import HeaderHome from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import {
  FormSubTitle,
  FormTitle,
  HomeFormContainer,
  HomeLayout,
} from "../../styles";
import { RegisterConfirmEmail } from "../../components/layout/Register/RegisterConfirm/style";
import { globalFont } from "../../styles/fonts";

const Page404 = () => {
  return (
    <HomeLayout>
      <HeaderHome />
      <HomeFormContainer>
        <FormTitle
          style={{
            fontSize: "24px",
            lineHeight: "32px",
            fontFamily: `${globalFont}`,
            marginBottom: "12px",
          }}
        >
          Page not found!
        </FormTitle>
        <FormSubTitle
          style={{
            fontSize: "20px",
            lineHeight: "28px",
            fontFamily: `${globalFont}`,
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          Sorry, but the page you were looking for could not be found.
        </FormSubTitle>

        <FormSubTitle
          style={{
            marginTop: "36px",
            fontSize: "16px",
            lineHeight: "28px",
            fontFamily: `${globalFont}`,
          }}
        >
          Return to our{" "}
          <RegisterConfirmEmail
            href="https://mig.finance/"
            style={{ fontSize: "16px", lineHeight: "28px" }}
          >
            home page
          </RegisterConfirmEmail>{" "}
          or drop our{" "}
          <RegisterConfirmEmail
            href="mailto:support@mig.finance"
            style={{ fontSize: "16px", lineHeight: "28px" }}
          >
            support team
          </RegisterConfirmEmail>{" "}
          if you can't find what you are looking for.
        </FormSubTitle>
      </HomeFormContainer>
      <Footer />
    </HomeLayout>
  );
};

export default Page404;
