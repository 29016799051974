import { GlobalStyle } from "./styles";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomePage from "./pages/Home";
import { Provider, useDispatch } from "react-redux";
import store from "./store";
import { useEffect } from "react";
import RegisterPage from "./pages/Register";
import {
  DASHBOARD,
  DASHBOARD_ADMIN_AGENTS, DASHBOARD_ADMIN_MANAGER_AGENTS, DASHBOARD_ADMIN_MANAGER_LEADS, DASHBOARD_ADMIN_MANAGERS,
  DASHBOARD_ADMIN_PAYMENTS,
  DASHBOARD_AGENTS, DASHBOARD_LEADS_VIEW, DASHBOARD_MANAGER, DASHBOARD_MANAGER_AGENT_LEADS, DASHBOARD_MANAGER_AGENTS,
  DASHBOARD_PAYMENTS, DASHBOARD_PAYMENTS_HISTORY, DASHBOARD_STAT,
  HOME, LOGIN,
  PASSWORD_NEW,
  REGISTER,
  REGISTER_CHOOSE,
  REGISTER_CONFIRM,
  USER_START,
} from "./constants/routes";
import RegisterConfirmPage from "./pages/Register/RegisterConfirm";
import PasswordNewPage from "./pages/PasswordNew";
import UserStartPage from "./pages/User/UserStart";
import RegisterChoosePage from "./pages/Register/RegisterChoose";
import DashboardPage from "./pages/Dashboard";
import DashboardAdminPage from "./pages/Dashboard/Admin";
import { getUser } from "./slices/userSlice";
import { getUserToken } from "./constants/api";
import DashboardPaymentsPage from "./pages/Dashboard/Payments";
import DashboardAdminAgentsPage from "./pages/Dashboard/Admin/Agents";
import DashboardAdminPaymentsPage from "./pages/Dashboard/Admin/Payments";
import Page404 from "./pages/404";
import PrivateRoute from "./components/PrivateRoute";
import DashboardAdminViewLeadsPage from "./pages/Dashboard/Admin/ViewLeads";
import LoginPage from "./pages/Login";
import DashboardManagerPage from "./pages/Dashboard/Manager";
import DashboardManagerAgentsPage from "./pages/Dashboard/Manager/Agents";
import DashboardAdminManagersPage from "./pages/Dashboard/Admin/Managers";
import DashboardAdminManagerLeadsPage from "./pages/Dashboard/Admin/Managers/AdminManagerLeads";
import DashboardAdminManagerAgentsPage from "./pages/Dashboard/Admin/Managers/AdminManagerAgents";
import DashboardPaymentsHistoryPage from "./pages/Dashboard/Payments/History";
import DashboardManagerAgentLeadPage from "./pages/Dashboard/Manager/Agents/Leads";
import DashboardStatisticsPage from "./pages/Dashboard/Statistics";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const checkExistingUser = async () => {
      if (getUserToken()) {
        dispatch(getUser());
      }
    };
    checkExistingUser();
  }, [dispatch]);

  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Switch>
          <Route exact path={HOME} component={HomePage} />
          <Route exact path={LOGIN} component={LoginPage} />
          <Route exact path={REGISTER} component={RegisterPage} />
          <Route exact path={REGISTER + "/:userType"} component={RegisterPage} />
          <Route exact path={REGISTER + "/:userType/hash/:hash"} component={RegisterPage} />
          <Route
            exact
            path={REGISTER_CONFIRM}
            component={RegisterConfirmPage}
          />
          <Route exact path={REGISTER_CHOOSE} component={RegisterChoosePage} />
          <Route exact path={PASSWORD_NEW} component={PasswordNewPage} />
          <Route exact path={USER_START} component={UserStartPage} />
          <Route exact path={USER_START + '/hash/:hash'} component={UserStartPage} />

          <PrivateRoute exact path={DASHBOARD} component={DashboardAdminPage} />
          <PrivateRoute exact path={DASHBOARD_LEADS_VIEW + ":userLeadId"} component={DashboardAdminViewLeadsPage} />

          <PrivateRoute
            exact
            path={DASHBOARD_ADMIN_AGENTS}
            component={DashboardAdminAgentsPage}
          />
          <PrivateRoute
              exact
              path={DASHBOARD_ADMIN_AGENTS + "/:userLeadId"}
              component={DashboardAdminAgentsPage}
          />
          <PrivateRoute
            exact
            path={DASHBOARD_ADMIN_PAYMENTS}
            component={DashboardAdminPaymentsPage}
          />
          <PrivateRoute
              exact
              path={DASHBOARD_ADMIN_MANAGERS}
              component={DashboardAdminManagersPage}
          />
          <PrivateRoute
              exact
              path={DASHBOARD_ADMIN_MANAGER_LEADS + ":userId"}
              component={DashboardAdminManagerLeadsPage}
          />
          <PrivateRoute
              exact
              path={DASHBOARD_ADMIN_MANAGER_AGENTS + ":userId"}
              component={DashboardAdminManagerAgentsPage}
          />
          <PrivateRoute
              exact
              path={DASHBOARD_STAT}
              component={DashboardStatisticsPage}
          />

          <PrivateRoute exact path={DASHBOARD_AGENTS} component={DashboardPage} />

          <PrivateRoute
            exact
            path={DASHBOARD_PAYMENTS}
            component={DashboardPaymentsPage}
          />
          <PrivateRoute
              exact
              path={DASHBOARD_PAYMENTS_HISTORY}
              component={DashboardPaymentsHistoryPage}
          />

          <PrivateRoute component={DashboardManagerPage} path={DASHBOARD_MANAGER} exact />
          <PrivateRoute component={DashboardManagerAgentsPage} path={DASHBOARD_MANAGER_AGENTS} exact />
          <PrivateRoute component={DashboardManagerAgentLeadPage} path={DASHBOARD_MANAGER_AGENT_LEADS + ":userId"} exact />

          <Route path="*"  component={Page404}/>
        </Switch>
      </BrowserRouter>
    </>
  );
};

const ProviderWrapper: React.FunctionComponent = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default ProviderWrapper;
