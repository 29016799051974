import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "../slices/userSlice";
import { countriesSlice } from "../slices/countrySlice";
import { productSlice } from "../slices/productSlice";
import { leadSlice } from "../slices/leadtSlice";
import { forgotSlice } from "../slices/forgotSlice";
import { registerSlice } from "../slices/registerSlice";
import { userLeadSlice } from "../slices/userLeadSlice";
import { paymentsSlice } from "../slices/paymentsSlice";
import { userEditSlice } from "../slices/userEditSlice";


const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    userEdit: userEditSlice.reducer,
    countries: countriesSlice.reducer,
    product: productSlice.reducer,
    leads: leadSlice.reducer,
    forgot: forgotSlice.reducer,
    register: registerSlice.reducer,
    userLead: userLeadSlice.reducer,
    payments: paymentsSlice.reducer
  }
});

export type RootState = ReturnType<typeof store.getState>

export default store;
