import React from 'react';
import { UserFormProgressWrapper, UserProgressContainer, UserProgressItem } from "./styles";
import {ReactComponent as CircleCheck} from "../../../../assets/circle-check.svg";

interface Props {
  step: number;
  allSteps: number;
}

const UserFormProgress: React.FunctionComponent<Props> = (props) => {
  const percent = (100 / props.allSteps) * props.step

  return (
      <UserFormProgressWrapper isFinish={props.allSteps === props.step}>
        <UserProgressContainer>
          <UserProgressItem width={percent} />
        </UserProgressContainer>
        <CircleCheck />
      </UserFormProgressWrapper>
  );
};

export default UserFormProgress;
