import axios, { AxiosRequestConfig } from "axios";
import {
  baseUrl,
  mePath,
  productsPath,
  countriesPath,
  userSetParamPath,
  userGetLeadsPath,
  userGetLeadsPaymentsPath,
  leadChangeStatusPath,
  adminGetPaymentSystemsPath,
  adminGetAgentPath,
  userChangeStatusPath,
  userChangeBalancePath,
  userGetParamPath,
  countriesPublicPath,
  adminSetUserPercentPath,
  getManagerAgentPath,
  managerAssignAgentPath,
  getManagerManagersPath,
  adminGetManagersPath,
  adminGetSumSubRequestPath,
  userGetInfoPath,
  userUpdateInfoPath,
} from "../constants/api";
import { axiosGlobalConfig, getAuthHeader } from "./auth";
import { FilterOrderType, OrderType, UserParamData } from "../types";

export type ChangeBalance = {
  user_id: number | string;
  balance: string | number;
  type: string | number;
};

export type ChangeStatus = {
  user_id: number | string;
  status: string;
};

export const getUserApi = async (token: string) => {
  const config: AxiosRequestConfig = {
    headers: getAuthHeader(token),
  };

  try {
    const response = await axios.post(`${baseUrl}${mePath}`, {}, config);

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const getProductsApi = async () => {
  try {
    const response = await axios.post(`${baseUrl}${productsPath}`);

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const getCountriesApi = async (token: string) => {
  try {
    const response = await axios.post(
      `${baseUrl}${countriesPath}`,
      {},
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const getCountriesPublicApi = async () => {
  try {
    const response = await axios.post(
      `${baseUrl}${countriesPublicPath}`,
      {},
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const setParamApi = async (token: string, data: UserParamData) => {
  const config: AxiosRequestConfig = {
    headers: getAuthHeader(token),
  };

  try {
    const response = await axios.post(
      `${baseUrl}${userSetParamPath}`,
      data,
      config
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const getUserParamApi = async () => {
  try {
    const response = await axios.post(
      `${baseUrl}${userGetParamPath}`,
      {},
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const getUserLeadsApi = async (data: {
  filter: string;
  order?: { type: string; field: string };
}) => {
  try {
    const response = await axios.post(
      `${baseUrl}${userGetLeadsPath}`,
      data,
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const getUserPaymentLeadsApi = async (data: FilterOrderType) => {
  try {
    const response = await axios.post(
      `${baseUrl}${userGetLeadsPaymentsPath}`,
      data,
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const leadChangeStatusApi = async (
  status: string,
  id: string | number
) => {
  try {
    const response = await axios.post(
      `${baseUrl}${leadChangeStatusPath}`,
      { status, id },
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const userChangeStatusApi = async (
  token: string,
  data: ChangeStatus
) => {
  const config: AxiosRequestConfig = {
    headers: getAuthHeader(token),
  };

  try {
    const response = await axios.post(
      `${baseUrl}${userChangeStatusPath}`,
      data,
      config
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const userChangeBalanceApi = async (
  token: string,
  data: ChangeBalance
) => {
  const config: AxiosRequestConfig = {
    headers: getAuthHeader(token),
  };

  try {
    const response = await axios.post(
      `${baseUrl}${userChangeBalancePath}`,
      data,
      config
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

/////////////ADMIN
export const adminGetAgentsApi = async (data?: FilterOrderType) => {
  try {
    const response = await axios.post(
      `${baseUrl}${adminGetAgentPath}`,
      data,
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const adminGetManagersApi = async (data?: OrderType) => {
  try {
    const response = await axios.post(
      `${baseUrl}${adminGetManagersPath}`,
      data,
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const adminGetPaymentSystemsApi = async (data?: {
  order?: { type: string; field: string };
}) => {
  try {
    const response = await axios.post(
      `${baseUrl}${adminGetPaymentSystemsPath}`,
      data,
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const adminSetUserPercentApi = async (data: {
  percent: number;
  id: number;
}) => {
  try {
    const response = await axios.post(
      `${baseUrl}${adminSetUserPercentPath}`,
      data,
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

//MANAGER
export const getManagerAssignAgentsApi = async () => {
  try {
    const response = await axios.post(
      `${baseUrl}${getManagerAgentPath}`,
      {},
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const managerAssignAgentApi = async (data: {
  agent_id: number;
  user_lead_id: number;
  type: string;
}) => {
  try {
    const response = await axios.post(
      `${baseUrl}${managerAssignAgentPath}`,
      data,
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const getManagerManagersApi = async () => {
  try {
    const response = await axios.post(
      `${baseUrl}${getManagerManagersPath}`,
      {},
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const adminGetSumSubRequestApi = async (data: { user_id: number }) => {
  try {
    const response = await axios.post(
      `${baseUrl}${adminGetSumSubRequestPath}`,
      data,
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const userGetInfoApi = async (data: { user_id: number }) => {
  try {
    const response = await axios.post(
      `${baseUrl}${userGetInfoPath}`,
      data,
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const userUpdateInfoApi = async (data: {
  user_id: number;
  data: any;
}) => {
  try {
    const response = await axios.post(
      `${baseUrl}${userUpdateInfoPath}`,
      data,
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};
