import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  adminGetAgentsApi,
  adminGetManagersApi,
  adminGetPaymentSystemsApi, adminGetSumSubRequestApi,
  adminSetUserPercentApi,
  ChangeBalance,
  ChangeStatus,
  getManagerAssignAgentsApi,
  getManagerManagersApi,
  getUserApi,
  getUserParamApi,
  managerAssignAgentApi,
  userChangeBalanceApi,
  userChangeStatusApi,
} from "../api/user";
import {
  AgentAssignType,
  AgentType, FilterOrderType,
  LoginData,
  ManagerType, OrderType,
  PaymentSystemType,
  Status,
  UserInfo,
  UserParamData,
} from "../types";
import { getUserToken, removeUserToken } from "../constants/api";
import { loginApi, logoutApi } from "../api/auth";
import { clearReg } from "./registerSlice";
import { leadsAssignClientApi } from "../api/leads";

export interface UserState {
  error: boolean;
  message: string;
  data: UserInfo;
  admin_data: any;
  status: Status;
  isAuth: boolean;
  status_balance: Status;
  status_change: Status;
  sumsub_token: { token: string; userId: string };
  admin_data_payments: Array<PaymentSystemType>;
  admin_data_agents: Array<AgentType>;
  admin_data_managers: Array<ManagerType>;
  user_param: UserParamData;
  manager_agents_assign: Array<AgentAssignType>;
  managerManagers: Array<AgentAssignType>;
}

const initialState: UserState = {
  status: Status.IDLE,
  error: false,
  message: "",
  isAuth: false,
  data: {
    id: 0,
    email: "",
    name: "",
    type: null,
    is_admin: false,
    created_at: "",
    avatar_url: "",
    ref_link: null,
    balance: 0,
  },
  status_balance: Status.IDLE,
  status_change: Status.IDLE,
  sumsub_token: { token: "", userId: "" },
  admin_data: [],
  admin_data_payments: [],
  admin_data_agents: [],
  admin_data_managers: [],
  user_param: {
    type: 0,
    ids: [],
  },
  manager_agents_assign: [],
  managerManagers: [],
};

export const getUser = createAsyncThunk("user/get", async () => {
  const token = getUserToken();
  return await getUserApi(token);
});

export const login = createAsyncThunk(
  "user/login",
  async (data: LoginData, { dispatch }) => {
    const response = await loginApi(data);
    await dispatch(getUser());
    return response;
  }
);

export const logout = createAsyncThunk(
  "user/logout",
  async (_arg, { dispatch }) => {
    const token = getUserToken();
    await dispatch(clearReg());
    return await logoutApi(token);
  }
);

export const adminGetAgents = createAsyncThunk(
  "user/admin-get-agents",
  async (data?: FilterOrderType) => {
    return await adminGetAgentsApi(data);
  }
);

export const adminGetManagers = createAsyncThunk(
  "user/admin-get-managers",
  async (data?: OrderType) => {
    return await adminGetManagersApi(data);
  }
);

export const adminGetPaymentSystems = createAsyncThunk(
  "user/admin-get-payment-systems",
  async (data?: OrderType) => {
    return await adminGetPaymentSystemsApi(data);
  }
);

export const userChangeBalance = createAsyncThunk(
  "user/admin-change-balance",
  async (data: ChangeBalance) => {
    const token = getUserToken();
    return await userChangeBalanceApi(token, data);
  }
);

export const userChangeStatus = createAsyncThunk(
  "user/admin-change-status",
  async (data: ChangeStatus) => {
    const token = getUserToken();
    return await userChangeStatusApi(token, data);
  }
);

export const leadsAssignClient = createAsyncThunk(
  "user/admin-leads-assign-client",
  async (data: { user_lead_id: number; user_id: number; cost: number }) => {
    return await leadsAssignClientApi(data);
  }
);

export const getUserParam = createAsyncThunk(
  "user/get-user-param",
  async () => {
    return await getUserParamApi();
  }
);

export const adminSetUserPercent = createAsyncThunk(
  "user/set-admin-user-percent",
  async (data: { percent: number; id: number }) => {
    return await adminSetUserPercentApi(data);
  }
);

//MANAGER
export const getManagerAssignAgents = createAsyncThunk(
  "user/manager/get-assign-agents",
  async () => {
    return await getManagerAssignAgentsApi();
  }
);

export const managerAssignAgent = createAsyncThunk(
  "user/manager/assign-agent",
  async (data: { agent_id: number; user_lead_id: number; type: string }) => {
    return await managerAssignAgentApi(data);
  }
);

export const getManagerManagers = createAsyncThunk(
  "user/manager/get-managers",
  async () => {
    return await getManagerManagersApi();
  }
);

export const adminGetSumSubRequest = createAsyncThunk(
    "user/manager/admin-get-request-sumsub",
    async (data: {user_id: number}) => {
      return await adminGetSumSubRequestApi(data);
    }
);


export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.status = Status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.rejected, (state, action) => {
      state.isAuth = false;
    });

    builder.addCase(getUser.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      state.data = action.payload;
      state.isAuth = true;
      state.message = "";
    });

    builder.addCase(login.pending, (state, action) => {
      state.status = Status.IDLE;
      state.message = "";
    });
    builder.addCase(login.rejected, (state, action) => {
      state.status = Status.FAILED;
      state.isAuth = false;
      state.message = action.error.message || "";
      removeUserToken();
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      state.isAuth = true;
      state.data.is_admin = action.payload?.data.is_admin || null;
      state.data.type = action.payload?.data.type || null;
      state.message = "";
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      state.isAuth = false;
      state.data = initialState.data;
    });

    //ADMIN
    builder.addCase(adminGetAgents.fulfilled, (state, action) => {
      state.admin_data_agents = action.payload;
    });
    builder.addCase(adminGetPaymentSystems.fulfilled, (state, action) => {
      state.admin_data = action.payload;
      state.admin_data_payments = action.payload;
    });
    builder.addCase(adminGetManagers.fulfilled, (state, action) => {
      state.admin_data_managers = action.payload;
    });

    builder.addCase(userChangeBalance.fulfilled, (state, action) => {
      state.status_balance = Status.SUCCEEDED;
    });

    builder.addCase(userChangeStatus.pending, (state, action) => {
      state.status_balance = Status.LOADING;
    });

    builder.addCase(userChangeStatus.fulfilled, (state, action) => {
      state.status_balance = Status.SUCCEEDED;
    });

    builder.addCase(getUserParam.fulfilled, (state, action) => {
      state.user_param = action.payload;
    });

    builder.addCase(getManagerAssignAgents.fulfilled, (state, action) => {
      state.manager_agents_assign = action.payload;
    });

    builder.addCase(getManagerManagers.fulfilled, (state, action) => {
      state.managerManagers = action.payload;
    });
  },
});

export const { clearStatus } = userSlice.actions;

export default userSlice.reducer;
