import React, { useState } from "react";
import { FormTitle, HomeFormContainer } from "../../../styles";
import RadioGroup from "../../ui/RadioGroup";
import ButtonSubmit from "../../ui/ButtonSubmit";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../../constants/routes";

const SelectUserType = () => {
  const history = useHistory();

  const [isClient, setIsClient] = useState(0);

  const handleClick = () => {

    const isClientPath = isClient === 1;

    if(isClientPath) {
      history.push('/ref/42');
    } else  {
      history.push(LOGIN);
    }

  };

  return (
    <HomeFormContainer>
      <FormTitle>Get to:</FormTitle>
      <RadioGroup onChangeCallback={setIsClient} />
      <div style={{ marginTop: "36px", width: "100%" }}>
        <ButtonSubmit title="Next" onClick={handleClick} isDisable={isClient === 0} />
      </div>
    </HomeFormContainer>
  );
};

export default SelectUserType;
