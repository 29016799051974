import React from 'react';
import { HomeLayout } from "../../../styles";
import HeaderHome from "../../../components/layout/Header";
import Footer from "../../../components/layout/Footer";
import RegisterConfirm from "../../../components/layout/Register/RegisterConfirm";

const RegisterConfirmPage = () => {
  return (
      <HomeLayout>
        <HeaderHome/>
        <RegisterConfirm/>
        <Footer />
      </HomeLayout>
  );
};

export default RegisterConfirmPage;
