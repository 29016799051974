import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FilterOrderType, Status } from "../types";
import { getPaymentsHistoryApi } from "../api/paymentsApi";

export interface PaymentsSliceState {
  error: boolean;
  message: string;
  status: Status;
  payments_history: Array<any>;
}

const initialState: PaymentsSliceState = {
  error: false,
  message: "",
  status: Status.IDLE,
  payments_history: [],
};

export const getPaymentsHistory = createAsyncThunk(
  "payments/get-history",
  async (data: FilterOrderType) => {
    return await getPaymentsHistoryApi(data);
  }
);

export const paymentsSlice = createSlice({
  name: "userLead",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentsHistory.fulfilled, (state, action) => {
      state.payments_history = action.payload;
    });
  },
});

export default paymentsSlice.reducer;
