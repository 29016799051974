import styled from "styled-components";
import { poppinsFont } from "../../../../styles/fonts";
import { black } from "../../../../styles/colors";
import linkedinIcon from "../../../../assets/linkedin.svg";

export const UserStartProductWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const UserStartProductItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-shadow: ${props => props.isSelected ? "3px 3px 24px rgba(37, 59, 134, 0.08)" : "3px 3px 24px rgba(37, 59, 134, 0.08)"};
  background-color: #FFFFFF;
  border: ${ props => props.isSelected ? "2px solid #253B86" : "2px solid transparent"};
  border-radius: 12px;
  width: 168px;
  height: 128px;
  padding: 14px;
  margin-bottom: 34px;

  :hover {
    cursor: pointer;
    
    p {
      color: rgba(23, 23, 23, 0.7);
    }
  }

  p {
    color: ${black};
  }
`

export const UserStartProductText = styled.p`
  font-family: ${poppinsFont};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #171717;
  text-align: center;
  margin: 0;  
`

export const LinkedInImg = styled.img.attrs({
  src: linkedinIcon,
  alt: "LinkedIn"
})``
