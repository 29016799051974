import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { DashboardContent, DashboardLayout } from "../../../styles";
import DashboardHeader from "../../../components/layout/Dashboard/Header";
import DashboardManager from "../../../components/layout/Dashboard/Manager";

const DashboardManagerPage = () => {
  const {
    data: { name, ref_link, balance },
  } = useSelector((state: RootState) => state.user);

  return (
    <DashboardLayout>
      <DashboardContent>
        <DashboardHeader
          refLink={ref_link}
          showRef
          showBalance
          name={name}
          balance={balance}
          showCommunity
        />
        <DashboardManager />
      </DashboardContent>
    </DashboardLayout>
  );
};

export default DashboardManagerPage;
