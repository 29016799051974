import React from "react";
import styled from "styled-components";
import { poppinsFont } from "../../../../styles/fonts";

const Container = styled.div`
  display: flex;
  align-items: center;  
  margin-bottom: 12px;
  
  :last-of-type {
    margin-bottom: 0;
  }
`;

const Input = styled.input`
  margin: 0;
  padding: 0;

  :checked, :not(:checked) {
    position: absolute;
    left: -9999px;
  }
  
  :checked + label, :not(:checked) + label {
    position: relative;    
    cursor: pointer;    
    display: flex;
    align-items: center;
  }
  
  :checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;    
    border-radius: 100%;
    background: #253B86;    
  }

  :checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 7px;
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 100%;    
  }


  :not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    width: 14px;
    height: 14px;
    border: 1px solid rgba(127, 127, 127, 0.5);
    border-radius: 100%;
    background-color: #ffffff;
  }
`;

const Label = styled.label`
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0;  
  color: #000000;
  padding-left: 26px;  
`;

type InputProps = {
  label: string,
  value: string,
  name?: string,
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
};

const FormRadio =  (props: InputProps) => {

  return (
    <Container>
      <Input id={props.value} type="radio" value={props.value} name={props.name} onChange={props.onChange} />
      <Label htmlFor={props.value}>{props.label}</Label>
    </Container>
  );
};

export default FormRadio;
