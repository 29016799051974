import styled from "styled-components";
import { poppinsFont } from "../../../styles/fonts";
import errorIcon from "../../../assets/error-warning.svg";

export const ErrorMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  background: rgba(254, 40, 40, 0.1);
  border-radius: 6px;
  height: 32px;  
  padding: 6px 12px;
`

export const ErrorMessageText = styled.p`
  font-family: ${poppinsFont};
  font-size: 12px;
  line-height: 20px;
  color: #FE2828;
  margin: 0;
`

export const ErrorMessageIcon = styled.img.attrs({
  src: errorIcon,
  alt: "Error"
})`
  margin-right: 6px;
`
