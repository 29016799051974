import styled from "styled-components";
import { poppinsFont } from "../../../styles/fonts";
import { black, lightLightBlue } from "../../../styles/colors";

export const RegisterFooterText = styled.p`
  font-family: ${poppinsFont};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${black};
  margin: 0;
  margin-right: 5px;
  padding: 0;
`

export const RegisterPrivacyText = styled.p`
  font-family: ${poppinsFont};
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  margin: 0;
  margin-left: 6px;
  padding: 0;
  color: ${lightLightBlue};
  
  a {
    font-size: 11px;
    line-height: 16px;
  }
`

export const RegisterPrivacyWrap = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 0;
  margin-bottom: 36px;
  width: 100%;  
`
