import React from "react";
import { FormTitle, HomeFormContainer } from "../../styles";
import { RadioGroupText } from "../../components/ui/RadioGroupMulti/styles";
import FormRadio from "../../components/ui/Form/Radio";
import { useForm, Controller } from "react-hook-form";
import { BtnSubmitElement } from "../../components/ui/ButtonSubmit/style";
import Input from "../../components/ui/Input";
import ErrorMessage from "../../components/ui/ErrorMessage";
import { getUser, userChangeBalance } from "../../slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { getPaymentsHistory } from "../../slices/paymentsSlice";

export interface IMadePaymentForm {
  payment_type: string;
  amount: number;
}

interface Props {
  onFinish: () => void;
}

const MadePaymentContainer = ({ onFinish }: Props) => {
  const dispatch = useDispatch();

  const {
    data: { id },
  } = useSelector((state: RootState) => state.user);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IMadePaymentForm>();

  const onSubmit = async (data: IMadePaymentForm) => {
    await dispatch(
      userChangeBalance({
        user_id: id,
        balance: data.amount,
        type: data.payment_type,
      })
    );

    await dispatch(getUser());
    await dispatch(getPaymentsHistory({filter: "all"}))
    onFinish();
  };

  return (
    <HomeFormContainer isModal>
      <FormTitle>Make a payment</FormTitle>

      <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
        <RadioGroupText style={{ marginBottom: "12px", fontWeight: 400 }}>
          Payment type<span>*</span>
        </RadioGroupText>

        <Controller
          name="payment_type"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <>
              <FormRadio
                label="Onboarding fee"
                value="on_boarding_fee"
                name="payment_type"
                onChange={onChange}
              />
              <FormRadio
                label="Monthly transactional commission"
                value="month_transaction"
                name="payment_type"
                onChange={onChange}
              />
            </>
          )}
        />

        <Controller
          render={({ field: { onChange } }) => (
            <div style={{ marginTop: "24px" }}>
              <Input
                labelText="Amount, €"
                placeholder=""
                type="text"
                onChange={onChange}
                isRequire
              />
            </div>
          )}
          name="amount"
          rules={{ required: true }}
          control={control}
        />

        <div style={{ marginTop: "12px" }}>
          {errors.amount && <ErrorMessage errorText="Amount is not empty" />}
          {errors.payment_type && (
            <ErrorMessage errorText="Select Payment Type" />
          )}
        </div>

        <BtnSubmitElement type="submit" style={{ marginTop: "24px" }}>
          Submit
        </BtnSubmitElement>
      </form>
    </HomeFormContainer>
  );
};

export default MadePaymentContainer;
