import React from "react";
import { TABLE_TITLES_LEADS_VIEW } from "../../constants/tableTitles";
import Table from "../../components/ui/Table";
import { TableContainerWrapper } from "../../components/ui/Table/style";

interface Props {
  data: Array<any>;
  onHandleOrder: (type: string, field: string) => void;
  onHandleDescriptionLead?: (id: any) => void;
}

const AgentViewContainer: React.FunctionComponent<Props> = ({
  data,
  onHandleOrder,
  onHandleDescriptionLead,
}) => {
  const TITLES = React.useMemo(() => TABLE_TITLES_LEADS_VIEW, []);
  const memoData = React.useMemo(() => data, [data]);

  return (
    <TableContainerWrapper>
      <Table
        onClickRow={() => {}}
        headTitles={TITLES}
        tableItems={memoData}
        onOrderCallback={onHandleOrder}
      />
    </TableContainerWrapper>
  );
};

export default React.memo(AgentViewContainer);
