import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  MultiSelectContainer,
  MultiSelectedClose,
  MultiSelectedImg,
  MultiSelectedItem,
  MultiSelectedItemChecked,
  MultiSelectedItems,
  MultiSelectedText,
  MultiSelectInput,
  MultiSelectList,
  MultiSelectListItem,
  MultiSelectListItemWrap,
  MultiSelectUpDown,
} from "./style";
import { baseUrl } from "../../../constants/api";
import { CountryData } from "../../../types";
import useComponentVisible from "../../../constants/hooks";

interface Props {
  items: CountryData[];
  onCallbackChange: (ids: Array<number>) => void;
  initialSelected?: Array<number>;
}

const MultiSelect: React.FunctionComponent<Props> = ({
  items,
  onCallbackChange,
  initialSelected,
}) => {
  const [tmpItems, setTempItems] = useState(items);
  const [selected, setSelected] = useState(new Array(0));

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  useEffect(() => {
    setTempItems(items);
    if (initialSelected) {
      setSelected(initialSelected);
    }
  }, [items, initialSelected]);

  useLayoutEffect(() => {
    onCallbackChange(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handlerInputFocus = () => {
    setIsComponentVisible(true);
  };

  const handleSelect = (id: number) => {
    if (!selected.includes(id)) {
      setSelected((prev) => [...prev, id]);
    } else {
      setSelected((prev) => prev.filter((item) => item !== id));
    }
  };

  const handleRemove = (id: number) => {
    const oldSelected = selected.filter((item) => item !== id);

    setSelected(oldSelected);
  };

  return (
    <MultiSelectContainer ref={ref}>
      <div style={{ width: "100%", position: "relative" }}>
        <MultiSelectInput
          placeholder="Select countries"
          onFocus={handlerInputFocus}
          onInput={(e: React.SyntheticEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value || "";
            const re = new RegExp(value, "gi");

            const newItems = items.filter((item) => {
              if (item.name.match(re)) {
                return item;
              }
              return null;
            });
            setTempItems(newItems);
          }}
        />

        <MultiSelectUpDown
          onClick={() => setIsComponentVisible(!isComponentVisible)}
          isOpen={isComponentVisible}
        />

        <MultiSelectList isVisible={isComponentVisible}>
          {tmpItems.map((item) => {
            return (
              <MultiSelectListItem
                key={item.id + "_country"}
                onClick={() => handleSelect(item.id)}
                isSelected={selected.includes(item.id)}
              >
                <MultiSelectListItemWrap>
                  <MultiSelectedImg
                    src={`${baseUrl}${item.icon}`}
                    alt={item.name}
                  />
                  <MultiSelectedText>{item.name}</MultiSelectedText>
                </MultiSelectListItemWrap>
                {selected.includes(item.id) && <MultiSelectedItemChecked />}
              </MultiSelectListItem>
            );
          })}
        </MultiSelectList>
      </div>

      <MultiSelectedItems>
        {selected.map((id) => {
          const findItem = items.find((item) => item.id === id);

          if (!findItem) return null;

          return (
            <MultiSelectedItem
              key={findItem.id}
              onClick={() => handleRemove(id)}
            >
              <MultiSelectedImg
                src={`${baseUrl}${findItem.icon}`}
                alt={findItem.name}
              />
              <MultiSelectedText>{findItem.name}</MultiSelectedText>
              <MultiSelectedClose />
            </MultiSelectedItem>
          );
        })}
      </MultiSelectedItems>
    </MultiSelectContainer>
  );
};

export default MultiSelect;
