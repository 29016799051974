import React from "react";
import {
  BreadList,
  BreadListItemLink,
  BreadWrapper,
  BredListItem,
} from "./styles";

interface Props {
  rootPath: string;
  rootName?: string;
  currentPage: string;
  secondName?: string
}

const Breadcrumb: React.FunctionComponent<Props> = (props) => {
  return (
    <BreadWrapper>
      <BreadList>
        <BredListItem>
          <BreadListItemLink to={props.rootPath}>
            {props.rootName ? props.rootName : "Agents"}
          </BreadListItemLink>
        </BredListItem>
        <BredListItem>{props.currentPage}</BredListItem>
        <BredListItem>{props.secondName ? props.secondName : "Leads"}</BredListItem>
      </BreadList>
    </BreadWrapper>
  );
};

export default Breadcrumb;
