import React from 'react';
import { FooterContainer, FooterCopyrightText } from "./style";

const Footer = () => {
  return (
      <FooterContainer>
        <FooterCopyrightText>© 2019-2021 Mig.finance.</FooterCopyrightText>
      </FooterContainer>
  );
};

export default Footer;
