import React from "react";
import { FormTitle } from "../../../../styles";
import Input from "../../../ui/Input";
import Checkbox from "../../../ui/Checkbox";
import { UserFormMessengerText } from "./styles";
import whatsAppIcon from "../../../../assets/whatsapp.svg";
import telegramIcon from "../../../../assets/telegram.svg";
import { LinkedInImg } from "../UserStart/style";


interface Props {
  type: number;
  onNameChange: (e: any) => void;
  onEmailChange: (e: any) => void;
  onPhoneChange: (e: any) => void;
  onCompanyChange?: (e: any) => void;
  onCompanyWebsiteChange?: (e: any) => void;
  onLinkedInChange?: (e: any) => void;
  onWhatsUpChange: () => void;
  onTelegramChange: () => void;
  email: { value: string, isError: boolean };
  name?: string;
  phone?: string;
  company?: string;
  companyWebsite?: string;
  whatsUp: boolean;
  telegram: boolean;
}

const UserForm: React.FunctionComponent<Props> = (props) => {
  return (
    <div style={{ width: "100%", marginBottom: "36px" }}>
      <FormTitle style={{ textAlign: "left" }}>Personal info</FormTitle>

      <div style={{ marginBottom: "20px", marginTop: "-12px" }}>
        <Input
          labelText="Full name"
          placeholder=""
          type="text"
          onChange={props.onNameChange}
        />
      </div>

      <div style={{ marginBottom: "20px" }}>
        <Input
          labelText={"Email"}
          placeholder=""
          type="email"
          onChange={props.onEmailChange}
          isError={props.email.isError}
        />
      </div>

      <div style={{ marginBottom: "20px" }}>
        <Input
          labelText="LinkedIn account"
          placeholder=""
          type="text"
          onChange={props.onLinkedInChange ? props.onLinkedInChange : () => {}}
          isPrePend
          prePendText={<LinkedInImg />}
        />
      </div>

      <div style={{ marginBottom: "20px" }}>
        <Input
          labelText={"Phone"}
          placeholder=""
          type="phone"
          onChange={props.onPhoneChange}
        />
      </div>

      <div style={{ display: "flex" }}>
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "24px" }}
        >
          <Checkbox onClick={props.onWhatsUpChange} value={props.whatsUp} />
          <img
            alt="Whatsapp"
            src={whatsAppIcon}
            style={{ marginLeft: "12px", marginRight: "4px" }}
          />
          <UserFormMessengerText>WhatsApp</UserFormMessengerText>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox onClick={props.onTelegramChange} value={props.telegram} />
          <img
            alt="Telegram"
            src={telegramIcon}
            style={{ marginLeft: "12px", marginRight: "4px" }}
          />
          <UserFormMessengerText>Telegram</UserFormMessengerText>
        </div>
      </div>
      {props.type === 2 && (
        <div>
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <Input
              labelText="Company name"
              placeholder=""
              type="text"
              onChange={
                props.onCompanyChange ? props.onCompanyChange : () => {}
              }
            />
          </div>
          <div>
            <Input
              labelText="Company website"
              placeholder=""
              type="text"
              onChange={
                props.onCompanyWebsiteChange
                  ? props.onCompanyWebsiteChange
                  : () => {}
              }
              isPrePend
              prePendText="https://"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserForm;
