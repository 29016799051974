import React, { useState } from 'react';
import { FilterContainer, FilterItem, FilterText, FilterTitle } from "./styles";

interface Props {
  items: Array<{
    name: string,
    slug: string
  }>
  onSelectCallback: (slug: string) => void;
}

const Filter: React.FunctionComponent<Props> = ({ items, onSelectCallback }) => {

  const [currentSlug, setCurrentSlug] = useState("all");

  const handleSelect = (slug: string) => {
    setCurrentSlug(slug);
    onSelectCallback(slug);
  }

  return (
      <FilterContainer>
        <FilterTitle>Filter:</FilterTitle>

        {items.map((item, index) => {
          return (
              <FilterItem
                  key={item.name + "_" + index}
                  isSelect={item.slug === currentSlug}
                  onClick={() => handleSelect(item.slug)}
              >
                <FilterText>
                  {item.name}
                </FilterText>
              </FilterItem>
          )
        })}

      </FilterContainer>
  );
};

export default Filter;
