import React, { useEffect, useState } from "react";
import DashboardNav from "../../Navigation";
import Breadcrumb from "../../../../ui/Breadcrumb";
import { DASHBOARD_ADMIN_MANAGERS } from "../../../../../constants/routes";
import { DashboardTableWrapper, DashboardTitle} from "../../../../../styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  getLeadsByUser,
  getManagerAgentLeads,
} from "../../../../../slices/leadtSlice";
import {
  TableActions,
  TableContainerWrapper,
} from "../../../../ui/Table/style";
import Table from "../../../../ui/Table";
import {
  TABLE_TITLES_ADMIN_AGENTS,
} from "../../../../../constants/tableTitles";
import useComponentVisible from "../../../../../constants/hooks";

interface Props {
  userId: number;
}

const DashboardAdminManagerAgents: React.FunctionComponent<Props> = ({
  userId,
}) => {
  const dispatch = useDispatch();
  const { by_user, managerAgentLeads} = useSelector((state: RootState) => state.leads);


  const [actionsObj, setActionsObj] = useState({ x: 0, y: 0, id: 0 });

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  useEffect(() => {
    dispatch(getManagerAgentLeads({ filter: "all", user_id: userId }));
    dispatch(getLeadsByUser({ user_lead_id: userId, filter: "none" }));
  }, [dispatch, userId]);

  return (
    <div style={{ display: "flex" }}>
      <DashboardNav />
      <DashboardTableWrapper   >
        <Breadcrumb
          rootPath={DASHBOARD_ADMIN_MANAGERS}
          rootName="Managers"
          currentPage={by_user.name}
          secondName="Agents"
        />

        <DashboardTitle>{by_user.name}</DashboardTitle>

        <TableContainerWrapper>
          <Table
            isAdmin
            headTitles={TABLE_TITLES_ADMIN_AGENTS}
            tableItems={managerAgentLeads}
            onClickRow={() => {}}
            onClickCellAction={(e, id) => {
              const { left, top } = e.currentTarget.getBoundingClientRect();
              setIsComponentVisible(true);

              setActionsObj({ x: left, y: top, id });
            }}
          />
        </TableContainerWrapper>
      </DashboardTableWrapper>

      <TableActions
        ref={ref}
        isVisible={isComponentVisible}
        x={actionsObj.x}
        y={actionsObj.y}
      ></TableActions>
    </div>
  );
};

export default DashboardAdminManagerAgents;
