import styled, { createGlobalStyle } from "styled-components";
import { globalFont, poppinsFont } from "./fonts";
import bgHomeRect from "../assets/bg-down-rect.svg";
import { black, blue, lightLightBlue } from "./colors";
import { Fade } from "./animations";

export const GlobalStyle = createGlobalStyle`
  html {
    display: block;
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${globalFont};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;


    ::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      background: #E9EBF3;
    }

    ::-webkit-scrollbar-thumb {
      background: #253B86;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  }

  * {
    box-sizing: border-box;
  }

  h1 {
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
  }

  a, button {
    cursor: pointer;
  }

  
`;

export const HomeLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  background-image: url("${bgHomeRect}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const HomeFormContainer = styled.div<{ isModal?: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-width: 482px;
  max-width: 482px;
  background: #ffffff;
  box-shadow: 6px 6px 24px rgba(37, 59, 134, 0.11);
  border-radius: 12px;
  margin: 0 auto;
  margin-top: ${({ isModal }) => (isModal ? "0" : "145px")};
  padding: 60px;

  select {
    font-family: ${poppinsFont};
    font-size: 14px;
    line-height: 20px;
    color: #7f7f7f;
    width: 100%;
    height: 46px;
    padding: 13px 16px;
    border: 1px solid rgba(127, 127, 127, 0.3);
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
  }

  @media (max-width: 500px) {
    width: 100%;
    max-width: 100%;
    min-width: auto;
    padding: 40px 20px;
  }
`;

export const FormTitle = styled.h2`
  font-family: ${globalFont};
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${blue};
  margin: 0;
  margin-bottom: 36px;
  padding: 0;
`;

export const FormSubTitle = styled.h2`
  font-family: ${poppinsFont};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${lightLightBlue};
  margin-top: -30px;
  margin-bottom: 24px;
`;

export const ModalLayout = styled.div<{ isShow: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: ${(props) => (props.isShow ? "flex" : "none")};
  height: 100vh;
  width: 100vw;
  background-color: rgba(23, 23, 23, 0.8);
  animation: ${Fade};
  animation-duration: 0.15s;
  animation-fill-mode: forwards;
  z-index: 20;
`;

export const Link = styled.a`
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${blue};
`;

export const DashboardLayout = styled.div`
  display: flex;
  background: #e9ebf3;
  min-height: 100vh;
  min-width: 100vw;
`;

export const DashboardContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1456px;
  margin: 0 auto;
  width: 100%;
  
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 6px 6px 24px rgba(37, 59, 134, 0.11);
  border-radius: 12px;
  height: 852px;  
  padding-top: 74px;
  padding-left: 178px;
  padding-right: 218px;
  
  @media (max-width: 1200px) {
    padding-left: 25px;
    padding-right: 25px;
    height: auto;
  }
`;

export const DashboardTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 6px 6px 24px rgba(37, 59, 134, 0.11);
  border-radius: 12px;
  height: 852px;
  padding: 60px;
  margin: 0;
  width: 100%;
  overflow: auto;
`;

export const DashboardTitle = styled.h1`
  font-family: ${globalFont};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${black};
  margin: 0;
  margin-bottom: 36px;
  padding: 0;
`;

export const TableWrapper = styled.div`
  display: flex;
  overflow: auto;
  position: relative;
  margin-bottom: 104px;

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: #E9EBF3;
  }

  ::-webkit-scrollbar-thumb {
    background: #253B86;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-button {

  }
`;
