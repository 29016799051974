import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormSubTitle,
  FormTitle,
  HomeFormContainer,
  ModalLayout,
} from "../../styles";
import {
  PopupTransferBtnContainer,
  PopupTransferBtnWrap,
  PopupTransferName,
  PopupTransferRow,
  PopupTransferText,
} from "../../components/ui/PopupTransfer/style";
import ButtonSubmit from "../../components/ui/ButtonSubmit";
import ButtonDecline from "../../components/ui/ButtonDecline";
import TabBar from "../../components/ui/TabBar";
import {
  getLeadInfo,
  leadChangeStatus,
  leadChangeStatusDecline,
  updateLeadInfo,
} from "../../slices/leadtSlice";
import { RootState } from "../../store";
import lockIcon from "../../assets/lock.svg";
import { BtnSubmitLoadingImg } from "../../components/ui/ButtonSubmit/style";
import { LeadStatus, Status, UserLeadType } from "../../types";
import {
  ClientEditButton,
  ClientInput,
  ClientInputWrap,
  ClientLink,
} from "./styles";
import editIcon from "../../assets/edit-client.svg";
import saveIcon from "../../assets/save-client.svg";
import { useForm } from "react-hook-form";
import ReferralLink from "../../components/ui/ReferalLink";

interface Props {
  onCloseCallback: () => void;
  userLeadId: number;
  onSaveCallback?: () => void;
}

const CLIENT_INFO_TABS = [
  {
    title: "Client info",
    index: 1,
  },
  {
    title: "Financial info",
    index: 2,
  },
  {
    title: "Fees expectations",
    index: 3,
  },
  {
    title: "Products",
    index: 4,
  },
];

const COMPANY_INFO_TABS = [
  {
    title: "Company info",
    index: 1,
  },
  {
    title: "Financial info",
    index: 2,
  },
  {
    title: "Fees expectations",
    index: 3,
  },
  {
    title: "Products",
    index: 4,
  },
];

const CLIENT_INFO_DATA = [
  {
    title: "Full name",
    slug: "name",
    type: "text",
  },
  {
    title: "Email",
    slug: "email",
    type: "text",
  },
  {
    title: "LinkedIn account",
    slug: "linkedin",
    type: "link",
  },
  {
    title: "Phone",
    slug: "phone",
    type: "text",
  },
  {
    title: "Messengers",
    slug: "messengers",
    type: "text",
  },
];

const CLIENT_INFO_DATA_FINANCE = [
  {
    title: "Citizenship",
    slug: "citizenship",
    type: "text",
  },
  {
    title: "Country of residence",
    slug: "residence",
    type: "text",
  },
  {
    title: "Incoming payments (countries)",
    slug: "in_payment",
    type: "text",
  },
  {
    title: "Outgoing payments (countries)",
    slug: "out_payment",
    type: "text",
  },
  {
    title: "Annual Turnover (EUR)",
    slug: "annualTurnover",
    type: "text",
  },
];

const CLIENT_INFO_DATA_FEE = [
  {
    title: "Onboarding fee, €",
    slug: "onboarding_fee",
    type: "text",
  },
  {
    title: "Transaction fee, %",
    slug: "transaction_fee",
    type: "text",
  },
  {
    title: "Monthly maintenance, €*",
    slug: "month_maintenance",
    type: "text",
  },
];

const CLIENT_INFO_DATA_PRODUCTS = [
  {
    title: "Products",
    slug: "products",
    type: "products",
  },
];

const COMPANY_INFO_DATA = [
  {
    title: "Full name",
    slug: "name",
    type: "text",
  },
  {
    title: "Email",
    slug: "email",
    type: "text",
  },
  {
    title: "LinkedIn account",
    slug: "linkedin",
    type: "link",
  },
  {
    title: "Phone",
    slug: "phone",
    type: "text",
  },
  {
    title: "Messengers",
    slug: "phone_type",
    type: "text",
  },
  {
    title: "Company name",
    slug: "company_name",
    type: "text",
  },
  {
    title: "Industry",
    slug: "industry",
    type: "text",
  },
  {
    title: "Company website",
    slug: "company_website",
    type: "text",
  },
];

const COMPANY_FINANCE_DATA = [
  {
    title: "Jurisdiction",
    slug: "jurisdiction",
    type: "text",
  },
  {
    title: "Main business activities details",
    slug: "mainBusinessActiviti",
    type: "text",
  },
  {
    title: "UBO citizenship",
    slug: "uboCitizenship",
    type: "text",
  },
  {
    title: "Year established",
    slug: "yearEstablished",
    type: "text",
  },
  {
    title: "Number of employees",
    slug: "numberOfEmployees",
    type: "text",
  },
  {
    title: "Financial information available",
    slug: "financialInformation",
    type: "text",
  },
  {
    title: "Transaction profile",
    slug: "sepaSwift",
    type: "text",
  },
  {
    title: "Incoming payments (countries)",
    slug: "incomingTransactions",
    type: "text",
  },
  {
    title: "Outgoing payments (countries)",
    slug: "outgoingTransactions",
    type: "text",
  },
  {
    title: "Type of transactions",
    slug: "typeOfTransactions",
    type: "text",
  },
  {
    title: "Annual Turnover (EUR)",
    slug: "annualTurnover",
    type: "text",
  },
  {
    title: "Average Transanction Size",
    slug: "averageTransactionSi",
    type: "text",
  },
  {
    title: "Incoming transactions per month",
    slug: "incomingTransactions",
    type: "text",
  },
  {
    title: "Outgoing transactions per month",
    slug: "outgoingTransactions",
    type: "text",
  },
  {
    title: "Currencies needed",
    slug: "currenciesNeeded",
    type: "text",
  },
  {
    title: "Other information",
    slug: "other_info",
    type: "text",
  },
  {
    title: "Accounts at other Banks",
    slug: "accountsAtOtherBanks",
    type: "text",
  },
  {
    title: "License",
    slug: "license",
    type: "text",
  },
  {
    title: "Additional links",
    slug: "additional_links",
    type: "text",
  },
];

const ClientDetailsContainer: React.FunctionComponent<Props> = ({
  onCloseCallback,
  onSaveCallback,
  userLeadId,
}) => {
  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm();

  const ref = useRef<HTMLDivElement>(null);

  const [currentTab, setCurrentTab] = useState(1);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const { lead_info, lead_info_status } = useSelector(
    (state: RootState) => state.leads
  );
  const {
    data: { is_admin, type },
  } = useSelector((state: RootState) => state.user);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onCloseCallback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref, dispatch, userLeadId]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    dispatch(getLeadInfo({ user_lead_id: userLeadId }));

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside, dispatch, userLeadId]);

  const handlerAccept = async () => {
    await dispatch(leadChangeStatus({ status: "approve", id: userLeadId }));
    onCloseCallback();
  };

  const handlerDecline = async () => {
    await dispatch(leadChangeStatusDecline({ id: userLeadId }));
    onCloseCallback();
  };

  const handleCancel = () => {
    setShowConfirm(false);
    onCloseCallback();
  };

  const onSubmit = async (data: any) => {
    if (isSave) {
      await dispatch(updateLeadInfo({ user_lead_id: userLeadId, data }));
      await dispatch(getLeadInfo({ user_lead_id: userLeadId }));
      setIsSave(false);

      if (onSaveCallback) {
        onSaveCallback();
      }
    } else {
      setIsSave(true);
    }
  };

  if (lead_info_status === Status.LOADING) {
    return (
      <ModalLayout isShow={true}>
        <HomeFormContainer
          style={{ marginTop: 0, alignItems: "center" }}
          ref={ref}
        >
          <BtnSubmitLoadingImg />
        </HomeFormContainer>
      </ModalLayout>
    );
  }

  if (showConfirm) {
    return (
      <ModalLayout isShow={true}>
        <HomeFormContainer isModal ref={ref}>
          <FormTitle>Decline client?</FormTitle>

          <FormSubTitle style={{ marginBottom: 0 }}>
            Are you sure? This action cannot be changed.
          </FormSubTitle>

          <PopupTransferBtnContainer style={{ marginTop: "36px" }}>
            <PopupTransferBtnWrap>
              <ButtonDecline title="Cancel" onClick={handleCancel} />
            </PopupTransferBtnWrap>

            <PopupTransferBtnWrap>
              <ButtonSubmit title="Decline" onClick={handlerDecline} />
            </PopupTransferBtnWrap>
          </PopupTransferBtnContainer>
        </HomeFormContainer>
      </ModalLayout>
    );
  }

  return (
    <ModalLayout isShow={true}>
      <HomeFormContainer
        style={{ position: "relative", maxWidth: "none", minWidth: "680px" }}
        isModal
        ref={ref}
      >
        <div style={{ width: "100%" }}>
          <TabBar
            onChangeCallback={(state) => setCurrentTab(state)}
            initialTab={currentTab}
            tabItems={
              lead_info?.type_lead === UserLeadType.BUSINESS
                ? COMPANY_INFO_TABS
                : CLIENT_INFO_TABS
            }
          />

          {currentTab === 1 && lead_info?.type_lead === 2 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              {(is_admin || type === 3) && (
                <ClientEditButton isSave={isSave} type="submit">
                  <img src={isSave ? saveIcon : editIcon} alt="Client Edit" />
                  {isSave ? "Save" : "Edit"}
                </ClientEditButton>
              )}

              {COMPANY_INFO_DATA.map((item, index) => {
                let value = "";

                if (lead_info && lead_info[item.slug]) {
                  value = lead_info[item.slug].toString();
                }

                return (
                  <PopupTransferRow key={item.slug + "_" + index}>
                    <PopupTransferName>{item.title}</PopupTransferName>

                    {isSave ? (
                      <ClientInputWrap>
                        <ClientInput
                          defaultValue={value}
                          {...register(item.slug)}
                        />
                      </ClientInputWrap>
                    ) : (
                      <PopupTransferText>
                        {value === "hidden" ? (
                          <img src={lockIcon} alt="hidden" />
                        ) : item.type === "link" ? (
                          <ClientLink href={value} target="_blank">
                            {value}
                          </ClientLink>
                        ) : (
                          value
                        )}
                      </PopupTransferText>
                    )}
                  </PopupTransferRow>
                );
              })}
            </form>
          )}

          {currentTab === 2 && lead_info?.type_lead === 2 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              {(is_admin || type === 3) && (
                <ClientEditButton isSave={isSave} type="submit">
                  <img src={isSave ? saveIcon : editIcon} alt="Client Edit" />

                  {isSave ? "Save" : "Edit"}
                </ClientEditButton>
              )}

              {COMPANY_FINANCE_DATA.map((item, index) => {
                let value = "";

                if (lead_info && lead_info[item.slug]) {
                  value = lead_info[item.slug].toString();
                }

                return (
                  <PopupTransferRow key={item.slug + "_" + index}>
                    <PopupTransferName>{item.title}</PopupTransferName>

                    {isSave ? (
                      <ClientInputWrap>
                        <ClientInput
                          defaultValue={value}
                          {...register(item.slug)}
                        />
                      </ClientInputWrap>
                    ) : (
                      <PopupTransferText>
                        {value === "hidden" ? (
                          <img src={lockIcon} alt="hidden" />
                        ) : (
                          value
                        )}
                      </PopupTransferText>
                    )}
                  </PopupTransferRow>
                );
              })}
            </form>
          )}

          {currentTab === 1 && lead_info?.type_lead === 1 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              {(is_admin || type === 3) && (
                <ClientEditButton isSave={isSave} type="submit">
                  <img src={isSave ? saveIcon : editIcon} alt="Client Edit" />
                  {isSave ? "Save" : "Edit"}
                </ClientEditButton>
              )}

              {CLIENT_INFO_DATA.map((item, index) => {
                let value = "";

                if (lead_info && lead_info[item.slug]) {
                  value = lead_info[item.slug].toString();
                }

                return (
                  <PopupTransferRow key={item.slug + "_" + index}>
                    <PopupTransferName>{item.title}</PopupTransferName>

                    {isSave ? (
                      <ClientInputWrap>
                        <ClientInput
                          defaultValue={value}
                          {...register(item.slug)}
                        />
                      </ClientInputWrap>
                    ) : (
                      <PopupTransferText>
                        {value === "hidden" ? (
                          <img src={lockIcon} alt="hidden" />
                        ) : item.type === "link" ? (
                          <ClientLink href={value} target="_blank">
                            {value}
                          </ClientLink>
                        ) : (
                          value
                        )}
                      </PopupTransferText>
                    )}
                  </PopupTransferRow>
                );
              })}
            </form>
          )}

          {currentTab === 2 && lead_info?.type_lead === 1 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              {(is_admin || type === 3) && (
                <ClientEditButton isSave={isSave} type="submit">
                  <img src={isSave ? saveIcon : editIcon} alt="Client Edit" />
                  {isSave ? "Save" : "Edit"}
                </ClientEditButton>
              )}

              {CLIENT_INFO_DATA_FINANCE.map((item, index) => {
                let value = "";

                if (lead_info && lead_info[item.slug]) {
                  value = lead_info[item.slug].toString();
                }

                return (
                  <PopupTransferRow key={item.slug + "_" + index}>
                    <PopupTransferName>{item.title}</PopupTransferName>

                    {isSave ? (
                      <ClientInputWrap>
                        <ClientInput
                          defaultValue={value}
                          {...register(item.slug)}
                        />
                      </ClientInputWrap>
                    ) : (
                      <PopupTransferText>
                        {value === "hidden" ? (
                          <img src={lockIcon} alt="hidden" />
                        ) : (
                          value
                        )}
                      </PopupTransferText>
                    )}
                  </PopupTransferRow>
                );
              })}
            </form>
          )}

          {currentTab === 3 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              {(is_admin || type === 3) && (
                <ClientEditButton isSave={isSave} type="submit">
                  <img src={isSave ? saveIcon : editIcon} alt="Client Edit" />
                  {isSave ? "Save" : "Edit"}
                </ClientEditButton>
              )}

              {CLIENT_INFO_DATA_FEE.map((item, index) => {
                let value = "";

                if (lead_info && lead_info[item.slug]) {
                  value = lead_info[item.slug].toString();
                }

                return (
                  <PopupTransferRow key={item.slug + "_" + index}>
                    <PopupTransferName>{item.title}</PopupTransferName>

                    {isSave ? (
                      <ClientInputWrap>
                        <ClientInput
                          defaultValue={value}
                          {...register(item.slug)}
                        />
                      </ClientInputWrap>
                    ) : (
                      <PopupTransferText>
                        {value === "hidden" ? (
                          <img src={lockIcon} alt="hidden" />
                        ) : (
                          value
                        )}
                      </PopupTransferText>
                    )}
                  </PopupTransferRow>
                );
              })}
            </form>
          )}

          {currentTab === 4 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              {(is_admin || type === 3) && (
                <ClientEditButton isSave={isSave} type="submit">
                  <img src={isSave ? saveIcon : editIcon} alt="Client Edit" />
                  {isSave ? "Save" : "Edit"}
                </ClientEditButton>
              )}

              {CLIENT_INFO_DATA_PRODUCTS.map((item, index) => {
                let value = "";

                if (lead_info && lead_info[item.slug]) {
                  value = lead_info[item.slug].toString();
                }

                return (
                  <PopupTransferRow key={item.slug + "_" + index}>
                    <PopupTransferName>{item.title}</PopupTransferName>

                    {isSave ? (
                      <ClientInputWrap>
                        <ClientInput
                          defaultValue={value}
                          {...register(item.slug)}
                        />
                      </ClientInputWrap>
                    ) : (
                      <PopupTransferText>
                        {value === "hidden" ? (
                          <img src={lockIcon} alt="hidden" />
                        ) : (
                          value
                        )}
                      </PopupTransferText>
                    )}
                  </PopupTransferRow>
                );
              })}
            </form>
          )}

          {(is_admin || type === 3) && lead_info?.register_link && (

            <div style={{ marginTop: "36px"}}>
              <div style={{ height: "2px", backgroundColor: "rgba(146, 157, 194, 0.2)", width: "100%", marginBottom: "26px"}} />
              <div style={{ marginLeft: "-60px" }}>
              <ReferralLink
                title="Registration link:"
                link={lead_info.register_link}
              />
              </div>
            </div>

          )}
        </div>

        {!is_admin &&
          type !== 3 &&
          lead_info?.status !== LeadStatus.APPROVE &&
          lead_info?.status !== LeadStatus.DECLINE && (
            <PopupTransferBtnContainer style={{ marginTop: "36px" }}>
              <PopupTransferBtnWrap>
                <ButtonSubmit title="Accept" onClick={handlerAccept} />
              </PopupTransferBtnWrap>

              <PopupTransferBtnWrap>
                <ButtonDecline
                  title="Decline"
                  onClick={() => setShowConfirm(true)}
                />
              </PopupTransferBtnWrap>
            </PopupTransferBtnContainer>
          )}
      </HomeFormContainer>
    </ModalLayout>
  );
};

export default ClientDetailsContainer;
