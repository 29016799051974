import styled from "styled-components";
import { globalFont } from "../../../../../styles/fonts";


export const DashboardStatisticsContainer = styled.div`
  display: flex;  
`

export const DashboardStatisticsItem = styled.div`
  display: flex;
  border: 1px solid #E9EBF3;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;  
  width: 284px; 
  margin-right: 36px;
  
  :last-of-type {
    margin-right: 0;
  }
`

export const DashboardStatisticsItemTitle = styled.div`
  font-family: ${globalFont};
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #253B86;
`

export const DashboardStatisticsItemDescription = styled.div`
  font-family: ${globalFont};
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #929DC2;
`

export const DashboardStatisticsIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #253B86;
  box-shadow: 6px 6px 12px rgba(37, 59, 134, 0.06);
  border-radius: 50%;
  height: 60px;
  width: 60px;  
  margin-right: 20px;
`

export const DashboardStatisticsIcon = styled.img``
