import React, { useEffect } from "react";
import NavManager from "../Navigation/NavManager";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { getLeadsByUser } from "../../../../slices/leadtSlice";
import Breadcrumb from "../../../ui/Breadcrumb";
import { DASHBOARD_MANAGER_AGENTS } from "../../../../constants/routes";
import { DashboardTableWrapper, DashboardTitle } from "../../../../styles";
import AgentViewContainer from "../../../../containers/AgentView";

interface Props {
  userLeadId: number;
}

const DashboardManagerAgentLeads: React.FunctionComponent<Props> = ({
  userLeadId,
}) => {
  const dispatch = useDispatch();
  const { by_user } = useSelector((state: RootState) => state.leads);

  useEffect(() => {
    dispatch(getLeadsByUser({ user_lead_id: userLeadId }));
  }, [dispatch, userLeadId]);

  const handleOder = (type: string, field: string) => {

  }

  return (
    <div style={{ display: "inline-flex" }}>
      <NavManager />
      <DashboardTableWrapper>

        <Breadcrumb
          rootPath={DASHBOARD_MANAGER_AGENTS}
          currentPage={by_user.name}
        />

        <DashboardTitle>{by_user.name}</DashboardTitle>

        <AgentViewContainer data={by_user.user_leads} onHandleOrder={handleOder}  />

      </DashboardTableWrapper>
    </div>
  );
};

export default DashboardManagerAgentLeads;
