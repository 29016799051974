import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import DashboardPage from "../pages/Dashboard";
import {
  DASHBOARD_MANAGER,
  DASHBOARD_MANAGER_AGENT_LEADS,
  DASHBOARD_MANAGER_AGENTS,
  DASHBOARD_PAYMENTS,
  DASHBOARD_PAYMENTS_HISTORY,
  LOGIN,
} from "../constants/routes";
import { getUserToken } from "../constants/api";
import { UserType } from "../types";
import LoadingScreen from "./ui/LoadingScreen";

interface Props {
  component: React.ComponentClass<any, any> | React.FunctionComponent<any>;
  path: string;
  exact: boolean;
}

const PrivateRoute: React.FC<Props> = (props) => {
  const agentLeadsMatch = useRouteMatch({
    path: DASHBOARD_MANAGER_AGENT_LEADS + ":userId",
  });

  const {
    data: { is_admin, type },
  } = useSelector((state: RootState) => state.user);

  if (is_admin && getUserToken()) {
    return <Route path={props.path} exact component={props.component} />;
  } else if (type === UserType.AGENT || type === UserType.TOKEN_HOLDER) {
    return (
      <Route path={props.path} exact={props.exact} component={DashboardPage} />
    );
  } else if (type === 2) {
    if (
      props.path === DASHBOARD_PAYMENTS_HISTORY ||
      props.path === DASHBOARD_PAYMENTS
    ) {
      return (
        <Route
          path={props.path}
          exact={props.exact}
          component={props.component}
        />
      );
    }
    return <Redirect to={DASHBOARD_PAYMENTS} />;
  } else if (type === 3) {
    if (
      props.path === DASHBOARD_MANAGER ||
      props.path === DASHBOARD_MANAGER_AGENTS ||
      agentLeadsMatch
    ) {
      return (
        <Route
          path={props.path}
          exact={props.exact}
          component={props.component}
        />
      );
    }
    return <Redirect to={DASHBOARD_MANAGER} />;
  } else if (getUserToken()) {
    return <LoadingScreen />;
  } else {
    return <Redirect to={LOGIN} />;
  }
};

export default PrivateRoute;
