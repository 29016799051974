import React from 'react';
import { HomeLayout } from "../../styles";
import HeaderHome from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import Login from "../../components/layout/Login";

const LoginPage = () => {


  return (
      <HomeLayout>
        <HeaderHome/>
        <Login />
        <Footer />
      </HomeLayout>
  );
};

export default LoginPage;
