import { UserLeadData, UserLeadFeeData, UserLeadProductData } from "../types";
import axios from "axios";
import {
  baseUrl,
  userAddLeadFeePath,
  userAddLeadPath,
  userAddLeadPaymentInfoPath,
  userAddLeadProductPath,
  userLeadRestorePath,
} from "../constants/api";

export const userAddLeadApi = async (data: UserLeadData) => {
  try {
    const response = await axios.post(`${baseUrl}${userAddLeadPath}`, data);

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const userAddLeadFeeApi = async (data: UserLeadFeeData) => {
  try {
    const response = await axios.post(`${baseUrl}${userAddLeadFeePath}`, data);

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const userAddLeadProductApi = async (data: UserLeadProductData) => {
  try {
    const response = await axios.post(
      `${baseUrl}${userAddLeadProductPath}`,
      data
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const userLeadRestoreApi = async (data: { hash: string }) => {
  try {
    const response = await axios.post(`${baseUrl}${userLeadRestorePath}`, data);

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const userLeadPaymentInfoApi = async (data: {
  citizenship: number;
  residence: number;
  in_payment: string;
  out_payment: string;
  month_payment: string;
  email: string;
}) => {
  try {
    const response = await axios.post(
      `${baseUrl}${userAddLeadPaymentInfoPath}`,
      data
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};
