export const HOME = "/";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const REGISTER_CONFIRM = "/password-send-confirm";
export const REGISTER_CHOOSE = "/register-choose";
export const PASSWORD_RESET = "/password-reset";
export const PASSWORD_NEW = "/password-new";
export const USER_START = "/ref/:id";

export const DASHBOARD_LEADS_VIEW = "/dashboard/leads/";

export const DASHBOARD = "/dashboard";
export const DASHBOARD_STAT = "/dashboard/stat";
export const DASHBOARD_ADMIN_AGENTS = "/dashboard/admin/agents";
export const DASHBOARD_ADMIN_PAYMENTS = "/dashboard/admin/payments";
export const DASHBOARD_ADMIN_MANAGERS = "/dashboard/admin/managers";
export const DASHBOARD_ADMIN_MANAGER_LEADS = "/dashboard/admin/manager/leads/";
export const DASHBOARD_ADMIN_MANAGER_AGENTS = "/dashboard/admin/manager/agents/";
export const DASHBOARD_ADMIN_MANAGER_AGENT_LEADS = "/dashboard/admin/manager/agent/leads/";

export const DASHBOARD_AGENTS = "/dashboard/a/";

export const DASHBOARD_PAYMENTS = "/dashboard/p/";
export const DASHBOARD_PAYMENTS_HISTORY = "/dashboard/p/history";

export const DASHBOARD_MANAGER = "/dashboard/m/";
export const DASHBOARD_MANAGER_AGENTS = "/dashboard/m/agents";
export const DASHBOARD_MANAGER_AGENT_LEADS = "/dashboard/m/agent/leads/";
