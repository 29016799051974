import styled from "styled-components";
import logoImg from "../../../assets/logo-dash.svg";

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 48px;  
`

export const HeaderLogo = styled.img.attrs({
  src: logoImg,
  alt: "Migom Finance"
})``

