import React, { useState } from "react";
import {
  RadioGroupBgCheck,
  RadioGroupCheck,
  RadioGroupContainer,
  RadioGroupText,
  RadioGroupWrapper,
} from "./styles";

type Props = {
  onChangeCallback: (type: number) => void;
};

const RadioGroup = ({ onChangeCallback }: Props) => {
  const [isClient, setIsClient] = useState(0);

  const handleChange = (isClient: number) => {
    setIsClient(isClient);

    onChangeCallback(isClient);
  };

  const isClientPath = isClient === 1;
  const isPaymentPath = isClient === 2;

  return (
    <>
      <RadioGroupContainer onClick={() => handleChange(1)} isActive={isClientPath}>
        <RadioGroupWrapper>
          <RadioGroupBgCheck isActive={isClientPath}>
            {isClientPath && <RadioGroupCheck />}
          </RadioGroupBgCheck>
          <RadioGroupText>Application for financial services</RadioGroupText>
        </RadioGroupWrapper>
      </RadioGroupContainer>

      <RadioGroupContainer onClick={() => handleChange(2)} isActive={isPaymentPath}>
        <RadioGroupWrapper>
          <RadioGroupBgCheck isActive={isPaymentPath}>
            {isPaymentPath && <RadioGroupCheck />}
          </RadioGroupBgCheck>
          <RadioGroupText>Agents and payment systems portal</RadioGroupText>
        </RadioGroupWrapper>
      </RadioGroupContainer>
    </>
  );
};

export default RadioGroup;
