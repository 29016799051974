export const baseUrl = process.env.REACT_APP_API_URL || "http://127.0.0.7";

export const mePath = "/api/user";
export const registerPath = "/api/register";
export const registerTokenRestorePath = "/api/restore-token";
export const loginPath = "/api/login";
export const logoutPath = "/api/logout";
export const resetPasswordPath = "/api/reset-password";
export const newPasswordPath = "/api/new-password";
export const productsPath = "/api/products";

export const countriesPath = "/api/countries";
export const countriesPublicPath = "/api/public/countries";

export const userSetParamPath = "/api/user/set-param";
export const userGetParamPath = "/api/user/get-param";
export const userGetLeadsPath = "/api/user/get-leads";
export const userGetLeadsPaymentsPath = "/api/user/get-leads-payments";
export const userChangeStatusPath = "/api/user/change-status";
export const userChangeBalancePath = "/api/user/change-balance";
export const userGetInfoPath = "/api/user/get-info";
export const userUpdateInfoPath = "/api/user/update-info";

export const leadChangeStatusPath = "/api/leads/change-status";
export const leadAcceptPath = "/api/lead/accept";
export const leadDeclinePath = "/api/lead/decline";

//ADMIN
export const adminGetAgentPath = "/api/admin/get-agents";
export const adminGetPaymentSystemsPath = "/api/admin/get-payment-systems";
export const adminSetUserPercentPath = "/api/admin/set-user-percent";
export const adminGetManagersPath = "/api/admin/get-managers";
export const adminGetSumSubRequestPath = "/api/admin/lead-request";

//LEAD
export const userAddLeadPath = "/api/user/add-lead";
export const userAddLeadFeePath = "/api/user/add-lead-fee";
export const userAddLeadProductPath = "/api/user/add-lead-product";
export const userAddLeadPaymentInfoPath = "/api/user/add-lead-payment-info";
export const userLeadRestorePath = "/api/user/lead-restore";
export const userLeadDeletePath = "/api/leads/delete-lead";

export const leadsAssignClientPath = "/api/leads/assign-client";
export const getLeadsByUserPath = "/api/leads/get-by-user";
export const getLeadInfoPath = "/api/leads/get-lead-info";
export const updateLeadInfoPath = "/api/leads/update-lead-info";

//MANAGER
export const getManagerLeadsPath = "/api/manager/get-leads";
export const getManagerAgentPath = "/api/manager/get-agents";
export const managerAssignAgentPath = "/api/manager/assign-agent";
export const getManagerAgentLeadsPath = "/api/manager/get-manager-agents";
export const deleteManagerAgentPath = "/api/manager/delete-agent";
export const changeStatusManagerAgentPath = "/api/manager/change-agent-status";
export const getManagerManagersPath = "/api/manager/get-managers";

//PAYMENT
export const getPaymentHistoryPath = "/api/payments/history";

//DASHBOARD
export const dashboardGetStatisticsPath = "/api/dashboard/get-statistics";
export const dashboardGetStatisticsChartPath = "/api/dashboard/get-statistics-chart";

//AUTH TOKENS
export const setUserToken = ( value: string) => localStorage.setItem("migom_finance_access_token", value);
export const getUserToken = () => localStorage.getItem("migom_finance_access_token") || "";
export const removeUserToken = () => localStorage.removeItem("migom_finance_access_token");
