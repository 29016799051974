import styled from "styled-components";
import { poppinsFont } from "../../../styles/fonts";
import { grey } from "../../../styles/colors";

export const LoginFooterText = styled.p`
  font-family: ${poppinsFont};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${grey};
  margin: 0;
  margin-right: 5px;
  padding: 0;
`
