import React, { useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip } from "recharts";
import DashboardNav from "../../Navigation";
import { DashboardTableWrapper, DashboardTitle } from "../../../../../styles";
import {
  DashboardStatisticsContainer,
  DashboardStatisticsIcon,
  DashboardStatisticsIconWrapper,
  DashboardStatisticsItem,
  DashboardStatisticsItemDescription,
  DashboardStatisticsItemTitle,
} from "./styles";
import leadsIcon from "../../../../../assets/dashboard-all-lead.svg";
import paymentSystemsIcon from "../../../../../assets/dashboard-payment-systems.svg";
import agentsIcon from "../../../../../assets/dashboard-agents.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardGetStatisticsChart, dashboardGetStatisticsChartAgents, dashboardGetStatisticsChartPayments,
  dashboardGetStatisticsLeads,
} from "../../../../../slices/leadtSlice";
import { RootState } from "../../../../../store";

const DashboardStatistics = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dashboardGetStatisticsLeads());
    dispatch(dashboardGetStatisticsChart({ filter: "all" }));
    dispatch(dashboardGetStatisticsChartAgents({ filter: "agents" }));
    dispatch(dashboardGetStatisticsChartPayments({ filter: "payments" }));
  }, [dispatch]);

  const { dashboardStatistics, chartData, chartDataAgents, chartDataPayments } = useSelector(
    (state: RootState) => state.leads
  );

  return (
    <div style={{ display: "inline-flex" }}>
      <DashboardNav />
      <DashboardTableWrapper style={{overflow: "hidden", height: "100%"}}>
        <DashboardTitle>Dashboard</DashboardTitle>

        <DashboardStatisticsContainer style={{ marginTop: "-12px" }}>
          <DashboardStatisticsItem>
            <DashboardStatisticsIconWrapper>
              <DashboardStatisticsIcon src={leadsIcon} alt="Leads" />
            </DashboardStatisticsIconWrapper>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <DashboardStatisticsItemTitle>
                {dashboardStatistics.leads}
              </DashboardStatisticsItemTitle>
              <DashboardStatisticsItemDescription>
                Leads
              </DashboardStatisticsItemDescription>
            </div>
          </DashboardStatisticsItem>

          <DashboardStatisticsItem>
            <DashboardStatisticsIconWrapper>
              <DashboardStatisticsIcon src={agentsIcon} alt="Leads" />
            </DashboardStatisticsIconWrapper>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <DashboardStatisticsItemTitle>
                {dashboardStatistics.agents}
              </DashboardStatisticsItemTitle>
              <DashboardStatisticsItemDescription>
                Agents
              </DashboardStatisticsItemDescription>
            </div>
          </DashboardStatisticsItem>

          <DashboardStatisticsItem>
            <DashboardStatisticsIconWrapper>
              <DashboardStatisticsIcon src={paymentSystemsIcon} alt="Leads" />
            </DashboardStatisticsIconWrapper>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <DashboardStatisticsItemTitle>
                {dashboardStatistics.paymentSystems}
              </DashboardStatisticsItemTitle>
              <DashboardStatisticsItemDescription>
                Payment systems
              </DashboardStatisticsItemDescription>
            </div>
          </DashboardStatisticsItem>
        </DashboardStatisticsContainer>

        <DashboardTitle style={{marginTop: "24px", fontSize: "18px"}}>
          Leads
        </DashboardTitle>
        <div style={{ marginLeft: "-36px", marginTop: "-24px" }}>
          <LineChart width={950} height={200} data={chartData}>
            <Line type="monotone" dataKey="cnt" stroke="#253B86" />
            <XAxis dataKey="day_month" stroke="#929DC2" minTickGap={0} fontSize={14} tickMargin={8}/>
            <YAxis stroke="#929DC2" />
            <Tooltip labelStyle={{color: "#929DC2"}}/>
          </LineChart>
        </div>

        <DashboardTitle style={{marginTop: "24px", fontSize: "18px"}}>
          Agents
        </DashboardTitle>
        <div style={{ marginLeft: "-36px", marginTop: "-24px" }}>
          <LineChart width={950} height={200} data={chartDataAgents} >
            <Line type="monotone" dataKey="cnt" stroke="#253B86" />
            <XAxis dataKey="day_month" stroke="#929DC2" minTickGap={0} fontSize={14} tickMargin={8}/>
            <YAxis stroke="#929DC2" />
            <Tooltip labelStyle={{color: "#929DC2"}}/>
          </LineChart>
        </div>

        <DashboardTitle style={{marginTop: "24px", fontSize: "18px"}}>
          Payment systems
        </DashboardTitle>
        <div style={{ marginLeft: "-36px", marginTop: "-24px" }}>
          <LineChart width={950} height={200} data={chartDataPayments}>
            <Line type="monotone" dataKey="cnt" stroke="#253B86" />
            <XAxis dataKey="day_month" stroke="#929DC2" minTickGap={0} fontSize={14} tickMargin={8}/>
            <YAxis stroke="#929DC2" />
            <Tooltip labelStyle={{color: "#929DC2"}}/>
          </LineChart>
        </div>

      </DashboardTableWrapper>
    </div>
  );
};

export default DashboardStatistics;
