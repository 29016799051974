import React from "react";
import { useSelector } from "react-redux";
import { DashboardContent, DashboardLayout } from "../../../../styles";
import DashboardHeader from "../../../../components/layout/Dashboard/Header";
import { RootState } from "../../../../store";
import DashboardManagerAgents from "../../../../components/layout/Dashboard/Manager/Agents";

const DashboardManagerAgentsPage = () => {
  const {
    data: { name, ref_link, balance },
  } = useSelector((state: RootState) => state.user);

  return (
    <DashboardLayout>
      <DashboardContent>
        <DashboardHeader refLink={ref_link} showRef showBalance name={name} balance={balance} showCommunity/>
        <DashboardManagerAgents />
      </DashboardContent>
    </DashboardLayout>
  );
};

export default DashboardManagerAgentsPage;
