import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomeFormContainer, ModalLayout } from "../../styles";
import {
  PopupTransferName,
  PopupTransferRow,
  PopupTransferText,
} from "../../components/ui/PopupTransfer/style";
import { RootState } from "../../store";
import { BtnSubmitLoadingImg } from "../../components/ui/ButtonSubmit/style";
import { ClientEditButton, ClientInput, ClientInputWrap } from "./styles";
import editIcon from "../../assets/edit-client.svg";
import saveIcon from "../../assets/save-client.svg";
import { useForm } from "react-hook-form";
import { Status } from "../../types";
import { userGetInfo, userUpdateInfo } from "../../slices/userEditSlice";

interface Props {
  userId: number;
  onSaveCallback?: () => void;
}

const USER_EDIT_FIELDS = [
  {
    title: "Full name",
    slug: "name",
    type: "text",
  },
  {
    title: "Email",
    slug: "email",
    type: "text",
  },
];

const UserEditContainer: React.FunctionComponent<Props> = ({
  onSaveCallback,
  userId,
}) => {
  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm();

  const ref = useRef<HTMLDivElement>(null);

  const [isSave, setIsSave] = useState(false);

  const { user, status } = useSelector((state: RootState) => state.userEdit);

  useEffect(() => {
    dispatch(userGetInfo({ user_id: userId }));
  }, [dispatch, userId]);

  const onSubmit = async (data: any) => {
    if (isSave) {
      setIsSave(false);

      await dispatch(userUpdateInfo({ user_id: userId, data }));

      if (onSaveCallback) {
        onSaveCallback();
      }

      await dispatch(userGetInfo({ user_id: userId }));
    } else {
      setIsSave(true);
    }
  };

  if (status === Status.LOADING) {
    return (
      <ModalLayout isShow={true}>
        <HomeFormContainer
          style={{ marginTop: 0, alignItems: "center" }}
          ref={ref}
        >
          <BtnSubmitLoadingImg />
        </HomeFormContainer>
      </ModalLayout>
    );
  }

  return (
    <HomeFormContainer
      style={{ position: "relative", maxWidth: "none", minWidth: "680px" }}
      isModal
      ref={ref}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <ClientEditButton isSave={isSave} type="submit">
          <img src={isSave ? saveIcon : editIcon} alt="Client Edit" />
          {isSave ? "Save" : "Edit"}
        </ClientEditButton>

        {USER_EDIT_FIELDS.map((item, index) => {
          let value = "";

          if (user) {
            Object.keys(user).forEach((key) => {
              if (item.slug === key) {
                // @ts-ignore
                value = user[key];
              }
            });
          }

          return (
            <PopupTransferRow key={item.slug + "_" + index}>
              <PopupTransferName>{item.title}</PopupTransferName>

              {isSave ? (
                <ClientInputWrap>
                  <ClientInput defaultValue={value} {...register(item.slug)} />
                </ClientInputWrap>
              ) : (
                <PopupTransferText>{value}</PopupTransferText>
              )}
            </PopupTransferRow>
          );
        })}
      </form>
    </HomeFormContainer>
  );
};

export default UserEditContainer;
