import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { FormTitle, HomeFormContainer, ModalLayout } from "../../../styles";
import Input from "../../ui/Input";
import ButtonSubmit from "../../ui/ButtonSubmit";
import { LoginFooterText } from "./style";
import { InputForgotLink } from "../../ui/Input/style";
import ResetPassword from "../ResetPassword";
import {
  DASHBOARD,
  DASHBOARD_AGENTS, DASHBOARD_MANAGER,
  DASHBOARD_PAYMENTS,
  REGISTER,
} from "../../../constants/routes";
import { clearStatus, login } from "../../../slices/userSlice";
import { validateEmail } from "../../../constants/heplers";
import { RootState } from "../../../store";
import { Status } from "../../../types";
import ErrorMessage from "../../ui/ErrorMessage";

const Login: React.FunctionComponent = () => {
  let history = useHistory();
  let dispatch = useDispatch();

  const {
    isAuth,
    data: { type, is_admin },
    status,
    message,
  } = useSelector((state: RootState) => state.user);

  const [showModal, setShowModal] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    isEmailValid: true,
    isPasswordValid: true,
  });

  const handlerLogin = useCallback(() => {
    if (!validateEmail(loginData.email)) {
      setLoginData(() => ({
        ...loginData,
        isEmailValid: false,
      }));
    }

    if (loginData.password.length <= 0) {
      setLoginData(() => ({
        ...loginData,
        isPasswordValid: false,
      }));
    }

    if (validateEmail(loginData.email) && loginData.password.length > 0) {
      dispatch(
        login({
          email: loginData.email,
          password: loginData.password,
        })
      );
    }
  }, [dispatch, loginData]);

  useEffect(() => {
    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { keyCode } = event;

      if (keyCode === 13) {
        handlerLogin();
      }
    };

    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handlerLogin]);

  const handlerClose = () => {
    setShowModal(false);
  };

  const handleInputChange = (
    type: string,
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const value = e.currentTarget.value;

    if (status !== Status.IDLE) dispatch(clearStatus());

    if (type === "password") {
      setLoginData(() => ({
        ...loginData,
        password: value,
        isPasswordValid: true,
      }));
    }

    if (type === "email") {
      setLoginData(() => ({
        ...loginData,
        email: value,
        isEmailValid: true,
      }));
    }
  };

  if (isAuth) {
    if (is_admin) {
      return <Redirect to={DASHBOARD} />;
    }

    if (type === 1) {
      return <Redirect to={DASHBOARD_AGENTS} />;
    }

    if (type === 2) {
      return <Redirect to={DASHBOARD_PAYMENTS} />;
    }

    if (type === 3) {
      return <Redirect to={DASHBOARD_MANAGER} />;
    }

    if (type === 4) {
      return <Redirect to={DASHBOARD_AGENTS} />;
    }
  }

  return (
    <HomeFormContainer>
      <FormTitle>Log in</FormTitle>
      <div style={{ width: "100%", marginBottom: "24px" }}>
        <Input
          type="email"
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            handleInputChange("email", e)
          }
          placeholder=""
          labelText="Email"
        />
      </div>
      <div style={{ width: "100%", marginBottom: "36px" }}>
        <Input
          type="password"
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            handleInputChange("password", e)
          }
          placeholder=""
          labelText="Password"
          showForgot
          showForgotCallback={() => setShowModal(true)}
        />
      </div>

      {status === Status.FAILED && (
        <div style={{ marginTop: "-12px", marginBottom: "24px" }}>
          <ErrorMessage errorText={message} />
        </div>
      )}

      {!loginData.isEmailValid && (
        <div style={{ marginTop: "-12px", marginBottom: "24px" }}>
          <ErrorMessage errorText="Please enter your email" />
        </div>
      )}

      {!loginData.isPasswordValid && (
        <div style={{ marginTop: "-12px", marginBottom: "24px" }}>
          <ErrorMessage errorText="Incorrect email or password" />
        </div>
      )}

      <div style={{ width: "100%", marginBottom: "36px" }}>
        <ButtonSubmit
          title="Log In"
          onClick={handlerLogin}
          isDisable={!loginData.isEmailValid || !loginData.isPasswordValid}
        />
      </div>

      <div style={{ display: "flex" }}>
        <LoginFooterText>Don’t have account?</LoginFooterText>
        <InputForgotLink onClick={() => history.push(REGISTER)}>
          Sign Up
        </InputForgotLink>
      </div>

      <ModalLayout isShow={showModal}>
        <ResetPassword onClose={handlerClose} />
      </ModalLayout>
    </HomeFormContainer>
  );
};

export default Login;
