import styled from "styled-components";
import { blue, lightLightBlue, orange } from "../../../../styles/colors";
import { poppinsFont } from "../../../../styles/fonts";

export const RegisterConfirmText = styled.p`
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${lightLightBlue};
  margin: 0;
  margin-bottom: 24px;
  
  :last-of-type {
    margin-bottom: 36px;
  }
  
  strong {
    color: ${blue};
  }
`

export const RegisterConfirmEmail = styled.a`
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${orange};
  text-decoration: none;
  margin: 0;
  padding: 0;
`
