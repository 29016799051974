import styled from "styled-components";
import { globalFont } from "../../../styles/fonts";
import { orange } from "../../../styles/colors";

export const BtnDeclineElement = styled.button`
  font-family: ${globalFont};
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: ${orange};  
  background-color: #ffffff;
  width: 100%;
  height: 56px;
  border: 1px solid #FE5728;
  box-sizing: border-box;  
  outline: none;  
  
  :disabled {
    background: rgba(254, 87, 40, 0.3);
  }
  
  :hover:not(:disabled) {
    border: 1px solid #E54F24;
    color: #E54F24;
  }
`
