import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {  adminGetPaymentSystems, userChangeBalance, userChangeStatus } from "../../../../../slices/userSlice";
import { RootState } from "../../../../../store";
import DashboardNav from "../../Navigation";
import { DashboardTableWrapper, FormSubTitle, FormTitle, HomeFormContainer, ModalLayout } from "../../../../../styles";
import Table from "../../../../ui/Table";
import { TableActions, TableActionsItem, TableContainerWrapper } from "../../../../ui/Table/style";
import useComponentVisible from "../../../../../constants/hooks";
import Switcher from "../../../../ui/Switch";
import Input from "../../../../ui/Input";
import ButtonSubmit from "../../../../ui/ButtonSubmit";
import { InputLabel } from "../../../../ui/Input/style";
import SelectSimple from "../../../../ui/SelectSimple";
import { PopupTransferBtnContainer, PopupTransferBtnWrap } from "../../../../ui/PopupTransfer/style";
import ButtonDecline from "../../../../ui/ButtonDecline";
import { deleteManagerAgent } from "../../../../../slices/leadtSlice";
import { TABLE_TITLES_ADMIN_PAYMENTS } from "../../../../../constants/tableTitles";

const SWITCH_ITEMS = [
  {
    id: 1,
    name: "Top Up",
  },
  {
    id: 2,
    name: "Charge",
  }
];

const AGENT_STATUS = [
  {
    id: "active",
    name: "Active",
  },
  {
    id: "disable",
    name: "Disable",
  }
];

const DashboardAdminPayments = () => {
  const dispatch = useDispatch();

  const [showStatus, setShowStatus] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [actionsObj, setActionsObj] = useState({ x: 0, y: 0, id: 0 });
  const [currentStatus, setCurrentStatus] = useState('reg');
  const [typeBalance, setTypeBalance] = useState(1);
  const [amount, setAmount] = useState("0");

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const refStatus = useRef<HTMLDivElement>(null);
  const refTransfer = useRef<HTMLDivElement>(null);
  const refDelete = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (refStatus.current && !refStatus.current.contains(event.target as Node)) {
      setShowTransfer(() => false);
    }

    if (refTransfer.current && !refTransfer.current.contains(event.target as Node)) {
      setShowStatus(() => false);
    }

    if (refDelete.current && !refDelete.current.contains(event.target as Node)) {
      setShowDelete(() => false);
    }
  },[]);

  useEffect(() => {
    dispatch(adminGetPaymentSystems());

    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { keyCode } = event;

      if (keyCode === 27) {
        setIsComponentVisible(() => false);
        setShowTransfer(() => false);
        setShowStatus(() => false);
        setShowDelete(() => false);
      }
    };

    document.addEventListener("keydown", handleUserKeyPress);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [dispatch,handleClickOutside,setIsComponentVisible])

  const { admin_data } = useSelector((state: RootState) => state.user);

  const handlerSubmit = async () => {

    await dispatch(userChangeBalance({user_id: actionsObj.id, balance: amount, type: typeBalance}));
    await dispatch(adminGetPaymentSystems());

    setShowTransfer(false);
  }

  const handleChange = async () => {
    await dispatch(userChangeStatus({ status: currentStatus, user_id: actionsObj.id }));
    await dispatch(adminGetPaymentSystems());

    setShowStatus(false);
  }

  const handleChangeStatus = () => {
    setShowStatus(true)
  }

  const amountChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    setAmount(value);
  }

  const handleCurrentStatus = (value: number | string) => {
    setCurrentStatus(value.toString());
  }

  const handleDelete = async () => {
    await dispatch(deleteManagerAgent({ user_id: actionsObj.id }));
    await dispatch(adminGetPaymentSystems());

    setShowDelete(false);
  };

  const handleOrder = async (order: string, field: string) => {
    await dispatch(
        adminGetPaymentSystems({ order: { type: order, field } })
    );
  };

  const findItem = admin_data.filter((item: any) => item.id === actionsObj.id )[0]

  return (
      <div style={{ display: "flex" }}>
        <DashboardNav/>
        <DashboardTableWrapper>
          <TableContainerWrapper>
            <Table
                headTitles={TABLE_TITLES_ADMIN_PAYMENTS}
                tableItems={admin_data ? admin_data : []}
                onClickRow={() => {
                }}
                onClickCellAction={(e, id) => {
                  const { left, top } = e.currentTarget.getBoundingClientRect();
                  setIsComponentVisible(true);

                  setActionsObj({ x: left, y: top, id });
                }}
                onOrderCallback={handleOrder}
            />
          </TableContainerWrapper>
        </DashboardTableWrapper>

        <ModalLayout isShow={showTransfer}>
          <HomeFormContainer ref={refStatus} isModal>
            <FormTitle>{findItem ? findItem.name : ""}</FormTitle>
            <FormSubTitle>Available: {findItem ? findItem.balance : ""} €</FormSubTitle>
            <Switcher onCallbackChange={(item) => setTypeBalance(item)} items={SWITCH_ITEMS}/>
            <div style={{marginBottom: "24px" }}/>
            <Input labelText="Amount, €" placeholder="" type="text" onChange={amountChange}/>
            <div style={{marginBottom: "36px" }}/>
            <ButtonSubmit title="Submit" onClick={handlerSubmit}/>
          </HomeFormContainer>
        </ModalLayout>

        <ModalLayout isShow={showStatus}>
            <HomeFormContainer ref={refTransfer} isModal>
              <FormTitle>Change agents status</FormTitle>
              <InputLabel>Change to</InputLabel>
              <div style={{marginBottom: "36px", width: "100%"}}>
                <SelectSimple items={AGENT_STATUS} onCallbackChange={handleCurrentStatus} />
              </div>
              <ButtonSubmit title="Change" onClick={handleChange} />
            </HomeFormContainer>
        </ModalLayout>

        <ModalLayout isShow={showDelete}>
            <HomeFormContainer ref={refDelete} isModal>
              <FormTitle style={{ marginBottom: 0 }}>Delete {findItem?.name}?</FormTitle>
              <FormSubTitle
                  style={{
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontWeight: 500,
                    marginTop: "12px",
                    marginBottom: 0,
                  }}
              >
                Are you sure? This action cannot be changed.
              </FormSubTitle>
              <PopupTransferBtnContainer style={{ marginTop: "36px" }}>
                <PopupTransferBtnWrap>
                  <ButtonDecline
                      title="Cancel"
                      onClick={() => setShowDelete(false)}
                  />
                </PopupTransferBtnWrap>
                <PopupTransferBtnWrap>
                  <ButtonSubmit title="Delete" onClick={handleDelete} />
                </PopupTransferBtnWrap>
              </PopupTransferBtnContainer>
            </HomeFormContainer>
        </ModalLayout>

        <TableActions
            ref={ref}
            isVisible={isComponentVisible}
            x={actionsObj.x}
            y={actionsObj.y}
        >
          <TableActionsItem onClick={() => setShowTransfer(true)}>
            Balance
          </TableActionsItem>
          <TableActionsItem onClick={handleChangeStatus}>
            Change status
          </TableActionsItem>
          <TableActionsItem onClick={() => setShowDelete(true)}>
            Delete
          </TableActionsItem>
        </TableActions>

      </div>
  );
};

export default DashboardAdminPayments;
