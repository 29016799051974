import { keyframes } from "styled-components";

export const RotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Fade = keyframes`
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.3;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
`;

export const SlideUp = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const PulseBlue = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(70, 144, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(70, 144, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(70, 144, 255, 0);
  }
`

export const PulseLightBlue = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(146, 157, 194, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(146, 157, 194, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(146, 157, 194, 0);
  }
`

export const PulseBlueBlue = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(96, 40, 254, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(96, 40, 254, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(96, 40, 254, 0);
  }
`

export const PulseOrange = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(250, 177, 69, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(250, 177, 69, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(250, 177, 69, 0);
  }
`

export const PulseGreen = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(58, 212, 150, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(58, 212, 150, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(58, 212, 150, 0);
  }
`

export const PulseRed = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(254, 40, 40, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(254, 40, 40, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(254, 40, 40, 0);
  }
`
