import styled from "styled-components";
import { black } from "../../../../styles/colors";
import { poppinsFont } from "../../../../styles/fonts";
import openDownImg from "../../../../assets/select-arrow-down.svg";

export const DashboardHeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
  padding-left: 24px;
  margin-top: 19px;
  margin-bottom: 17px;
  width: 100%;
  
  @media (max-width: 640px) {
    flex-direction: column;
  }
`

export const DashboardHeaderBalance = styled.div`
  display: flex;
  align-items: center;
  background: #253B86;
  border-radius: 100px;  
  padding: 12px 16px;
  margin-right: 36px;
  height: 46px;
`

export const DashboardHeaderText = styled.p`  
  font-family: ${poppinsFont};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  margin-right: 6px;
  padding: 0;
`

export const DashboardHeaderMoney = styled.p`
  font-family: ${poppinsFont};
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  margin: 0;
  padding: 0;
`

export const DashboardHeaderMenu = styled.div`
  display: flex;
  align-items: center;  
  position: relative;
  
  :hover {
    cursor: pointer;
  }
`

export const DashboardHeaderMenuText = styled.p`
  font-family: ${poppinsFont};
  font-size: 14px;
  line-height: 21px;
  color: ${black};
  margin-left: 12px;
  margin-right: 4px;
`

export const DashboardHeaderMenuAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  background-color: #ffffff;
  border-radius: 50%;
`

export const DashboardHeaderMenuOpen = styled.img.attrs({
  src: openDownImg,
  alt: "Open / Close"
})<{isOpen: boolean}>`
  transform: ${ props=> props.isOpen ? "rotate(180deg)" : ""};
  transition: all 0.2s linear;
`

export const DashboardHeaderAuthMenu = styled.div<{ isVisible?: boolean }>`
  position: absolute;    
  top: 50px;
  right: 0;
  display: ${ props => props.isVisible ? "flex" : "none" };  
  flex-direction: column;  
  background-color: #ffffff;
  box-shadow: 6px 6px 24px rgba(37, 59, 134, 0.11);
  border-radius: 6px;
  min-width: 134px;
`

export const DashboardHeaderAuthMenuText = styled.a`
  font-family: ${poppinsFont};
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #171717;
  margin: 0;
  white-space: nowrap;
  height: 47px;
  padding: 12px;
  text-decoration: none;
  border-bottom: 1px solid rgba(37, 59, 134, 0.1);

  img {
    margin-right: 6px;
  }
  
  :hover {
    background-color: rgba(233, 235, 243, 0.4);
  }
  
  :last-of-type {
    border-bottom: none;
  }
`

export const DashboardTg = styled.div`
  position: relative;
  background: #FFFFFF;
  box-shadow: 2px 2px 8px rgba(37, 59, 134, 0.1);
  border-radius: 50%;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;

  .community-hover {
    :hover {
      display: flex;
    }
  }
  
  :hover {
    cursor: pointer;
    
    .community-hover {
      display: flex;
     

    }
  }
`
