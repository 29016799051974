import React, { useEffect, useState } from "react";
import { DashboardTitle, DashboardWrapper } from "../../../../styles";
import Table from "../../../ui/Table";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { getLeads } from "../../../../slices/leadtSlice";
import { getUser } from "../../../../slices/userSlice";
import { TableContainerWrapper } from "../../../ui/Table/style";
import Filter from "../../../ui/Filter";
import {
  FILTER_ITEM_LEADS,
  TABLE_TITLES_AGENT_LEADS,
} from "../../../../constants/tableTitles";

const DashboardAgents = () => {
  const dispatch = useDispatch();

  const [slug, setSlug] = useState("all");

  const { data } = useSelector((state: RootState) => state.leads);

  useEffect(() => {
    dispatch(getLeads({ filter: slug }));
    dispatch(getUser());
  }, [dispatch, slug]);

  const handleSelect = async (slug: string) => {
    await dispatch(getLeads({ filter: slug }));
    setSlug(slug);
  };

  const handleOrder = async (order: string, field: string) => {
    await dispatch(getLeads({ filter: slug, order: { type: order, field } }));
  };

  return (
    <DashboardWrapper>
      <DashboardTitle style={{ marginBottom: "12px" }}>
        Your leads
      </DashboardTitle>

      <Filter items={FILTER_ITEM_LEADS} onSelectCallback={handleSelect} />

      <TableContainerWrapper style={{ marginBottom: "104px" }}>
        <Table
          onClickRow={() => {}}
          headTitles={TABLE_TITLES_AGENT_LEADS}
          tableItems={data ? data : []}
          onOrderCallback={handleOrder}
        />
      </TableContainerWrapper>
    </DashboardWrapper>
  );
};

export default DashboardAgents;
