import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderContainer, HeaderLogo } from "./style";
import { HOME } from "../../../constants/routes";

const HeaderHome = () => {
  return (
      <HeaderContainer>
        <Link to={HOME}>
          <HeaderLogo/>
        </Link>
      </HeaderContainer>
  );
};

export default HeaderHome;
