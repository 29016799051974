import axios, { AxiosRequestConfig } from 'axios';
import {
  baseUrl,
  getUserToken,
  loginPath,
  logoutPath,
  newPasswordPath,
  registerPath, registerTokenRestorePath,
  removeUserToken,
  resetPasswordPath,
  setUserToken
} from '../constants/api';
import { LoginData, RegisterData } from "../types";

interface AuthHeader {
  Authorization: string;
}

export const getAuthHeader = (token: string): AuthHeader => ({ Authorization: `Bearer ${token}` });

export const axiosGlobalConfig = (): AxiosRequestConfig => ({
  headers: getAuthHeader(getUserToken())
});

export interface JWTData {
  data: {
    access_token?: string;
    is_admin: null | boolean;
    type: null | number;
    sumsub_token?: { token: string; userId: string };
  };
  error: boolean;
  message: string;
}

export const registerApi = async (data: RegisterData) => {
  try {
    const response = await axios.post<JWTData>(`${baseUrl}${registerPath}`, data);
    const { data: data_1 } = response.data;

    if (response.status === 200 && data_1.access_token) {
      setUserToken(data_1.access_token);
      return response.data;
    }

  } catch (err) {
    const { response: { data } } = err;

    if(data && data?.message) {
      return await Promise.reject(data?.message);
    }

    return await Promise.reject("Error Register!");
  }
};

export const loginApi = async (data: LoginData) => {
  try {
    const response = await axios.post<JWTData>(`${baseUrl}${loginPath}`, data);
    const { data: data_1 } = response.data;

    if (response.status === 200 && data_1.access_token) {
      setUserToken(data_1.access_token);

      return response.data;
    }

  } catch (err) {
    const { response: { data } } = err;

    if(data && data?.message) {
      return await Promise.reject(data?.message);
    }

    return await Promise.reject("Error Login!");
  }
};

export const logoutApi = async (token: string) => {
  const config: AxiosRequestConfig = {
    headers: getAuthHeader(token),
  };
  try {
    const response = await axios.post(`${baseUrl}${logoutPath}`, null, config);
    removeUserToken();
    return response.data;
  } catch (err) {
    return await Promise.reject('Logout Failed!');
  }
};

interface PasswordResetData {
  message: string;
  error: string;
}

export const resetPasswordApi = async (email: string) => {
  try {
    const response = await axios.post<PasswordResetData>(`${baseUrl}${resetPasswordPath}`, { email });

    if (response.status === 200) {
      return response.data;
    }

  } catch (err) {
    return await Promise.reject('Failed!');
  }
};

export const newPasswordApi = async (password: string, token: string | null) => {
  try {
    const response = await axios.post<PasswordResetData>(`${baseUrl}${newPasswordPath}`, { password, token });

    if (response.status === 200) {
      return response.data;
    }

  } catch (err) {
    return await Promise.reject('Authentication Failed!');
  }
};


export const registerTokenRestoreApi = async (hash: string) => {
  try {
    const response = await axios.post<JWTData>(`${baseUrl}${registerTokenRestorePath}`, { hash });

    if(response.data.data.access_token) {
      setUserToken(response.data.data.access_token);
    }

    return response.data.data;

  } catch (err) {
    return await Promise.reject('Failed!');
  }
};
