import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminGetManagers,
  adminSetUserPercent,
  userChangeBalance,
} from "../../../../../slices/userSlice";
import { RootState } from "../../../../../store";
import DashboardNav from "../../Navigation";
import {
  DashboardTableWrapper,
  FormSubTitle,
  FormTitle,
  HomeFormContainer,
} from "../../../../../styles";
import Table from "../../../../ui/Table";
import {
  TableActions,
  TableActionsItem,
  TableContainerWrapper,
} from "../../../../ui/Table/style";
import useComponentVisible from "../../../../../constants/hooks";
import Switcher from "../../../../ui/Switch";
import Input from "../../../../ui/Input";
import ButtonSubmit from "../../../../ui/ButtonSubmit";
import { deleteManagerAgent } from "../../../../../slices/leadtSlice";
import {
  PopupTransferBtnContainer,
  PopupTransferBtnWrap,
} from "../../../../ui/PopupTransfer/style";
import ButtonDecline from "../../../../ui/ButtonDecline";
import { TABLE_TITLES_ADMIN_MANAGERS } from "../../../../../constants/tableTitles";
import Modal from "../../../../ui/Modal";
import UserEditContainer from "../../../../../containers/UserEdit";

const SWITCH_ITEMS = [
  {
    id: 1,
    name: "Top Up",
  },
  {
    id: 2,
    name: "Charge",
  },
];

const DashboardAdminManagers = () => {
  const dispatch = useDispatch();

  const [showTransfer, setShowTransfer] = useState(false);
  const [showCommission, setShowCommission] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [typeBalance, setTypeBalance] = useState(1);
  const [actionsObj, setActionsObj] = useState({ x: 0, y: 0, id: 0 });
  const [amount, setAmount] = useState("0");
  const [percent, setPercent] = useState(10);

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  useEffect(() => {
    dispatch(adminGetManagers());

    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { keyCode } = event;

      if (keyCode === 27) {
        setIsComponentVisible(() => false);
      }
    };

    document.addEventListener("keydown", handleUserKeyPress);
    return () => {
      document.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [dispatch, setIsComponentVisible]);

  const { admin_data_managers } = useSelector((state: RootState) => state.user);

  const handlerSubmit = async () => {
    await dispatch(
      userChangeBalance({
        user_id: actionsObj.id,
        balance: amount,
        type: typeBalance,
      })
    );
    await dispatch(adminGetManagers());

    setShowTransfer(false);
  };

  const amountChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    setAmount(value);
  };

  const handlePercentChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = parseInt(e.currentTarget.value);

    setPercent(value);
  };

  const handlePercent = async () => {
    await dispatch(adminSetUserPercent({ percent, id: actionsObj.id }));
    await dispatch(adminGetManagers());

    setShowCommission(false);
  };

  const handleDelete = async () => {
    await dispatch(deleteManagerAgent({ user_id: actionsObj.id }));
    await dispatch(adminGetManagers());

    setShowDelete(false);
  };

  const handleOrder = async (order: string, field: string) => {
    await dispatch(adminGetManagers({ order: { type: order, field } }));
  };

  const handleSaveDetails = async () => {
    await dispatch(adminGetManagers());
  }

  const findItem = admin_data_managers.filter(
    (item: any) => item.id === actionsObj.id
  )[0];

  return (
    <div style={{ display: "flex" }}>
      <DashboardNav />

      <DashboardTableWrapper>
        <TableContainerWrapper>
          <Table
            headTitles={TABLE_TITLES_ADMIN_MANAGERS}
            tableItems={admin_data_managers}
            onClickRow={() => {}}
            onClickCellAction={(e, id) => {
              const { left, top } = e.currentTarget.getBoundingClientRect();
              setIsComponentVisible(true);

              setActionsObj({ x: left, y: top, id });
            }}
            onOrderCallback={handleOrder}
          />
        </TableContainerWrapper>
      </DashboardTableWrapper>

      <Modal isVisible={showTransfer} onCloseCallback={() => setShowTransfer(false)}>
        <HomeFormContainer isModal>
          <FormTitle>{findItem ? findItem.name : ""}</FormTitle>
          <FormSubTitle>
            Available: {findItem ? findItem.balance : ""} €
          </FormSubTitle>
          <Switcher
            onCallbackChange={(item) => setTypeBalance(item)}
            items={SWITCH_ITEMS}
          />
          <div style={{ marginBottom: "24px" }} />
          <Input
            labelText="Amount, €"
            placeholder=""
            type="text"
            onChange={amountChange}
          />
          <div style={{ marginBottom: "36px" }} />
          <ButtonSubmit title="Submit" onClick={handlerSubmit} />
        </HomeFormContainer>
      </Modal>

      <Modal isVisible={showCommission} onCloseCallback={() => setShowCommission(false)}>
        <HomeFormContainer isModal>
          <FormTitle>{findItem ? findItem.name : ""}</FormTitle>

          <FormSubTitle style={{ marginBottom: "24px" }}>
            Current commission: {findItem ? findItem.bonus : ""}
            {"%"}
          </FormSubTitle>

          <Input
            labelText="Set manager bonus, %"
            placeholder=""
            type="text"
            onChange={handlePercentChange}
          />
          <div style={{ marginBottom: "36px" }} />
          <ButtonSubmit title="Submit" onClick={handlePercent} />
        </HomeFormContainer>
      </Modal>

      <Modal isVisible={showDelete} onCloseCallback={() => setShowDelete(false)}>
        <HomeFormContainer isModal>
          <FormTitle style={{ marginBottom: 0 }}>Delete {findItem?.name}?</FormTitle>
          <FormSubTitle
            style={{
              fontSize: "16px",
              lineHeight: "28px",
              fontWeight: 500,
              marginTop: "12px",
              marginBottom: 0,
            }}
          >
            Are you sure? This action cannot be changed.
          </FormSubTitle>
          <PopupTransferBtnContainer style={{ marginTop: "36px" }}>
            <PopupTransferBtnWrap>
              <ButtonDecline
                title="Cancel"
                onClick={() => setShowDelete(false)}
              />
            </PopupTransferBtnWrap>

            <PopupTransferBtnWrap>
              <ButtonSubmit title="Delete" onClick={handleDelete} />
            </PopupTransferBtnWrap>
          </PopupTransferBtnContainer>
        </HomeFormContainer>
      </Modal>

      <Modal isVisible={showDetails} onCloseCallback={() => setShowDetails(false)}>
        <UserEditContainer onSaveCallback={handleSaveDetails} userId={actionsObj.id} />
      </Modal>

      <TableActions
        ref={ref}
        isVisible={isComponentVisible}
        x={actionsObj.x}
        y={actionsObj.y}
      >
        <TableActionsItem onClick={() => setShowDetails(true)}>
          Details
        </TableActionsItem>
        <TableActionsItem onClick={() => setShowTransfer(true)}>
          Balance
        </TableActionsItem>
        <TableActionsItem onClick={() => setShowCommission(true)}>
          Manager bonus
        </TableActionsItem>
        <TableActionsItem onClick={() => setShowDelete(true)}>
          Delete
        </TableActionsItem>
      </TableActions>
    </div>
  );
};

export default DashboardAdminManagers;
