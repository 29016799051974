import React, { useState } from "react";
import { TableHeadContainer } from "./style";
import sortAscIcon from "../../../../assets/sort.svg";
import sortDescIcon from "../../../../assets/sort-desc.svg";

interface Props {
  order?: string;
  onClickCallback?: (order: string) => void;
  isSticky?: boolean;
  isStickyBtn?: boolean;
}

const TableHeadRow: React.FunctionComponent<Props> = (props) => {
  const [currentOrder, setCurrentOrder] = useState("asc");

  return (
    <TableHeadContainer
      className={
        props.isSticky
          ? "column-sticky-th"
          : props.isStickyBtn
          ? "column-sticky-btn-th"
          : ""
      }
      showSort={!!props.order}
      onClick={() => {
        if (props.onClickCallback) {
          let newOrder = "";

          if (currentOrder === "asc") {
            newOrder = "desc";
          }

          if (currentOrder === "desc") {
            newOrder = "asc";
          }

          setCurrentOrder(newOrder);

          props.onClickCallback(newOrder);
        }
      }}
    >
      {props.isSticky ? (
        <div className="column-sticky-th-div">
          {props.children}
          {props?.order && currentOrder === "asc" && (
            <img src={sortAscIcon} alt="Order" />
          )}
          {props?.order && currentOrder === "desc" && (
            <img src={sortDescIcon} alt="Order" />
          )}
        </div>
      ) : props.isStickyBtn ? (
        <div className="column-sticky-th-div">
          {props.children}
          {props?.order && currentOrder === "asc" && (
            <img src={sortAscIcon} alt="Order" />
          )}
          {props?.order && currentOrder === "desc" && (
            <img src={sortDescIcon} alt="Order" />
          )}
        </div>
      ) : (
        <>
          {props.children}
          {props?.order && currentOrder === "asc" && (
            <img src={sortAscIcon} alt="Order" />
          )}
          {props?.order && currentOrder === "desc" && (
            <img src={sortDescIcon} alt="Order" />
          )}
        </>
      )}
    </TableHeadContainer>
  );
};

export default TableHeadRow;
