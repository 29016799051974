import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userGetInfoApi, userUpdateInfoApi } from "../api/user";
import { Status, UserInfo } from "../types";

interface UserEditState {
  status: Status;
  user: UserInfo | null
}

const initialState: UserEditState = {
  status: Status.IDLE,
  user: null
};

export const userGetInfo = createAsyncThunk(
  "userEdit/get-info",
  async (data: { user_id: number }) => {
    return await userGetInfoApi(data);
  }
);

export const userUpdateInfo = createAsyncThunk(
    "userEdit/update-info",
    async (data: { user_id: number, data: any }) => {
      return await userUpdateInfoApi(data);
    }
);

export const userEditSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.status = Status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userGetInfo.fulfilled, (state, action) => {
      state.user = action.payload;
      state.status = Status.SUCCEEDED
    });

    builder.addCase(userUpdateInfo.pending, (state) => {
      state.status = Status.LOADING
    });
  },
});

export const { clearStatus } = userEditSlice.actions;

export default userEditSlice.reducer;
