import React, { useCallback, useEffect, useRef } from "react";
import { ModalLayout } from "../../../styles";

interface Props {
  isVisible: boolean;
  onCloseCallback: () => void;
}

const Modal: React.FunctionComponent<Props> = ({
  isVisible,
  children,
  onCloseCallback,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onCloseCallback();
      }
    },
    [ref, onCloseCallback]
  );

  useEffect(() => {
    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { keyCode } = event;

      if (keyCode === 27) {
        onCloseCallback();
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("keydown", handleUserKeyPress);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleClickOutside, onCloseCallback]);

  return (
    <ModalLayout isShow={isVisible}>
      {isVisible && <div ref={ref}>{children}</div>}
    </ModalLayout>
  );
};

export default Modal;
