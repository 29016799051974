import React, { useState } from 'react';
import { FormTitle, HomeFormContainer } from "../../../styles";
import { ResetPasswordClose, ResetPasswordCloseIcon, ResetPasswordWrapper } from "./style";
import ButtonSubmit from "../../ui/ButtonSubmit";
import Input from "../../ui/Input";
import { validateEmail } from "../../../constants/heplers";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, setForgotEmail } from "../../../slices/forgotSlice";
import { Redirect } from "react-router-dom";
import { REGISTER_CONFIRM } from "../../../constants/routes";
import { RootState } from "../../../store";
import { Status } from "../../../types";
import { redColor } from "../../../styles/colors";

interface Props {
  onClose(): void;
}

const ResetPassword: React.FunctionComponent<Props> = (props) => {

  const dispatch = useDispatch();

  const { isSent, status } = useSelector((state: RootState) => state.forgot);

  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handlerChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    setEmail(value);
  }

  const handlerReset = () => {

    if(!validateEmail(email)) {
      setIsValid(false);
    } else {
      setIsValid(true);
      dispatch(resetPassword(email));
      dispatch(setForgotEmail(email));
    }
  }

  if(isSent) {
    return  <Redirect to={REGISTER_CONFIRM} />;
  }

  return (
      <ResetPasswordWrapper>
        <HomeFormContainer style={{ position: "relative", marginTop: 0 }}>
          <FormTitle>Reset your password</FormTitle>

          <ResetPasswordClose onClick={props.onClose}>
            <ResetPasswordCloseIcon />
          </ResetPasswordClose>

          <div style={{ width: "100%", marginBottom: "36px" }}>
            <Input
                labelText="Email"
                placeholder=""
                type="email"
                onChange={handlerChange}
                isError={!isValid}
            />
          </div>

          {status === Status.FAILED && <p style={{ marginBottom: "24px", marginTop: "-12px", color: redColor }}>Something went wrong try later...</p>}

          <ButtonSubmit
              title="Reset password"
              onClick={handlerReset}
              isDisable={status === Status.LOADING}
          />

        </HomeFormContainer>
      </ResetPasswordWrapper>
  );
};

export default ResetPassword;
