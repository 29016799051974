import styled  from "styled-components";
import { globalFont } from "../../../styles/fonts";
import { orange } from "../../../styles/colors";
import loadingIcon from "../../../assets/loading.svg";
import { RotateAnimation } from "../../../styles/animations";

export const BtnSubmitElement = styled.button`
  font-family: ${globalFont};
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  background-color: ${orange};
  width: 100%;
  height: 56px;
  border: none;
  outline: none;
  box-sizing: border-box;

  :disabled {
    background: rgba(254, 87, 40, 0.3);
  }

  :hover:not(:disabled) {
    background: #e64f24;
  }
`;

export const BtnSubmitLoadingImg = styled.img.attrs({
  src: loadingIcon,
  alt: "Loading....",
})`
  vertical-align: top;
  animation: ${RotateAnimation} 1.2s infinite;
`;


