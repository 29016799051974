import styled from "styled-components";
import { poppinsFont } from "../../../styles/fonts";
import { black, lightLightBlue } from "../../../styles/colors";

export const PopupTransferWrapper = styled.div`
  margin: 0 auto;
`

export const PopupTransferBtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const PopupTransferBtnWrap = styled.div`
  width: 100%;
  margin-right: 24px;
  
  :last-of-type {
    margin-right: 0;
  }
`


export const PopupTransferRow = styled.div`
  display: flex; 
  align-items: center;
  border-bottom: 1px solid rgba(146, 157, 194, 0.2);
  margin-bottom: 12px;
  
  :last-of-type {
    margin-bottom: 0;
    border-bottom: none;
  }
`

export const PopupTransferName = styled.p`
  font-family: ${poppinsFont};  
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 12px;
  
  padding: 0;
  width: 50%;
  color: ${lightLightBlue};
`

export const PopupTransferText = styled.p`
  font-family: ${poppinsFont};  
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
  width: 50%;
  color: ${black};
  
  img {
    height: 24px;
    width: 24px;
    text-align: left;    
    vertical-align: top;
  }
`
