import React from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { DashboardContent, DashboardLayout } from "../../../styles";
import DashboardHeader from "../../../components/layout/Dashboard/Header";
import DashboardStatistics from "../../../components/layout/Dashboard/Admin/Statistics";


const DashboardStatisticsPage = () => {
  const {
    data: { name },
  } = useSelector((state: RootState) => state.user);

  return (
      <DashboardLayout>
        <DashboardContent>
          <DashboardHeader refLink={null} name={name} />
          <DashboardStatistics />
        </DashboardContent>
      </DashboardLayout>
  );
};

export default DashboardStatisticsPage;
