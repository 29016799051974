import React, { useEffect, useState } from "react";
import {
  DashboardTitle,
  DashboardWrapper,
  FormSubTitle,
  FormTitle,
  HomeFormContainer,
  ModalLayout,
} from "../../../../styles";
import Table from "../../../ui/Table";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  clearEnough,
  clearLeadStatus,
  getLeadsPayments,
  leadChangeStatus,
} from "../../../../slices/leadtSlice";
import { Status } from "../../../../types";
import ButtonSubmit from "../../../ui/ButtonSubmit";
import ClientDetailsContainer from "../../../../containers/ClientDetails";
import { TableContainerWrapper } from "../../../ui/Table/style";
import { InputForgotLink } from "../../../ui/Input/style";
import Filter from "../../../ui/Filter";
import { TABLE_TITLES_PAYMENT } from "../../../../constants/tableTitles";

const FILTER_ITEMS = [
  {
    name: "All",
    slug: "all",
  },
  {
    name: "Individuals",
    slug: "individual",
  },
  {
    name: "Business entity",
    slug: "business",
  },
];

const DashboardPayments = () => {
  const dispatch = useDispatch();

  const [showTransfer, setShowTransfer] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [currentSlug, setCurrentSlug] = useState("all");

  const { data_payments, change_status, is_enough, change_status_decline } = useSelector(
    (state: RootState) => state.leads
  );

  useEffect(() => {
    dispatch(getLeadsPayments({ filter: currentSlug }));

    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { keyCode } = event;

      if (keyCode === 27) {
        setShowTransfer(() => false);
        dispatch(clearEnough());
      }
    };

    document.addEventListener("keydown", handleUserKeyPress);
    return () => {
      document.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [dispatch, currentSlug]);

  const handlerCloseAccept = async () => {
    await dispatch(clearLeadStatus());
  };

  const handlerEnough = async () => {
    await dispatch(clearEnough());
  };

  const handleSelect = async (slug: string) => {
    await dispatch(getLeadsPayments({ filter: slug }));
    setCurrentSlug(slug);
  };

  const handlerAccept = async (id: any) => {
    await dispatch(leadChangeStatus({ status: "approve", id }));
    await dispatch(getLeadsPayments({ filter: currentSlug }));
  };

  const handleOrder = async (order: string, field: string) => {
    await dispatch(
      getLeadsPayments({ filter: currentSlug, order: { type: order, field } })
    );
  };

  return (
    <DashboardWrapper>
      <DashboardTitle style={{ marginBottom: "12px" }}>
        Your leads
      </DashboardTitle>

      <Filter items={FILTER_ITEMS} onSelectCallback={handleSelect} />

      <TableContainerWrapper style={{ marginBottom: "104px" }}>
        <Table
          onClickRow={() => {}}
          headTitles={TABLE_TITLES_PAYMENT}
          tableItems={data_payments ? data_payments : []}
          onClickCellAction={(e, id) => {
            setSelectedId(id);
            setShowTransfer(true);
          }}
          showAcceptButton
          onAcceptCallback={handlerAccept}
          onOrderCallback={handleOrder}
        />
      </TableContainerWrapper>

      {showTransfer && (
        <ClientDetailsContainer
          onCloseCallback={() => setShowTransfer(false)}
          userLeadId={selectedId}
        />
      )}

      <ModalLayout isShow={change_status === Status.SUCCEEDED && is_enough}>
        <HomeFormContainer isModal>
          <FormTitle>Client accepted</FormTitle>
          <ButtonSubmit title="Close" onClick={handlerCloseAccept} />
        </HomeFormContainer>
      </ModalLayout>

      <ModalLayout isShow={change_status_decline === Status.SUCCEEDED && is_enough}>
        <HomeFormContainer isModal>
          <FormTitle>Client declined</FormTitle>
          <ButtonSubmit title="Close" onClick={handlerCloseAccept} />
        </HomeFormContainer>
      </ModalLayout>

      <ModalLayout isShow={!is_enough}>
        <HomeFormContainer isModal>
          <FormTitle>Oops...</FormTitle>
          <FormSubTitle
            style={{
              fontSize: "16px",
              lineHeight: "28px",
              marginTop: "-24px",
              marginBottom: "36px",
            }}
          >
            Unfortunately, you do not have enough funds on your balance sheet.
            You can contact our support team to resolve this issue.
            <InputForgotLink href="mailto:support@mig.finance">
              {" "}
              support@mig.finance
            </InputForgotLink>
          </FormSubTitle>

          <div style={{ maxWidth: "170px", width: "100%" }}>
            <ButtonSubmit title="Back" onClick={handlerEnough} />
          </div>
        </HomeFormContainer>
      </ModalLayout>
    </DashboardWrapper>
  );
};

export default DashboardPayments;
