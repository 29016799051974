import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormTitle, HomeFormContainer, ModalLayout } from "../../styles";
import ButtonSubmit from "../../components/ui/ButtonSubmit";
import { InputLabel } from "../../components/ui/Input/style";
import { RootState } from "../../store";
import {
  getManagerAssignAgents,
  managerAssignAgent,
} from "../../slices/userSlice";
import SelectSimple from "../../components/ui/SelectSimple";

interface Props {
  isVisible: boolean;
  onCloseCallback: () => void;
  userLeadId: number;
  type: string;
}

const LeadAssignAgent: React.FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const { manager_agents_assign } = useSelector(
    (state: RootState) => state.user
  );
  const [userId, setUserId] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        props.onCloseCallback();
      }
    },
    [ref, props]
  );

  useEffect(() => {
    dispatch(getManagerAssignAgents());
  }, [dispatch]);

  useEffect(() => {

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside]);

  const handlerChangeClient = (value: number | string) => {
    let newValue: number | string;

    if (typeof value === "string") {
      newValue = parseInt(value);
    } else {
      newValue = value;
    }

    setUserId(newValue);
  };

  const handleSubmit = async () => {
    await dispatch(
      managerAssignAgent({ agent_id: userId, user_lead_id: props.userLeadId, type: props.type })
    );

    props.onCloseCallback();
  };



  return (
    <ModalLayout isShow={props.isVisible}>
      <HomeFormContainer style={{ marginTop: "0" }} ref={ref}>
        <FormTitle style={{ marginBottom: "24px" }}>Assign to agent</FormTitle>

        <InputLabel>Select agent</InputLabel>

        <div style={{ marginBottom: "24px", width: "100%" }}>
          <SelectSimple
            items={manager_agents_assign}
            onCallbackChange={handlerChangeClient}
            customPlaceholder="No agent"
          />
        </div>

        <ButtonSubmit title="Change" onClick={handleSubmit} />
      </HomeFormContainer>
    </ModalLayout>
  );
};

export default LeadAssignAgent;
