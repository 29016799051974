import React from 'react';
import { BtnSubmitElement, BtnSubmitLoadingImg } from "./style";

interface Props {
  title: string;
  onClick: () => void;
  isDisable?: boolean;
  isLoading?: boolean;
}

const ButtonSubmit: React.FunctionComponent<Props> = (props) => {
  if(props.isLoading) {
    return (
        <BtnSubmitElement disabled>
          <BtnSubmitLoadingImg />
        </BtnSubmitElement>
    )
  }
  return (
      <BtnSubmitElement onClick={props.onClick} disabled={props.isDisable}>
        {props.title}
      </BtnSubmitElement>
  );
};

export default ButtonSubmit;
