import React, { useState } from "react";
import { FormTitle } from "../../../../styles";
import {
  SliderSubText, SliderSubTitle,
  SliderText,
  SliderValueContainer,
  SliderValueText,
  SliderWrapper,
  StyledContainer,
  StyledSlider,
  StyledThumb,
  StyledTrack,
} from "./styles";

const Thumb = (props: any) => <StyledThumb {...props} />;
const Track = (props: any, state: any) => (
  <StyledTrack {...props} index={state.index} />
);

interface Props {
  onBoardingValue: number;
  transferFeeValue: number;
  monthFeeValue: number;
  onChangeOnBoarding: (value: number) => void;
  onChangeTransfer:(value: number) => void;
  onChangeMonth: (value: number) => void;
}

const UserFormFee: React.FunctionComponent<Props> = (props) => {
  const [onboardingFee, setOnboardingFee] = useState<any>(props.onBoardingValue);
  const [transferFee, setTransferFee] = useState<any>(props.transferFeeValue);
  const [monthFee, setMonthFee] = useState<any>(props.monthFeeValue);

  return (
    <div style={{ width: "100%" }}>
      <FormTitle style={{ textAlign: "left", marginBottom: "0" }}>Fees expectations</FormTitle>
      <SliderSubTitle>
        (maximum willing to pay)
      </SliderSubTitle>

      <StyledContainer style={{ marginBottom: "20px" }}>
        <SliderWrapper style={{ marginBottom: "8px" }}>
          <SliderText>Onboarding fee, €</SliderText>
          <SliderValueContainer>
            <SliderValueText>{onboardingFee} €</SliderValueText>
          </SliderValueContainer>
        </SliderWrapper>

        <StyledSlider
          defaultValue={onboardingFee / 100}
          step={5}
          renderThumb={Thumb}
          renderTrack={Track}
          min={0}
          max={40}
          onChange={(value) => {
            // @ts-ignore
            const newValue = parseInt(value)  * 100;
            setOnboardingFee(newValue);
            props.onChangeOnBoarding(newValue);
          }}
        />
        <SliderWrapper>
          <SliderSubText>
            0
          </SliderSubText>
          <SliderSubText>
            4000
          </SliderSubText>

        </SliderWrapper>
      </StyledContainer>

      <StyledContainer style={{ marginBottom: "20px" }}>
        <SliderWrapper style={{ marginBottom: "8px" }}>
          <SliderText>Transaction fee, %</SliderText>
          <SliderValueContainer>
            <SliderValueText>{transferFee} %</SliderValueText>
          </SliderValueContainer>
        </SliderWrapper>

        <StyledSlider
            defaultValue={transferFee * 10}
            renderThumb={Thumb}
            renderTrack={Track}
            min={0}
            max={30}
            onChange={(value) => {
              // @ts-ignore
              const newValue = parseInt(value)  / 10;

              setTransferFee(newValue);
              props.onChangeTransfer(newValue);
            }}
        />
        <SliderWrapper>
          <SliderSubText>
            Fixed
          </SliderSubText>
          <SliderSubText>
            3 %
          </SliderSubText>

        </SliderWrapper>
      </StyledContainer>

      <StyledContainer style={{ marginBottom: "36px" }}>
        <SliderWrapper style={{ marginBottom: "8px" }}>
          <SliderText>Monthly maintenance, €</SliderText>
          <SliderValueContainer>
            <SliderValueText>{monthFee} €</SliderValueText>
          </SliderValueContainer>
        </SliderWrapper>

        <StyledSlider
            defaultValue={monthFee / 10}
            renderThumb={Thumb}
            renderTrack={Track}
            min={0}
            max={30}
            step={5}
            onChange={(value) => {
              // @ts-ignore
              const newValue = parseInt(value)  * 10;

              setMonthFee(newValue);
              props.onChangeMonth(newValue);
            }}
        />
        <SliderWrapper>
          <SliderSubText>
            0
          </SliderSubText>
          <SliderSubText>
            300
          </SliderSubText>

        </SliderWrapper>
      </StyledContainer>


    </div>
  );
};

export default UserFormFee;
