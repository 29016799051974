import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminGetAgents,
  adminSetUserPercent,
  userChangeBalance,
  userChangeStatus,
} from "../../../../../slices/userSlice";
import { RootState } from "../../../../../store";
import DashboardNav from "../../Navigation";
import {
  DashboardTableWrapper,
  FormSubTitle,
  FormTitle,
  HomeFormContainer,
  ModalLayout,
} from "../../../../../styles";
import Table from "../../../../ui/Table";
import {
  TableActions,
  TableActionsItem,
  TableContainerWrapper,
} from "../../../../ui/Table/style";
import useComponentVisible from "../../../../../constants/hooks";
import Switcher from "../../../../ui/Switch";
import Input from "../../../../ui/Input";
import ButtonSubmit from "../../../../ui/ButtonSubmit";
import { InputLabel } from "../../../../ui/Input/style";
import SelectSimple from "../../../../ui/SelectSimple";
import LeadAssignManager from "../../../../../containers/LeadAssingManager";
import { deleteManagerAgent } from "../../../../../slices/leadtSlice";
import {
  PopupTransferBtnContainer,
  PopupTransferBtnWrap,
} from "../../../../ui/PopupTransfer/style";
import ButtonDecline from "../../../../ui/ButtonDecline";
import { TABLE_TITLES_ADMIN_AGENTS } from "../../../../../constants/tableTitles";
import Filter from "../../../../ui/Filter";
import Modal from "../../../../ui/Modal";
import UserEditContainer from "../../../../../containers/UserEdit";

const SWITCH_ITEMS = [
  {
    id: 1,
    name: "Top Up",
  },
  {
    id: 2,
    name: "Charge",
  },
];

const AGENT_STATUS = [
  {
    id: "active",
    name: "Active",
  },
  {
    id: "disable",
    name: "Disable",
  },
];

const FILTER_ITEMS = [
  {
    name: "All",
    slug: "all",
  },
  {
    name: "Agents",
    slug: "agents",
  },
  {
    name: "Token holders",
    slug: "token",
  },
];

const DashboardAdminAgents = () => {
  const dispatch = useDispatch();

  const [showTransfer, setShowTransfer] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showCommission, setShowCommission] = useState(false);
  const [showManagerAssign, setShowManagerAssign] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [typeBalance, setTypeBalance] = useState(1);
  const [actionsObj, setActionsObj] = useState({ x: 0, y: 0, id: 0 });
  const [currentStatus, setCurrentStatus] = useState("reg");
  const [amount, setAmount] = useState("0");
  const [percent, setPercent] = useState(10);
  const [currentSlug, setCurrentSlug] = useState("all");

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const refTransfer = useRef<HTMLDivElement>(null);
  const refCommission = useRef<HTMLDivElement>(null);
  const refStatus = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        refStatus.current &&
        !refStatus.current.contains(event.target as Node)
      ) {
        setShowStatus(() => false);
      }

      if (
        refTransfer.current &&
        !refTransfer.current.contains(event.target as Node)
      ) {
        setShowTransfer(() => false);
      }

      if (
        refCommission.current &&
        !refCommission.current.contains(event.target as Node)
      ) {
        setShowCommission(() => false);
      }

    },
    []
  );

  useEffect(() => {
    dispatch(adminGetAgents());

    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { keyCode } = event;

      if (keyCode === 27) {
        setIsComponentVisible(() => false);
        setShowTransfer(() => false);
        setShowStatus(() => false);
        setShowCommission(() => false);
        setShowManagerAssign(() => false);
        setShowDelete(() => false);
      }
    };

    document.addEventListener("keydown", handleUserKeyPress);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [dispatch, handleClickOutside, setIsComponentVisible]);

  const { admin_data_agents } = useSelector((state: RootState) => state.user);

  const handlerSubmit = async () => {
    await dispatch(
      userChangeBalance({
        user_id: actionsObj.id,
        balance: amount,
        type: typeBalance,
      })
    );
    await dispatch(adminGetAgents());

    setShowTransfer(false);
  };

  const handleChange = async () => {
    await dispatch(
      userChangeStatus({ status: currentStatus, user_id: actionsObj.id })
    );
    await dispatch(adminGetAgents());

    setShowStatus(false);
  };

  const handleChangeStatus = () => {
    setShowStatus(true);
  };

  const amountChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    setAmount(value);
  };

  const handleCurrentStatus = (value: number | string) => {
    setCurrentStatus(value.toString());
  };

  const handlePercentChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = parseInt(e.currentTarget.value);

    setPercent(value);
  };

  const handlePercent = async () => {
    await dispatch(adminSetUserPercent({ percent, id: actionsObj.id }));
    await dispatch(adminGetAgents({filter: currentSlug}));

    setShowCommission(false);
  };

  const handleDelete = async () => {
    await dispatch(deleteManagerAgent({ user_id: actionsObj.id }));
    await dispatch(adminGetAgents({filter: currentSlug}));

    setShowDelete(false);
  };

  const handleOrder = async (order: string, field: string) => {
    await dispatch(
        adminGetAgents({filter: currentSlug, order: { type: order, field } })
    );
  };

  const handleFilter = async (slug: string) => {
    await dispatch(adminGetAgents({filter: slug}));
    setCurrentSlug(slug);
  };

  const handleSaveDetails = async () => {
    await dispatch(adminGetAgents({filter: currentSlug}));
  }

  const findItem = admin_data_agents.filter(
    (item: any) => item.id === actionsObj.id
  )[0];

  return (
    <div style={{ display: "flex" }}>
      <DashboardNav />
      <DashboardTableWrapper>
        <Filter items={FILTER_ITEMS} onSelectCallback={handleFilter} />

        <TableContainerWrapper>
          <Table
            headTitles={TABLE_TITLES_ADMIN_AGENTS}
            tableItems={admin_data_agents}
            onClickRow={() => {}}
            onClickCellAction={(e, id) => {
              const { left, top } = e.currentTarget.getBoundingClientRect();
              setIsComponentVisible(true);

              setActionsObj({ x: left, y: top, id });
            }}
            onOrderCallback={handleOrder}
            isAdmin
          />
        </TableContainerWrapper>
      </DashboardTableWrapper>

      <ModalLayout isShow={showTransfer}>
        <HomeFormContainer ref={refTransfer} isModal>
          <FormTitle>{findItem ? findItem.name : ""}</FormTitle>
          <FormSubTitle>
            Available: {findItem ? findItem.balance : ""} €
          </FormSubTitle>
          <Switcher
            onCallbackChange={(item) => setTypeBalance(item)}
            items={SWITCH_ITEMS}
          />
          <div style={{ marginBottom: "24px" }} />
          <Input
            labelText="Amount, €"
            placeholder=""
            type="text"
            onChange={amountChange}
          />
          <div style={{ marginBottom: "36px" }} />
          <ButtonSubmit title="Submit" onClick={handlerSubmit} />
        </HomeFormContainer>
      </ModalLayout>

      <ModalLayout isShow={showCommission}>
        <HomeFormContainer ref={refCommission} isModal>
          <FormTitle>{findItem ? findItem.name : ""}</FormTitle>
          <FormSubTitle style={{ marginBottom: "24px" }}>
            Current commission: {findItem ? findItem.percent : ""}
            {"%"}
          </FormSubTitle>
          <Input
            labelText="Appoint agent commission, %"
            placeholder=""
            type="text"
            onChange={handlePercentChange}
          />
          <div style={{ marginBottom: "36px" }} />
          <ButtonSubmit title="Submit" onClick={handlePercent} />
        </HomeFormContainer>
      </ModalLayout>

      {showManagerAssign && (
        <LeadAssignManager
          isVisible={showManagerAssign}
          userLeadId={actionsObj.id}
          type="user"
          onCloseCallback={() => {
            dispatch(adminGetAgents());
            setShowManagerAssign(false);
          }}
        />
      )}

      <ModalLayout isShow={showStatus}>
        <HomeFormContainer ref={refStatus} isModal>
          <FormTitle>Change agents status</FormTitle>
          <InputLabel>Change to</InputLabel>
          <div style={{ marginBottom: "36px", width: "100%" }}>
            <SelectSimple
              items={AGENT_STATUS}
              onCallbackChange={handleCurrentStatus}
            />
          </div>
          <ButtonSubmit title="Change" onClick={handleChange} />
        </HomeFormContainer>
      </ModalLayout>

      <Modal isVisible={showDelete} onCloseCallback={() => setShowDelete(false)}>
        <HomeFormContainer>
          <FormTitle style={{ marginBottom: 0 }}>Delete {findItem?.name}?</FormTitle>
          <FormSubTitle
            style={{
              fontSize: "16px",
              lineHeight: "28px",
              fontWeight: 500,
              marginTop: "12px",
              marginBottom: 0,
            }}
          >
            Are you sure? This action cannot be changed.
          </FormSubTitle>
          <PopupTransferBtnContainer style={{ marginTop: "36px" }}>
            <PopupTransferBtnWrap>
              <ButtonDecline
                title="Cancel"
                onClick={() => setShowDelete(false)}
              />
            </PopupTransferBtnWrap>

            <PopupTransferBtnWrap>
              <ButtonSubmit title="Delete" onClick={handleDelete} />
            </PopupTransferBtnWrap>
          </PopupTransferBtnContainer>
        </HomeFormContainer>
      </Modal>

      <Modal isVisible={showDetails} onCloseCallback={() => setShowDetails(false)}>
        <UserEditContainer onSaveCallback={handleSaveDetails} userId={actionsObj.id} />
      </Modal>

      <TableActions
        ref={ref}
        isVisible={isComponentVisible}
        x={actionsObj.x}
        y={actionsObj.y}
      >
        <TableActionsItem onClick={() => setShowDetails(true)}>
          Details
        </TableActionsItem>
        <TableActionsItem onClick={() => setShowTransfer(true)}>
          Balance
        </TableActionsItem>
        <TableActionsItem onClick={() => setShowCommission(true)}>
          Agent's commission, %
        </TableActionsItem>
        <TableActionsItem onClick={() => setShowManagerAssign(true)}>
          Assign to manager
        </TableActionsItem>
        <TableActionsItem onClick={handleChangeStatus}>
          Change agent status
        </TableActionsItem>
        <TableActionsItem onClick={() => setShowDelete(true)}>
          Delete
        </TableActionsItem>
      </TableActions>
    </div>
  );
};

export default DashboardAdminAgents;
