import React from 'react';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { DashboardContent, DashboardLayout } from "../../../../styles";
import DashboardHeader from "../../../../components/layout/Dashboard/Header";
import DashboardAdminManagerAgents from "../../../../components/layout/Dashboard/Admin/Managers/ManagerAgents";

const DashboardAdminManagerAgentsPage = () => {
  let { userId } = useParams<{ userId: string | undefined}>();

  const {data: { name}} = useSelector((state: RootState) => state.user);
  return (
      <DashboardLayout>
        <DashboardContent>
          <DashboardHeader refLink={null} name={name}/>
          <DashboardAdminManagerAgents userId={parseInt(userId || "0")} />
        </DashboardContent>
      </DashboardLayout>
  );
};

export default DashboardAdminManagerAgentsPage;
