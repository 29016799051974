import React from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { DashboardContent, DashboardLayout } from "../../../../styles";
import DashboardHeader from "../../../../components/layout/Dashboard/Header";
import DashboardAdminAgents from "../../../../components/layout/Dashboard/Admin/Agents";

const DashboardAdminAgentsPage = () => {
  const {data: { name}} = useSelector((state: RootState) => state.user);
  return (
      <DashboardLayout>
        <DashboardContent>
          <DashboardHeader refLink={null} name={name}/>
          <DashboardAdminAgents />
        </DashboardContent>
      </DashboardLayout>
  );
};

export default DashboardAdminAgentsPage;
