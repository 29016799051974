import React from 'react';
import styled from "styled-components";
import { BtnSubmitLoadingImg } from "../ButtonSubmit/style";

const Container = styled.div`
  background: rgba(255, 255, 255, 0.89);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;  
`

const LoadingScreen = () => {
  return (
      <Container>
        <BtnSubmitLoadingImg />
      </Container>
  );
};

export default LoadingScreen;
