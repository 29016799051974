import React, { useState } from "react";
import {
  DashboardHeaderAuthMenu,
  DashboardHeaderAuthMenuText,
  DashboardHeaderBalance,
  DashboardHeaderContainer,
  DashboardHeaderMenu,
  DashboardHeaderMenuAvatar,
  DashboardHeaderMenuOpen,
  DashboardHeaderMenuText,
  DashboardHeaderMoney,
  DashboardHeaderText,
  DashboardTg,
} from "./style";
import { Link, useHistory } from "react-router-dom";
import {
  DASHBOARD,
  DASHBOARD_PAYMENTS_HISTORY,
  LOGIN,
} from "../../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../slices/userSlice";
import useComponentVisible from "../../../../constants/hooks";
import ReferralLink from "../../../ui/ReferalLink";
import LeadSettingsContainer from "../../../../containers/LeadSettings";
import { RootState } from "../../../../store";
import leadSettingsIcon from "../../../../assets/equalizer.svg";
import logoImg from "../../../../assets/logo-dash.svg";
import avatarBlankImg from "../../../../assets/avatar-blank.svg";
import logoutIcon from "../../../../assets/logout-icon.svg";
import faqIcon from "../../../../assets/question-circle.svg";
import paymentHistoryIcon from "../../../../assets/payment-history.svg";
import tgCommunity from "../../../../assets/telegram-community.svg";
import arrowMenuR from "../../../../assets/arrow-menu-r.svg";
import Modal from "../../../ui/Modal";
import MadePaymentContainer from "../../../../containers/MadePayment";

interface Props {
  showRef?: boolean;
  showBalance?: boolean;
  balance?: number;
  refLink: null | string;
  name?: string;
  showCommunity?: boolean;
}

const DashboardHeader: React.FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [leadSettings, setLeadSettings] = useState(false);
  const [showMadePayment, setShowMadePayment] = useState(false);

  const {
    data: { type, is_admin },
  } = useSelector((state: RootState) => state.user);

  const handlerLogout = async () => {
    await dispatch(logout());
    history.push(LOGIN);
  };

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  return (
    <DashboardHeaderContainer>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link to={DASHBOARD}>
          <img src={logoImg} alt="Mig finance" />
        </Link>
        {props.showRef && <ReferralLink link={props.refLink} />}
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        {props.showCommunity && (
          <DashboardTg
            as="a"
            target="_blank"
            href="https://t.me/migfinancecommunity"
          >
            <img src={tgCommunity} alt="Community" />
            <DashboardHeaderAuthMenu
              className="community-hover"
              style={{
                borderRadius: "6px",
                padding: "6px 12px",
                minWidth: "auto",
                top: "40px",
                right: "-100%",
              }}
            >
              <DashboardHeaderAuthMenuText
                style={{ color: "#929DC2", padding: "0", height: "auto" }}
              >
                Community
              </DashboardHeaderAuthMenuText>
            </DashboardHeaderAuthMenu>
          </DashboardTg>
        )}
        {props.showBalance && (
          <DashboardHeaderBalance>
            <DashboardHeaderText>Balance:</DashboardHeaderText>
            <DashboardHeaderMoney>{props.balance} €</DashboardHeaderMoney>
          </DashboardHeaderBalance>
        )}
        <DashboardHeaderMenu
          onClick={() => setIsComponentVisible(!isComponentVisible)}
        >
          <DashboardHeaderMenuAvatar>
            <img src={avatarBlankImg} alt="Avatar" />
          </DashboardHeaderMenuAvatar>
          <DashboardHeaderMenuText>{props.name}</DashboardHeaderMenuText>
          <DashboardHeaderMenuOpen isOpen={isComponentVisible} />
          <DashboardHeaderAuthMenu isVisible={isComponentVisible} ref={ref}>
            {type === 2 && !is_admin && (
              <>
                <DashboardHeaderAuthMenuText
                  href="https://www.notion.so/Welcome-to-mig-finance-help-center-d5569c9349464ccda94109aac4d0523a"
                  target="_blank"
                >
                  <img src={faqIcon} alt="Logout" />
                  FAQ
                </DashboardHeaderAuthMenuText>
                <DashboardHeaderAuthMenuText
                  onClick={() => setLeadSettings(true)}
                >
                  <img src={leadSettingsIcon} alt="Logout" />
                  Lead settings
                </DashboardHeaderAuthMenuText>
                <DashboardHeaderAuthMenuText
                  onClick={() => history.push(DASHBOARD_PAYMENTS_HISTORY)}
                >
                  <img src={paymentHistoryIcon} alt="Logout" />
                  Payments history
                </DashboardHeaderAuthMenuText>
                <DashboardHeaderAuthMenuText onClick={() => setShowMadePayment(true)}>
                  <img src={arrowMenuR} alt="Made a payment" />
                  Make a payment
                </DashboardHeaderAuthMenuText>
              </>
            )}
            {type === 1 && !is_admin && (
              <>
                <DashboardHeaderAuthMenuText
                  href="https://www.notion.so/Welcome-to-mig-finance-help-center-d5569c9349464ccda94109aac4d0523a"
                  target="_blank"
                >
                  <img src={faqIcon} alt="Logout" />
                  FAQ
                </DashboardHeaderAuthMenuText>
              </>
            )}
            <DashboardHeaderAuthMenuText onClick={handlerLogout}>
              <img src={logoutIcon} alt="Logout" />
              Sign out
            </DashboardHeaderAuthMenuText>
          </DashboardHeaderAuthMenu>
        </DashboardHeaderMenu>
      </div>
      {leadSettings && (
        <LeadSettingsContainer
          isVisible={leadSettings}
          onCallbackSubmit={() => setLeadSettings(false)}
        />
      )}
      <Modal isVisible={showMadePayment} onCloseCallback={() => setShowMadePayment(false)}>
        <MadePaymentContainer onFinish={() => setShowMadePayment(false)} />
      </Modal>
    </DashboardHeaderContainer>
  );
};

export default DashboardHeader;
