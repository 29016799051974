import React, { useEffect, useState } from "react";
import { FormTitle } from "../../styles";
import Input from "../../components/ui/Input";
import Select from "../../components/ui/Select";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { getCountriesPublic } from "../../slices/countrySlice";
import { RegisterChooseHead } from "../../components/layout/Register/RegisterChoose/style";
import Checkbox from "../../components/ui/Checkbox";
import { UserFormMessengerText } from "../../components/layout/User/UserForm/styles";
import ButtonSubmit from "../../components/ui/ButtonSubmit";
import { userAddPaymentIfo } from "../../slices/userLeadSlice";

interface Props {
  email: string;
  onNextCallback: (callbackStep: number) => void;
}

const UserPaymentInfoContainer: React.FunctionComponent<Props> = ({ email, onNextCallback }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountriesPublic());
  }, [dispatch]);

  const { data_country } = useSelector((state: RootState) => state.countries);

  const [same, setSame] = useState(false);
  const [citizenship, setCitizenship] = useState(-1);
  const [residence, setResidence] = useState(-1);
  const [inPayment, setInPayment] = useState("");
  const [outPayment, setOutPayment] = useState("");
  const [monthPayment, setMonthPayment] = useState("");

  const handleInput = (
    type: string,
    e: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const value = e.currentTarget.value;

    if (type === "in") {
      setInPayment(value);
    }

    if (type === "out") {
      setOutPayment(value);
    }

    if (type === "month") {
      setMonthPayment(value);
    }
  };

  const handleSelectCitizen = (id: number | string) => {
    setCitizenship(+id);

    if (same) {
      setResidence(+id);
    }
  };

  const handleSelectResidence = (id: number | string) => {
    setResidence(+id);
  };

  const handleSame = () => {
    if(same) {
      setResidence(citizenship);
    }

    setSame(!same);
  }

  const handleNext = async () => {
    await dispatch(userAddPaymentIfo({
      email: email,
      citizenship,
      residence,
      in_payment: inPayment,
      out_payment: outPayment,
      month_payment: monthPayment
    }));

    onNextCallback(3);
  }

  return (
    <div style={{ width: "100%" }}>
      <FormTitle style={{ textAlign: "left" }}>Payments info</FormTitle>

      <div style={{ marginBottom: "20px" }}>
        <RegisterChooseHead>Citizenship</RegisterChooseHead>
        <Select
          items={data_country}
          initialSelected={citizenship}
          onCallbackChange={handleSelectCitizen}
        />
      </div>

      {!same && (
        <div style={{ marginBottom: "10px" }}>
          <RegisterChooseHead>Country of residence</RegisterChooseHead>
          <Select
            items={data_country}
            initialSelected={residence}
            onCallbackChange={handleSelectResidence}
          />
        </div>
      )}

      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
      >
        <Checkbox
          onClick={handleSame}
          value={same}
          defaultChecked={false}
        />
        <UserFormMessengerText>same as citizenship</UserFormMessengerText>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <Input
          labelText="Incoming payments (countries)"
          placeholder=""
          type="text"
          onChange={(e) => handleInput("in", e)}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <Input
          labelText="Outgoing payments (countries)"
          placeholder=""
          type="text"
          onChange={(e) => handleInput("out", e)}
        />
      </div>
      <div style={{ marginBottom: "36px" }}>
        <Input
          labelText="Annual Turnover, €"
          placeholder=""
          type="text"
          onChange={(e) => handleInput("month", e)}
        />
      </div>

      <div style={{ width: "100%" }}>
        <ButtonSubmit title="Next" onClick={handleNext} />
      </div>
    </div>
  );
};

export default UserPaymentInfoContainer;
