import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormTitle, HomeFormContainer, ModalLayout } from "../../styles";
import MultiSelect from "../../components/ui/MultiSelect";
import { RegisterChooseHead } from "../../components/layout/Register/RegisterChoose/style";
import Switcher from "../../components/ui/Switch";
import ButtonSubmit from "../../components/ui/ButtonSubmit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { getCountries } from "../../slices/countrySlice";
import { getUserParam } from "../../slices/userSlice";
import { userSetParam } from "../../slices/registerSlice";

const SWITCH_ITEMS = [
  {
    id: 1,
    name: "Individuals",
  },
  {
    id: 2,
    name: "Businesses",
  }
];

interface Props {
  isVisible: boolean;
  onCallbackSubmit: () => void;
}

const LeadSettingsContainer: React.FunctionComponent<Props> = ({isVisible, onCallbackSubmit}) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const { data } = useSelector((state: RootState) => state.countries);
  const { user_param } = useSelector((state: RootState) => state.user);

  const handleClickOutside = useCallback(
      (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          onCallbackSubmit();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [ref, dispatch]
  );

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getUserParam());

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [dispatch, handleClickOutside]);


  const [choose, setChoose] = useState({
    ids: new Array(0),
    type: 1,
  });


  const handleChangeCountry = (ids: Array<number>) => {
    setChoose(() => ({
      ...choose,
      ids
    }));
  }

  const handleChangeLeads = (id: number) => {
    setChoose(() => ({
      ...choose,
      type: id
    }));
  }

  const handleSubmit = async () => {
    await dispatch(userSetParam({type: choose.type, ids: choose.ids}));
    onCallbackSubmit();
  }

  return (
      <ModalLayout isShow={isVisible}>
        <HomeFormContainer style={{ marginTop: 0}} ref={ref}>

          <FormTitle style={{ textAlign: "left", maxWidth: "242px" }}>
            Choose parameters of your lead
          </FormTitle>

          <div style={{ width: "100%", marginBottom: "24px" }}>
            <RegisterChooseHead>
              Countries
            </RegisterChooseHead>

            <MultiSelect
                items={data}
                onCallbackChange={handleChangeCountry}
                initialSelected={user_param.ids}
            />

          </div>
          <div style={{ marginBottom: "36px", width: "100%" }}>
            <RegisterChooseHead>
              Type of leads
            </RegisterChooseHead>
            <Switcher
                onCallbackChange={handleChangeLeads}
                items={SWITCH_ITEMS}
                currentSelected={user_param.type}
            />
          </div>
          <ButtonSubmit
              title={"Submit"}
              onClick={handleSubmit}
          />
        </HomeFormContainer>
      </ModalLayout>
  );
};

export default LeadSettingsContainer;
