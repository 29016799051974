import React from "react";
import {
  TableCellContainer,
  TableCellStatus,
  TableCellStatusApproveDot,
  TableCellStatusDeclineDot,
  TableCellStatusPendingDot,
  TableCellStatusRegDot,
  TableCellStatusRegFinishDot,
  TableCellStatusVerifyDot,
} from "./style";

interface Props {
  type?: string;
  typeStatus?: string;
  onClick?: () => void;
  isLast?: boolean;
  className?: string;
}

const TableCell: React.FunctionComponent<Props> = (props) => {
  return (
    <TableCellContainer onClick={() => {}} className={props.className}>
      {props.type === "status" && (
        <TableCellStatus typeStatus={props.typeStatus}>
          {props.typeStatus === "reg" && <TableCellStatusRegDot />}
          {props.typeStatus === "reg_finish" && <TableCellStatusRegFinishDot />}
          {props.typeStatus === "verified" && <TableCellStatusVerifyDot />}
          {props.typeStatus === "wait" && <TableCellStatusPendingDot />}
          {props.typeStatus === "approve" && <TableCellStatusApproveDot />}
          {props.typeStatus === "decline" && <TableCellStatusDeclineDot />}
          {props.typeStatus === "active" && <TableCellStatusApproveDot />}
          {props.typeStatus === "disable" && <TableCellStatusDeclineDot />}
          {props.children}
        </TableCellStatus>
      )}

      {props.type !== "status" && props.type !== "img" && props.children}
    </TableCellContainer>
  );
};

export default TableCell;
