import React from 'react';
import { FormTitle, HomeFormContainer } from "../../../../styles";
import { RegisterConfirmText, RegisterConfirmEmail } from "./style";
import ButtonSubmit from "../../../ui/ButtonSubmit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { resetPassword } from "../../../../slices/forgotSlice";
import { Redirect } from "react-router-dom";
import { LOGIN } from "../../../../constants/routes";
import { Status } from "../../../../types";

const RegisterConfirm = () => {
  const dispatch = useDispatch();

  const { email, status } = useSelector((state: RootState) => state.forgot);

  const handlerResend = () => {
    dispatch(resetPassword(email));
  }

  if (!email) {
    return <Redirect to={LOGIN}/>
  }

  return (
      <HomeFormContainer>
        <FormTitle style={{ textAlign: "left" }}>
          Please check your mail to verify registration
        </FormTitle>
        <RegisterConfirmText>
          You’re almost there! We sent an email to <RegisterConfirmEmail href="mailto:example@mail.com">
          {email}
        </RegisterConfirmEmail>
        </RegisterConfirmText>
        <RegisterConfirmText>
          Just click on the link in that email to complete your signup.
          If you don’t see it, you may need to <strong>check your spam</strong> folder.
        </RegisterConfirmText>
        <RegisterConfirmText>
          Still can’t find the email?
        </RegisterConfirmText>
        <ButtonSubmit
            isDisable={status === Status.IDLE}
            title="Resend Email"
            onClick={handlerResend}
        />
      </HomeFormContainer>
  );
};

export default RegisterConfirm;
