import React, { useEffect, useState } from 'react';
import DashboardNav from "../../Navigation";
import Breadcrumb from "../../../../ui/Breadcrumb";
import { DASHBOARD_ADMIN_MANAGERS } from "../../../../../constants/routes";
import { DashboardTableWrapper, DashboardTitle } from "../../../../../styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { getLeadsByUser } from "../../../../../slices/leadtSlice";
import { TableActions, TableContainerWrapper } from "../../../../ui/Table/style";
import Table from "../../../../ui/Table";
import { FILTER_ITEM_LEADS, TABLE_TITLES_ADMIN_MANAGER_LEADS } from "../../../../../constants/tableTitles";
import useComponentVisible from "../../../../../constants/hooks";
import Filter from "../../../../ui/Filter";

interface Props {
  userId: number;
}

const DashboardAdminManagerLeads: React.FunctionComponent<Props> = ({ userId }) => {
  const dispatch = useDispatch();
  const { by_user } = useSelector((state: RootState) => state.leads);

  const [actionsObj, setActionsObj] = useState({ x: 0, y: 0, id: 0 });
  const [currentSlug, setCurrentSlug] = useState("all");

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  useEffect(() => {
    dispatch(getLeadsByUser({ user_lead_id: userId, filter: currentSlug }));
  }, [dispatch, userId, currentSlug]);

  const handleFilter = async (slug: string) => {
    setCurrentSlug(slug);
  }

  return (
      <div style={{ display: "flex" }}>
        <DashboardNav />
        <DashboardTableWrapper>
          <Breadcrumb
              rootPath={DASHBOARD_ADMIN_MANAGERS}
              rootName="Managers"
              currentPage={by_user.name}
          />

          <DashboardTitle style={{ marginBottom: "14px" }}>{by_user.name}</DashboardTitle>

          <Filter items={FILTER_ITEM_LEADS} onSelectCallback={handleFilter} />
          <TableContainerWrapper>
            <Table
                isAdmin
                headTitles={TABLE_TITLES_ADMIN_MANAGER_LEADS}
                tableItems={by_user.user_leads}
                onClickRow={() => {}}
                onClickCellAction={(e, id) => {
                  const { left, top } = e.currentTarget.getBoundingClientRect();
                  setIsComponentVisible(true);

                  setActionsObj({ x: left, y: top, id });
                }}
            />
          </TableContainerWrapper>
        </DashboardTableWrapper>

        <TableActions
            ref={ref}
            isVisible={isComponentVisible}
            x={actionsObj.x}
            y={actionsObj.y}
        >
        </TableActions>
      </div>
  );
};

export default DashboardAdminManagerLeads;
