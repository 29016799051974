import React from "react";
import { useLocation } from "react-router-dom";
import {
  AgentIcon,
  AllLeadsIcon,
  DashboardNavContainer,
  DashboardNavItem,
  DashboardNavLink,
  DashboardNavList,
} from "../style";
import {
  DASHBOARD_MANAGER,
  DASHBOARD_MANAGER_AGENTS,
} from "../../../../../constants/routes";

const NavManager = () => {
  const { pathname } = useLocation();

  return (
    <DashboardNavContainer>
      <DashboardNavList>
        <DashboardNavItem isHere={DASHBOARD_MANAGER === pathname}>
          <DashboardNavLink
            to={DASHBOARD_MANAGER}
            ishere={DASHBOARD_MANAGER === pathname}
          >
            <AllLeadsIcon />
            My leads
          </DashboardNavLink>
        </DashboardNavItem>

        <DashboardNavItem isHere={DASHBOARD_MANAGER_AGENTS === pathname}>
          <DashboardNavLink
            to={DASHBOARD_MANAGER_AGENTS}
            ishere={DASHBOARD_MANAGER_AGENTS === pathname}
          >
            <AgentIcon />
            My agents
          </DashboardNavLink>
        </DashboardNavItem>
      </DashboardNavList>
    </DashboardNavContainer>
  );
};

export default NavManager;
