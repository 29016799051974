import styled from "styled-components";
import { poppinsFont } from "../../styles/fonts";

export const ClientInputWrap = styled.div` 
  width: 50%;
  margin-bottom: 12px;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(146, 157, 194, 0.2);  

  :hover {
    border-bottom: 1px solid rgba(37, 59, 134, 0.5);
  }
  
  :focus {
    border-bottom: 1px solid #253B86;
  }
`

export const ClientInput = styled.input`
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #171717;
  font-family: ${poppinsFont};  
  box-sizing: border-box;
  border: none;    
  outline: none;
  padding: 0;  
`
export const ClientEditButton = styled.button<{ isSave: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  top: 12px;
  right: 12px;
  min-width: 72px;
  padding: 4px 8px;
  height: 28px;
  border: 1px solid #253B86;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.isSave ? "#FFFFFF" : "#253B86"} ;
  font-family: ${poppinsFont};
  background-color: ${props => props.isSave ? "#253B86" : "#FFFFFF"} ;
  
  img {
    margin-right: 10px;
  }
`

export const ClientLink = styled.a`
  font-family: ${poppinsFont};
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #FE5728;
`
