import React from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { DashboardContent, DashboardLayout } from "../../../../styles";
import DashboardHeader from "../../../../components/layout/Dashboard/Header";
import DashboardPaymentsHistory from "../../../../components/layout/Dashboard/Payments/History";

const DashboardPaymentsHistoryPage = () => {
  const {
    data: { name, balance },
  } = useSelector((state: RootState) => state.user);

  return (
      <DashboardLayout>
        <DashboardContent>
          <DashboardHeader refLink={null} name={name} showBalance balance={balance} showCommunity />
          <DashboardPaymentsHistory />
        </DashboardContent>
      </DashboardLayout>
  );
};

export default DashboardPaymentsHistoryPage;
