import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  DashboardTableWrapper,
  FormSubTitle,
  FormTitle,
  HomeFormContainer,
  ModalLayout,
} from "../../../../styles";
import Table from "../../../ui/Table";
import DashboardNav from "../Navigation";
import {
  deleteLead,
  getLeads,
  leadChangeStatus,
} from "../../../../slices/leadtSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  TableActions,
  TableActionsItem,
  TableContainerWrapper,
} from "../../../ui/Table/style";
import useComponentVisible from "../../../../constants/hooks";
import { InputLabel } from "../../../ui/Input/style";
import ButtonSubmit from "../../../ui/ButtonSubmit";
import LeadAssignClient from "../../../../containers/LeadAssingClient";
import ClientDetailsContainer from "../../../../containers/ClientDetails";
import SelectSimple from "../../../ui/SelectSimple";
import Filter from "../../../ui/Filter";
import {
  PopupTransferBtnContainer,
  PopupTransferBtnWrap,
} from "../../../ui/PopupTransfer/style";
import ButtonDecline from "../../../ui/ButtonDecline";
import LeadAssignAgent from "../../../../containers/LeadAssingAgent";
import LeadAssignManager from "../../../../containers/LeadAssingManager";
import { TABLE_TITLES_ADMIN_LEADS } from "../../../../constants/tableTitles";
import { adminGetSumSubRequest } from "../../../../slices/userSlice";

const AGENT_STATUS = [
  {
    id: "reg",
    name: "Started registration",
  },
  {
    id: "reg_finish",
    name: "Identity verification",
  },
  {
    id: "verified",
    name: "Verified",
  },
  {
    id: "wait",
    name: "Pending",
  },
  {
    id: "approve",
    name: "Approved",
  },
  {
    id: "decline",
    name: "Decline",
  },
];

const FILTER_ITEMS = [
  {
    name: "All",
    slug: "all",
  },
  {
    name: "Individuals",
    slug: "individual",
  },
  {
    name: "Business entity",
    slug: "business",
  },
];

const DashboardAdmin = () => {
  const dispatch = useDispatch();

  const [actionsObj, setActionsObj] = useState({ x: 0, y: 0, id: 0 });
  const [showAssign, setShowAssign] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showAssingAgent, setShowAssingAgent] = useState(false);
  const [showManagerAssign, setShowManagerAssign] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("reg");
  const [currentSlug, setCurrentSlug] = useState("all");

  const refStatus = useRef<HTMLDivElement>(null);
  const refDelete = useRef<HTMLDivElement>(null);

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      refStatus.current &&
      !refStatus.current.contains(event.target as Node)
    ) {
      setShowStatus(() => false);
    }

    if (
        refDelete.current &&
        !refDelete.current.contains(event.target as Node)
    ) {
      setShowDelete(() => false);
    }
  }, []);

  useEffect(() => {
    dispatch(getLeads({ filter: currentSlug }));

    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { keyCode } = event;

      if (keyCode === 27) {
        setShowAssign(() => false);
        setIsComponentVisible(() => false);
        setShowDetails(() => false);
        setShowStatus(() => false);
        setShowDelete(() => false);
        setShowAssingAgent(() => false);
        setShowManagerAssign(() => false);
      }
    };

    document.addEventListener("keydown", handleUserKeyPress);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("keydown", handleUserKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, handleClickOutside, currentSlug]);

  const { data } = useSelector((state: RootState) => state.leads);

  const handleChangeStatus = () => {
    setShowStatus(true);
  };

  const handleChange = async () => {
    await dispatch(
      leadChangeStatus({ status: currentStatus, id: actionsObj.id })
    );

    await dispatch(getLeads({ filter: currentSlug }));

    setShowStatus(false);
  };

  const handleCurrentStatus = (value: number | string) => {
    setCurrentStatus(value.toString());
  };

  const handleFilter = async (slug: string) => {
    await dispatch(getLeads({ filter: slug }));
    setCurrentSlug(slug);
  };

  const handleOrder = async (order: string, field: string) => {
    await dispatch(
      getLeads({ filter: currentSlug, order: { type: order, field } })
    );
  };

  const handleDelete = async () => {
    await dispatch(deleteLead({ user_lead_id: actionsObj.id }));
    await dispatch(getLeads({ filter: currentSlug }));

    setShowDelete(false);
  };

  const handleRequest = async () => {
    await dispatch(adminGetSumSubRequest({user_id: actionsObj.id}));
    setIsComponentVisible(() => false);
  }

  const findItem = data?.filter(
      (item: any) => item.id === actionsObj.id
  )[0];

  return (
    <div style={{ display: "inline-flex" }}>
      <DashboardNav />

      <DashboardTableWrapper>
        <Filter items={FILTER_ITEMS} onSelectCallback={handleFilter} />
        <TableContainerWrapper>
          <Table
            isAdmin
            headTitles={TABLE_TITLES_ADMIN_LEADS}
            tableItems={data ? data : []}
            onClickRow={() => {}}
            onClickCellAction={(e, id) => {
              const { left, top } = e.currentTarget.getBoundingClientRect();
              setIsComponentVisible(true);

              setActionsObj({ x: left - 245, y: top, id });
            }}
            onOrderCallback={handleOrder}
          />
        </TableContainerWrapper>
      </DashboardTableWrapper>

      {showDetails && (
        <ClientDetailsContainer
          onCloseCallback={() => {
            setShowDetails(false);
          }}
          userLeadId={actionsObj.id}
          onSaveCallback={() => dispatch(getLeads({ filter: currentSlug }))}
        />
      )}

      <ModalLayout isShow={showStatus}>
        <HomeFormContainer ref={refStatus} style={{ marginTop: 0 }}>
          <FormTitle>Change lead status</FormTitle>
          <InputLabel>Change to</InputLabel>
          <div style={{ marginBottom: "36px", width: "100%" }}>
            <SelectSimple
              items={AGENT_STATUS}
              onCallbackChange={handleCurrentStatus}
            />
          </div>
          <ButtonSubmit title="Change" onClick={handleChange} />
        </HomeFormContainer>
      </ModalLayout>

      {showAssign && (
        <LeadAssignClient
          isVisible={showAssign}
          userLeadId={actionsObj.id}
          onCloseCallback={() => {
            dispatch(getLeads({ filter: "all" }));
            setShowAssign(false);
          }}
        />
      )}

      {showAssingAgent && (
        <LeadAssignAgent
          isVisible={showAssingAgent}
          userLeadId={actionsObj.id}
          onCloseCallback={() => {
            dispatch(getLeads({ filter: currentSlug }));
            setShowAssingAgent(false);
          }}
          type="admin_agent"
        />
      )}

      {showManagerAssign && (
        <LeadAssignManager
          type="lead"
          isVisible={showManagerAssign}
          userLeadId={actionsObj.id}
          onCloseCallback={() => {
            dispatch(getLeads({ filter: currentSlug }));
            setShowManagerAssign(false);
          }}
        />
      )}

      <ModalLayout isShow={showDelete}>
        <HomeFormContainer style={{ marginTop: 0 }} ref={refDelete}>
          <FormTitle style={{ marginBottom: 0 }}>Delete {findItem?.company}?</FormTitle>
          <FormSubTitle
            style={{
              fontSize: "16px",
              lineHeight: "28px",
              fontWeight: 500,
              marginTop: "12px",
              marginBottom: 0,
            }}
          >
            Are you sure? This action cannot be changed.
          </FormSubTitle>
          <PopupTransferBtnContainer style={{ marginTop: "36px" }}>
            <PopupTransferBtnWrap>
              <ButtonDecline
                title="Cancel"
                onClick={() => setShowDelete(false)}
              />
            </PopupTransferBtnWrap>

            <PopupTransferBtnWrap>
              <ButtonSubmit title="Delete" onClick={handleDelete} />
            </PopupTransferBtnWrap>
          </PopupTransferBtnContainer>
        </HomeFormContainer>
      </ModalLayout>

      <TableActions
        ref={ref}
        isVisible={isComponentVisible}
        x={actionsObj.x}
        y={actionsObj.y}
      >
        <TableActionsItem onClick={() => setShowDetails(true)}>
          Lead details
        </TableActionsItem>
        <TableActionsItem onClick={handleChangeStatus}>
          Change status
        </TableActionsItem>

        <TableActionsItem onClick={() => setShowAssign(true)}>
          Assign to payment system
        </TableActionsItem>

        <TableActionsItem onClick={() => setShowAssingAgent(true)}>
          Assign to agent
        </TableActionsItem>

        <TableActionsItem onClick={() => setShowManagerAssign(true)}>
          Assign to manager
        </TableActionsItem>

        <TableActionsItem onClick={handleRequest}>
          Request SumSub data
        </TableActionsItem>

        <TableActionsItem onClick={() => setShowDelete(true)}>
          Delete
        </TableActionsItem>
      </TableActions>
    </div>
  );
};

export default DashboardAdmin;
