import HeaderHome from "../../../components/layout/Header";
import { HomeLayout } from "../../../styles";
import RegisterChoose from "../../../components/layout/Register/RegisterChoose";
import Footer from "../../../components/layout/Footer";

const RegisterChoosePage = () => {
  return (
      <HomeLayout>
        <HeaderHome/>
        <RegisterChoose/>
        <Footer />
      </HomeLayout>
  );
};

export default RegisterChoosePage;
