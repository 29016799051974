import React from "react";
import Table from "../../../../../ui/Table";
import { TABLE_TITLES_PAYMENT_HISTORY } from "../../../../../../constants/tableTitles";
import { TableContainerWrapper } from "../../../../../ui/Table/style";

interface Props {
  data: Array<any>;
  onHandleOrder: (type: string, field: string) => void;
  onHandleDescriptionLead?: (id: any) => void;
}

const PaymentsHistoryTable: React.FunctionComponent<Props> = ({
  data,
  onHandleOrder,
  onHandleDescriptionLead,
}) => {
  const TITLES = React.useMemo(() => TABLE_TITLES_PAYMENT_HISTORY, []);
  const memoData = React.useMemo(() => data, [data]);

  return (
    <TableContainerWrapper style={{ marginBottom: "104px" }}>
      <Table
        onClickRow={() => {}}
        headTitles={TITLES}
        tableItems={memoData}
        onOrderCallback={onHandleOrder}
        onDescriptionLeadCallback={onHandleDescriptionLead}
      />
    </TableContainerWrapper>
  );
};

export default React.memo(PaymentsHistoryTable);
