import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormTitle, HomeFormContainer, ModalLayout } from "../../styles";
import Input from "../../components/ui/Input";
import ButtonSubmit from "../../components/ui/ButtonSubmit";
import { InputLabel } from "../../components/ui/Input/style";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  adminGetPaymentSystems,
  leadsAssignClient,
} from "../../slices/userSlice";
import SelectSimple from "../../components/ui/SelectSimple";

interface Props {
  isVisible: boolean;
  onCloseCallback: () => void;
  userLeadId: number;
}

const LeadAssignClient: React.FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const { admin_data_payments } = useSelector((state: RootState) => state.user);
  const [userId, setUserId] = useState(0);
  const [cost, setCost] = useState(0);
  const [error, setError] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        props.onCloseCallback();
      }
    },
    [ref, props]
  );

  useEffect(() => {
    dispatch(adminGetPaymentSystems());
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside]);

  const handlerChangeCost = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = parseInt(e.currentTarget.value);

    if (error) setError(false);

    setCost(value);
  };

  const handlerChangeClient = (value: number | string) => {
    let newValue: number | string;

    if (typeof value === "string") {
      newValue = parseInt(value);
    } else {
      newValue = value;
    }

    setUserId(newValue);
  };

  const handleSubmit = async () => {
    if (cost > 0) {
      await dispatch(
        leadsAssignClient({
          user_lead_id: props.userLeadId,
          user_id: userId,
          cost: cost,
        })
      );
      props.onCloseCallback();
    } else {
      if(!userId) {
        await dispatch(
            leadsAssignClient({
              user_lead_id: props.userLeadId,
              user_id: userId,
              cost: cost,
            })
        );
        props.onCloseCallback();
      } else {
        setError(true);
      }
    }
  };

  let newItems = [...admin_data_payments];

  if(newItems) {
    newItems.unshift({
      id: 0,
      name: "No payment system",
      email: "nopayment@mig.finance",
      status: "wait",
      balance: 0,
    });
  }

  return (
    <ModalLayout isShow={props.isVisible}>
      <HomeFormContainer style={{ marginTop: "0" }} ref={ref}>
        <FormTitle style={{ marginBottom: "24px" }}>
          Assign a payment system
        </FormTitle>

        <InputLabel>Payment system</InputLabel>
        <div style={{ marginBottom: "24px", width: "100%" }}>
          <SelectSimple
            items={newItems}
            onCallbackChange={handlerChangeClient}
          />
        </div>

        <Input
          labelText="Cost per Lead, €"
          placeholder="0"
          type="text"
          onChange={handlerChangeCost}
          isError={error}
          errorMessage="Enter the cost"
        />

        <div style={{ marginBottom: "36px" }} />

        <ButtonSubmit title="Submit" onClick={handleSubmit} />
      </HomeFormContainer>
    </ModalLayout>
  );
};

export default LeadAssignClient;
