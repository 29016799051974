import React, { useEffect, useState } from "react";
import { DashboardTitle, DashboardWrapper } from "../../../../../styles";
import PaymentsHistoryTable from "./Table";
import Filter from "../../../../ui/Filter";
import { FILTER_ITEM_PAYMENT_HISTORY } from "../../../../../constants/tableTitles";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentsHistory } from "../../../../../slices/paymentsSlice";
import { RootState } from "../../../../../store";
import ClientDetailsContainer from "../../../../../containers/ClientDetails";

const DashboardPaymentsHistory = () => {
  const dispatch = useDispatch();

  const [currentSlug, setCurrentSlug] = useState("all");
  const [clientDetailsOb, setClientDetailsOb] = useState({
    id: 0,
    isVisible: false,
  });

  useEffect(() => {
    dispatch(getPaymentsHistory({ filter: currentSlug }));
  }, [dispatch, currentSlug]);

  const { payments_history } = useSelector(
    (state: RootState) => state.payments
  );

  const handleFilter = (slug: string) => {
    setCurrentSlug(slug);
  };

  const handlerOrder = async (type: string, field: string) => {
    await dispatch(
      getPaymentsHistory({ filter: currentSlug, order: { type, field } })
    );
  };

  const handleDescriptionLead = (id: any) => {
    const intID = parseInt(id);

    setClientDetailsOb((oldState) => ({
      ...oldState,
      isVisible: true,
      id: intID,
    }));
  };

  const handleClientDetailClose = () => {
    setClientDetailsOb((oldState) => ({
      ...oldState,
      isVisible: false,
    }));
  };

  return (
    <DashboardWrapper>
      <DashboardTitle style={{ marginBottom: "12px" }}>
        Payments history
      </DashboardTitle>

      <Filter
        items={FILTER_ITEM_PAYMENT_HISTORY}
        onSelectCallback={handleFilter}
      />

      <PaymentsHistoryTable
        data={payments_history}
        onHandleOrder={handlerOrder}
        onHandleDescriptionLead={handleDescriptionLead}
      />

      {clientDetailsOb.isVisible && (
        <ClientDetailsContainer
          onCloseCallback={handleClientDetailClose}
          userLeadId={clientDetailsOb.id}
        />
      )}
    </DashboardWrapper>
  );
};

export default DashboardPaymentsHistory;
