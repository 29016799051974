import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { FormTitle, HomeFormContainer } from "../../../styles";
import {
  RegisterFooterText,
  RegisterPrivacyText,
  RegisterPrivacyWrap,
} from "./style";
import { InputForgotLink } from "../../ui/Input/style";
import {
  DASHBOARD_AGENTS,
  LOGIN,
  REGISTER_CHOOSE,
} from "../../../constants/routes";
import { validateEmail } from "../../../constants/heplers";
import { register, registerTokenRestore } from "../../../slices/registerSlice";
import { RootState } from "../../../store";
import { Status, UserType } from "../../../types";
import Input from "../../ui/Input";
import ButtonSubmit from "../../ui/ButtonSubmit";
import Switcher from "../../ui/Switch";
import Checkbox from "../../ui/Checkbox";
import ErrorMessage from "../../ui/ErrorMessage";
import { SUM_SUB_API_URL } from "../../../constants/sumSub";
// @ts-ignore
import snsWebSdk from "@sumsub/websdk";
import { BtnSubmitLoadingImg } from "../../ui/ButtonSubmit/style";

const SWITCH_ITEMS = [
  {
    id: 1,
    name: "Agents",
  },
  {
    id: 2,
    name: "Payment system",
  },
];

interface Props {
  userType?: string;
  hash?: string;
}

const Register: React.FunctionComponent<Props> = ({ userType, hash }) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const {
    data: { type },
    status,
    isReg,
    message,
    sumsub_token,
  } = useSelector((state: RootState) => state.register);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    name: "",
    type: 1,
    isEmailValid: true,
    isPasswordValid: true,
    isNameValid: true,
    isCheck: true,
    isFirstCheck: true,
  });

  const handlerRegister = useCallback(async () => {
    if (!validateEmail(loginData.email)) {
      setLoginData(() => ({
        ...loginData,
        isEmailValid: false,
      }));
    }

    if (loginData.password.length <= 9) {
      setLoginData(() => ({
        ...loginData,
        isPasswordValid: false,
      }));
    }

    if (loginData.name.length <= 0) {
      setLoginData(() => ({
        ...loginData,
        isNameValid: false,
      }));
    }

    if (
      validateEmail(loginData.email) &&
      loginData.password.length > 9 &&
      loginData.name.length > 0 &&
      loginData.isCheck
    ) {
      let typeLead = loginData.type;

      if (userType) {
        if (userType === "token") {
          typeLead = UserType.TOKEN_HOLDER;
        }
      }

      await dispatch(
        register({
          email: loginData.email,
          password: loginData.password,
          type: typeLead,
          name: loginData.name,
        })
      );
    }
  }, [dispatch, loginData, userType]);

  useEffect(() => {
    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { keyCode } = event;

      if (keyCode === 13) {
        handlerRegister();
      }
    };

    if (hash) {
      dispatch(registerTokenRestore(hash));
    }

    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handlerRegister, hash, dispatch]);

  const handleInputChange = (
    type: string,
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const value = e.currentTarget.value;

    if (type === "password") {
      setLoginData(() => ({
        ...loginData,
        password: value,
        isPasswordValid: true,
      }));
    }

    if (type === "email") {
      setLoginData(() => ({
        ...loginData,
        email: value,
        isEmailValid: true,
      }));
    }

    if (type === "name") {
      setLoginData(() => ({
        ...loginData,
        name: value,
        isNameValid: true,
      }));
    }
  };

  const handlerSwitch = (type: number) => {
    setLoginData(() => ({
      ...loginData,
      type,
    }));
  };

  const handlerCheck = () => {
    setLoginData(() => ({
      ...loginData,
      isCheck: !loginData.isCheck,
    }));
  };
  function launchWebSdk(
    apiUrl: string,
    flowName: string,
    accessToken: string,
    applicantEmail: string,
    applicantPhone: string,
    customI18nMessages: string
  ) {
    let snsWebSdkInstance = snsWebSdk
      .Builder(apiUrl, flowName)
      .withAccessToken(accessToken, (newAccessTokenCallback: any) => {
        // @ts-ignore
        let newAccessToken = this.newAccessToken(); // get a new token from your backend
        newAccessTokenCallback(newAccessToken);
      })
      .withConf({
        lang: "en",
        email: applicantEmail,
        phone: applicantPhone,
        i18n: customI18nMessages,
        onMessage: (type: any, payload: any) => {
          if (type === "idCheck.applicantStatus") {
            if (payload.reviewResult?.reviewAnswer === "GREEN") {
              history.push(DASHBOARD_AGENTS);
            }
          }
        },
        uiConf: {
          customCss: "",
        },
        onError: (error: any) => {
          console.error("WebSDK onError", error);
        },
      })
      .build();
    snsWebSdkInstance.launch("#sumsub-websdk-container");
  }

  if (sumsub_token.token) {
    // @ts-ignore
    launchWebSdk(
      SUM_SUB_API_URL,
      loginData.type === 2 ? "KYB_test" : "KYC-KB",
      sumsub_token.token
    );
  }

  if (isReg && type !== UserType.TOKEN_HOLDER) {
    if (type === UserType.AGENT) return <Redirect to={DASHBOARD_AGENTS} />;
    if (type === UserType.PAYMENT) return <Redirect to={REGISTER_CHOOSE} />;
  }

  if (hash) {
    return (
      <HomeFormContainer
        style={{ marginTop: "41px" }}
        id="sumsub-websdk-container"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <BtnSubmitLoadingImg />
        </div>
      </HomeFormContainer>
    );
  }

  return (
    <HomeFormContainer
      style={{ marginTop: "41px" }}
      id="sumsub-websdk-container"
    >
      <FormTitle>Create Account</FormTitle>

      {!userType && (
        <div style={{ width: "100%", marginBottom: "24px" }}>
          <Switcher onCallbackChange={handlerSwitch} items={SWITCH_ITEMS} />
        </div>
      )}

      <div style={{ width: "100%", marginBottom: "24px" }}>
        <Input
          type="email"
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            handleInputChange("email", e)
          }
          placeholder=""
          labelText="Email"
        />
      </div>

      <div style={{ width: "100%", marginBottom: "24px" }}>
        <Input
          type="text"
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            handleInputChange("name", e)
          }
          placeholder=""
          labelText={loginData.type === 1 ? "Full name" : "Company name"}
        />
      </div>

      <div style={{ width: "100%", marginBottom: "24px" }}>
        <Input
          type="password"
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            handleInputChange("password", e)
          }
          placeholder=""
          labelText="Password"
          isError={!loginData.isPasswordValid}
          errorMessage="Your password is not strong enough. Your password must be at least 10 characters."
          showHint
          hintText="Your password needs to be at least 10 characters. Include multiple words and phrases to make it more secure."
        />
      </div>

      {status === Status.FAILED && (
        <div style={{ marginBottom: "24px" }}>
          <ErrorMessage errorText={message} />
        </div>
      )}

      {!loginData.isEmailValid && (
        <div style={{ marginBottom: "24px" }}>
          <ErrorMessage errorText="Please enter your email" />
        </div>
      )}

      {!loginData.isNameValid && (
        <div style={{ marginBottom: "24px" }}>
          <ErrorMessage errorText="Please enter your name" />
        </div>
      )}

      {!loginData.isFirstCheck && (
        <div style={{ marginBottom: "24px" }}>
          <ErrorMessage errorText="Сheckbox is not filled" />
        </div>
      )}

      <RegisterPrivacyWrap>
        <Checkbox onClick={handlerCheck} value={loginData.isCheck} />
        <RegisterPrivacyText>
          Get emails from Mig.finance about product updates, industry news, and
          events. If you change your mind, you can unsubscribe at any time.{" "}
          {/*<InputForgotLink onClick={() => history.push("/privacy")}>Privacy Policy</InputForgotLink> */}
        </RegisterPrivacyText>
      </RegisterPrivacyWrap>

      {(!loginData.isNameValid ||
        !loginData.isEmailValid ||
        !loginData.isPasswordValid) &&
        null}

      <div style={{ width: "100%", marginBottom: "36px" }}>
        <ButtonSubmit
          isLoading={status === Status.LOADING}
          title="Create account"
          onClick={handlerRegister}
          isDisable={
            !loginData.isNameValid ||
            !loginData.isEmailValid ||
            !loginData.isPasswordValid
          }
        />
      </div>

      <div style={{ display: "flex" }}>
        <RegisterFooterText>Have an account?</RegisterFooterText>
        <InputForgotLink onClick={() => history.push(LOGIN)}>
          Log In
        </InputForgotLink>
      </div>
    </HomeFormContainer>
  );
};

export default Register;
