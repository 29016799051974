import styled from "styled-components";
import ReactSlider from "react-slider";
import { poppinsFont } from "../../../../styles/fonts";

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;  
`;

export const StyledThumb = styled.div`
  height: 24px;
  width: 24px;
  background-color: #fe5728;
  border-radius: 50%;
  cursor: grab;
`;

export const StyledContainer = styled.div`  
  width: 100%;
`;

export const StyledTrack = styled.div<any>`
    top: 11px;
    bottom: 0;
    background: ${props => props.index === 1 ? 'rgba(146, 157, 194, 0.3)' : '#253B86'};
    height: 2px;
`;

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;  
`

export const SliderText = styled.p`
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #171717;
  margin: 0;
  padding: 0;
`

export const SliderValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: rgba(224, 232, 254, 0.5);
  border-radius: 4px;
  min-width: 70px;
  height: 28px;
  padding: 4px 10px;
`

export const SliderValueText = styled.p`
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #171717;
  margin: 0;
  padding: 0;
`

export const SliderSubText = styled.p`
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #929DC2;
  margin: 0;
  padding: 0;
`

export const SliderSubTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #929DC2;
  margin: 0;
  margin-bottom: 36px;
`
