import React from 'react';
import { useParams } from "react-router-dom";
import { HomeLayout } from "../../styles";
import HeaderHome from "../../components/layout/Header";
import Register from "../../components/layout/Register";
import Footer from "../../components/layout/Footer";


interface RegisterParams {
  userType: string | undefined;
  hash: string | undefined
}

const RegisterPage = () => {
  let { userType, hash } = useParams<RegisterParams>();

  return (
      <HomeLayout>
        <HeaderHome/>
        <Register userType={userType} hash={hash}/>
        <Footer />
      </HomeLayout>
  );
};

export default RegisterPage;
