import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getUserLeadsApi,
  getUserPaymentLeadsApi,
  leadChangeStatusApi,
} from "../api/user";
import {
  AgentLeadData,
  FilterOrderType,
  LeadByUser,
  LeadInfoData,
  ManagerAgentLeadData,
  Status,
} from "../types";
import {
  changeStatusManagerAgentApi, dashboardGetStatisticsChartApi, dashboardGetStatisticsLeadsApi,
  deleteLeadApi,
  deleteManagerAgentApi,
  getLeadInfoApi,
  getLeadsByUserApi,
  getManagerAgentLeadsApi,
  getManagerLeadsApi,
  updateLeadInfoApi,
} from "../api/leads";
import { getUser } from "./userSlice";

interface DashboardStatistics {
  leads: number,
  agents: number,
  paymentSystems: number
}

interface ChartData {
  cnt: number,
  day_month: string
}

export interface LeadsSliceState {
  error: boolean;
  message: string;
  data?: AgentLeadData[];
  data_payments?: AgentLeadData[];
  by_user: LeadByUser;
  lead_info: LeadInfoData | null;
  is_enough: true;
  managerAgentLeads: ManagerAgentLeadData[];
  status: Status;
  change_status: Status;
  change_status_decline: Status;
  lead_info_status: Status;
  dashboardStatistics: DashboardStatistics;
  chartData: ChartData[],
  chartDataAgents: ChartData[],
  chartDataPayments: ChartData[],
}

const initialState: LeadsSliceState = {
  status: Status.IDLE,
  change_status: Status.IDLE,
  change_status_decline: Status.IDLE,
  lead_info_status: Status.IDLE,
  error: false,
  message: "",
  data: [],
  data_payments: [],
  by_user: {
    name: "",
    user_leads: [],
  },
  lead_info: null,
  is_enough: true,
  managerAgentLeads: [],
  dashboardStatistics: {
    agents: 0,
    leads: 0,
    paymentSystems: 0
  },
  chartData: [],
  chartDataAgents: [],
  chartDataPayments: []
};

export const getLeads = createAsyncThunk(
  "leads/get-leads",
  async (data: { filter: string; order?: { type: string; field: string } }) => {
    return await getUserLeadsApi(data);
  }
);

export const getLeadsPayments = createAsyncThunk(
  "leads/get-payments",
  async (data: FilterOrderType) => {
    return await getUserPaymentLeadsApi(data);
  }
);

export const leadChangeStatus = createAsyncThunk(
  "leads/change-status",
  async (data: { status: string; id: number | string }, { dispatch }) => {
    const response = await leadChangeStatusApi(data.status, data.id);

    await dispatch(getLeadsPayments({ filter: "all" }));
    await dispatch(getUser());

    return response;
  }
);

export const leadChangeStatusDecline = createAsyncThunk(
  "leads/change-status-decline",
  async (data: { id: number | string }, { dispatch }) => {
    const response = await leadChangeStatusApi("decline", data.id);

    await dispatch(getLeadsPayments({ filter: "all" }));
    await dispatch(getUser());

    return response;
  }
);

export const getLeadsByUser = createAsyncThunk(
  "leads/get-leads-by-user",
  async (data: { user_lead_id: number; type?: number; filter?: string }) => {
    return await getLeadsByUserApi(data);
  }
);

export const getLeadInfo = createAsyncThunk(
  "leads/get-lead-info",
  async (data: { user_lead_id: number; type?: number }) => {
    return await getLeadInfoApi(data);
  }
);

export const updateLeadInfo = createAsyncThunk(
  "leads/update-lead-info",
  async (data: { user_lead_id: number; data: any }) => {
    return await updateLeadInfoApi(data);
  }
);

export const deleteLead = createAsyncThunk(
  "leads/delete-user-lead",
  async (data: { user_lead_id: number; type?: string }) => {
    return await deleteLeadApi(data);
  }
);

//MANAGER
export const getManagerLeads = createAsyncThunk(
  "leads/manager/get-leads",
  async (data: { filter: string; order?: { type: string; field: string } }) => {
    return await getManagerLeadsApi(data);
  }
);

export const leadManagerChangeStatus = createAsyncThunk(
  "leads/change-status",
  async (data: { status: string; id: number | string }, { dispatch }) => {
    return await leadChangeStatusApi(data.status, data.id);
  }
);

export const getManagerAgentLeads = createAsyncThunk(
  "leads/manager/get-manager-agent-leads",
  async (data: {
    filter: string;
    order?: { type: string; field: string };
    user_id?: number;
  }) => {
    return await getManagerAgentLeadsApi(data);
  }
);

export const deleteManagerAgent = createAsyncThunk(
  "leads/manager/delete-manager-agent",
  async (data: { user_id: number }) => {
    return await deleteManagerAgentApi(data);
  }
);

export const changeStatusManagerAgent = createAsyncThunk(
  "leads/manager/change-status-manager-agent",
  async (data: { user_id: number; status: string }) => {
    return await changeStatusManagerAgentApi(data);
  }
);

//DASHBOARD
export const dashboardGetStatisticsLeads = createAsyncThunk(
    "leads/dashboard/get-statistics",
    async () => {
      return await dashboardGetStatisticsLeadsApi();
    }
);

export const dashboardGetStatisticsChart = createAsyncThunk(
    "leads/dashboard/get-statistics-chart-leads",
    async (data: FilterOrderType) => {
      return await dashboardGetStatisticsChartApi(data);
    }
);

export const dashboardGetStatisticsChartAgents = createAsyncThunk(
    "leads/dashboard/get-statistics-chart-agents",
    async (data: FilterOrderType) => {
      return await dashboardGetStatisticsChartApi(data);
    }
);

export const dashboardGetStatisticsChartPayments = createAsyncThunk(
    "leads/dashboard/get-statistics-chart-payments",
    async (data: FilterOrderType) => {
      return await dashboardGetStatisticsChartApi(data);
    }
);

export const leadSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    clearLeadStatus: (state) => {
      state.change_status = Status.IDLE;
      state.change_status_decline = Status.IDLE;
    },
    clearEnough: (state) => {
      state.is_enough = true;
      state.status = Status.IDLE;
      state.change_status = Status.IDLE;
      state.change_status_decline = Status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeads.rejected, (state) => {
      state.status = Status.FAILED;
      state.error = true;
    });
    builder.addCase(getLeads.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      state.error = false;
      state.data = action.payload;
    });

    builder.addCase(getLeadsPayments.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      state.error = false;
      state.data_payments = action.payload;
    });

    builder.addCase(leadChangeStatus.rejected, (state) => {
      state.status = Status.FAILED;
      state.error = true;
      state.is_enough = true;
    });
    builder.addCase(leadChangeStatus.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      state.change_status = Status.SUCCEEDED;
      state.is_enough = action.payload.is_enough;
      state.error = false;
    });

    builder.addCase(leadChangeStatusDecline.rejected, (state) => {
      state.status = Status.FAILED;
      state.error = true;
      state.is_enough = true;
    });
    builder.addCase(leadChangeStatusDecline.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      state.change_status_decline = Status.SUCCEEDED;
      state.is_enough = action.payload.is_enough;
      state.error = false;
    });

    builder.addCase(getLeadsByUser.fulfilled, (state, action) => {
      state.by_user = action.payload;
    });

    builder.addCase(getLeadInfo.pending, (state) => {
      state.lead_info_status = Status.LOADING;
    });
    builder.addCase(getLeadInfo.rejected, (state) => {
      state.lead_info_status = Status.IDLE;
    });
    builder.addCase(getLeadInfo.fulfilled, (state, action) => {
      state.lead_info = action.payload;
      state.lead_info_status = Status.SUCCEEDED;
    });

    builder.addCase(getManagerLeads.rejected, (state) => {
      state.status = Status.FAILED;
      state.error = true;
    });
    builder.addCase(getManagerLeads.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      state.error = false;
      state.data = action.payload;
    });

    builder.addCase(getManagerAgentLeads.rejected, (state) => {
      state.status = Status.FAILED;
      state.error = true;
    });
    builder.addCase(getManagerAgentLeads.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      state.error = false;
      state.managerAgentLeads = action.payload;
    });

    builder.addCase(dashboardGetStatisticsLeads.fulfilled, (state, action) => {
      state.dashboardStatistics = action.payload;
    });

    builder.addCase(dashboardGetStatisticsChart.fulfilled, (state, action) => {
      state.chartData = action.payload;
    });
    builder.addCase(dashboardGetStatisticsChartAgents.fulfilled, (state, action) => {
      state.chartDataAgents = action.payload;
    });
    builder.addCase(dashboardGetStatisticsChartPayments.fulfilled, (state, action) => {
      state.chartDataPayments = action.payload;
    });
  },
});

export const { clearLeadStatus, clearEnough } = leadSlice.actions;

export default leadSlice.reducer;
