import React from 'react';
import { HomeLayout } from "../../../styles";
import HeaderHome from "../../../components/layout/Header";
import Footer from "../../../components/layout/Footer";
import UserStart from "../../../components/layout/User/UserStart";

const UserStartPage = () => {
  return (
      <HomeLayout>
        <HeaderHome/>
        <UserStart/>
        <Footer />
      </HomeLayout>
  );
};

export default UserStartPage;
