import styled from "styled-components";

export const UserFormProgressWrapper = styled.div<{ isFinish?: boolean }>`
  margin: 0 auto;  
  margin-top: 44px;
  display: flex;
  align-items: center;
  
  svg {
    fill: ${props => props.isFinish ? "#27AE60" : "#929DC2"};
  }
`

export const UserProgressContainer = styled.div`
  position: relative;
  width: 328px;
  height: 6px;
  background: rgba(146, 157, 194, 0.2);
  border-radius: 100px;
  margin-right: 12px;
`

export const UserProgressItem = styled.div<{width: number}>`
  position: absolute;
  top: 0;
  width: ${props => props.width + "%"};
  height: 6px;
  background: #929DC2;
  border-radius: 100px;
  margin-right: 12px;
`
