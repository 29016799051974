import React from 'react';
import { DashboardContent, DashboardLayout } from "../../styles";
import DashboardHeader from "../../components/layout/Dashboard/Header";
import DashboardAgents from "../../components/layout/Dashboard/Agents";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const DashboardPage = () => {

  const {data: {balance, ref_link, name}} = useSelector((state: RootState) => state.user);

  return (
      <DashboardLayout>
        <DashboardContent>
          <DashboardHeader
              showBalance
              showRef
              balance={balance}
              refLink={ref_link}
              name={name}
              showCommunity
          />
          <DashboardAgents />
        </DashboardContent>
      </DashboardLayout>
  );
};

export default DashboardPage;
