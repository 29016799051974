import styled from "styled-components";
import closeIcon from "../../../assets/close-icon.svg";
import { SlideUp } from "../../../styles/animations";

export const ResetPasswordWrapper = styled.div`
  margin: 0 auto;
  animation: ${SlideUp};
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
`
export const ResetPasswordClose = styled.a`
  position: absolute;
  top: 12px;
  right: 12px;  
`

export const ResetPasswordCloseIcon = styled.img.attrs({
  src: closeIcon,
  alt: "Close"
})`  
`
