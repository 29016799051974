import axios from "axios";
import {
  baseUrl, changeStatusManagerAgentPath, dashboardGetStatisticsChartPath, dashboardGetStatisticsPath, deleteManagerAgentPath, getLeadInfoPath,
  getLeadsByUserPath, getManagerAgentLeadsPath, getManagerLeadsPath,
  leadsAssignClientPath, updateLeadInfoPath, userLeadDeletePath,
} from "../constants/api";
import { axiosGlobalConfig } from "./auth";
import { FilterOrderType } from "../types";

export const leadsAssignClientApi = async (data: {
  user_lead_id: number;
  user_id: number;
  cost: number;
}) => {
  try {
    const response = await axios.post(
      `${baseUrl}${leadsAssignClientPath}`,
      data,
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const getLeadsByUserApi = async (data: {
  user_lead_id: number;
  type?: number;
  filter?: string
}) => {
  try {
    const response = await axios.post(
      `${baseUrl}${getLeadsByUserPath}`,
      data,
      axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const getLeadInfoApi = async (data: {
  user_lead_id: number;
  type?: number;
}) => {
  try {
    const response = await axios.post(
        `${baseUrl}${getLeadInfoPath}`,
        data,
        axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const updateLeadInfoApi = async (data: {
  user_lead_id: number;
  data: any
}) => {
  try {
    const response = await axios.post(
        `${baseUrl}${updateLeadInfoPath}`,
        data,
        axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const deleteLeadApi = async (data: {
  user_lead_id: number;
  type?: string
}) => {
  try {
    const response = await axios.post(
        `${baseUrl}${userLeadDeletePath}`,
        data,
        axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

//MANAGER
export const getManagerLeadsApi = async (data: { filter: string; order?: { type: string; field: string } }) => {
  try {
    const response = await axios.post(
        `${baseUrl}${getManagerLeadsPath}`,
        data,
        axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const getManagerAgentLeadsApi = async (data: { filter: string; order?: { type: string; field: string }; user_id?: number }) => {
  try {
    const response = await axios.post(
        `${baseUrl}${getManagerAgentLeadsPath}`,
        data,
        axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const deleteManagerAgentApi = async (data: { user_id: number }) => {
  try {
    const response = await axios.post(
        `${baseUrl}${deleteManagerAgentPath}`,
        data,
        axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const changeStatusManagerAgentApi = async (data: { user_id: number; status: string }) => {
  try {
    const response = await axios.post(
        `${baseUrl}${changeStatusManagerAgentPath}`,
        data,
        axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};


//DASHBOARD
export const dashboardGetStatisticsLeadsApi = async () => {
  try {
    const response = await axios.post(
        `${baseUrl}${dashboardGetStatisticsPath}`,
        {},
        axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};

export const dashboardGetStatisticsChartApi = async (data: FilterOrderType) => {
  try {
    const response = await axios.post(
        `${baseUrl}${dashboardGetStatisticsChartPath}`,
        data,
        axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};
