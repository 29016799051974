import styled from "styled-components";
import { poppinsFont } from "../../../styles/fonts";
import { blue, lightLightBlue } from "../../../styles/colors";

export const SwitcherContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 6px;
  border: 1px solid rgba(146, 157, 194, 0.2);
  box-sizing: border-box;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.2);
`

export const SwitcherItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;  
  height: 46px;
  user-select: none;
  outline: none;
  z-index: 1;

  :hover {
    cursor: ${props => !props.isActive ? "pointer" : "normal"};    
  }  
`

export const SwitcherTitle = styled.p<{ isActive: boolean }>`
  font-family: ${poppinsFont};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${props => props.isActive ? "#FFFFFF" : lightLightBlue};  
  transition: color 0.1s;

  :hover {
    cursor: ${props => !props.isActive ? "pointer" : "normal"};
    color: ${ props => !props.isActive ? blue : ""};
  }
`

export const SwitchMarker = styled.i<{ isActive?: boolean }>`
  position: absolute;
  top: 6px;  
  height: 46px;
  width: calc(50% - 6px);
  background-color: #253B86;
  transform: ${ props => props.isActive ? "translateX(0%)" : "translate(100%)"};
  transition: transform .1s;
  border-radius: 8px;
`
