import styled from "styled-components";
import { Link } from "react-router-dom";
import { poppinsFont } from "../../../../styles/fonts";
import { blue, lightLightBlue, orange } from "../../../../styles/colors";
import Icon from "../../../ui/Icon";

export const DashboardNavContainer = styled.div`
  margin-top: 50px;
  margin-left: 24px;
  width: 196px;
  margin-right: 62px;
`;

export const DashboardNavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
`;

export const DashboardNavItem = styled.li<{ isHere: boolean }>`
  position: relative;
  height: 24px;
  margin-bottom: 14px;

  ::before {
    display: ${(props) => (props.isHere ? "block" : "none")};
    position: absolute;
    top: 9px;
    left: -15px;
    content: "";
    height: 6px;
    width: 6px;
    background-color: ${orange};
    border-radius: 50%;
  }
`;

export const DashboardNavLink = styled(Link)<{ ishere: boolean }>`
  display: flex;
  align-items: center;
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.ishere ? blue : lightLightBlue)};
  text-decoration: none;

  svg {
    fill: ${(props) => (props.ishere ? blue : lightLightBlue)};
  }

  :hover {
    color: ${blue};

    svg {
      fill: ${blue};
    }
  }
`;

const Svg = styled(Icon)`
  height: 24px;
  width: 24px;
  margin-right: 6px;
`;

export const AllLeadsIcon = () => (
  <Svg>
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.80332 8.56068L8.92464 10.682C9.51043 11.2678 9.51043 12.2175 8.92464 12.8033L6.80332 14.9246C6.21753 15.5104 5.26778 15.5104 4.682 14.9246L2.56068 12.8033C1.97489 12.2175 1.97489 11.2678 2.56068 10.682L4.682 8.56068C5.26778 7.97489 6.21753 7.97489 6.80332 8.56068ZM18.8033 8.56068L20.9246 10.682C21.5104 11.2678 21.5104 12.2175 20.9246 12.8033L18.8033 14.9246C18.2175 15.5104 17.2678 15.5104 16.682 14.9246L14.5607 12.8033C13.9749 12.2175 13.9749 11.2678 14.5607 10.682L16.682 8.56068C17.2678 7.97489 18.2175 7.97489 18.8033 8.56068Z"

    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8033 14.5607L14.9246 16.682C15.5104 17.2678 15.5104 18.2175 14.9246 18.8033L12.8033 20.9246C12.2175 21.5104 11.2678 21.5104 10.682 20.9246L8.56068 18.8033C7.97489 18.2175 7.97489 17.2678 8.56068 16.682L10.682 14.5607C11.2678 13.9749 12.2175 13.9749 12.8033 14.5607ZM12.8033 2.56068L14.9246 4.682C15.5104 5.26778 15.5104 6.21753 14.9246 6.80332L12.8033 8.92464C12.2175 9.51043 11.2678 9.51043 10.682 8.92464L8.56068 6.80332C7.97489 6.21753 7.97489 5.26778 8.56068 4.682L10.682 2.56068C11.2678 1.97489 12.2175 1.97489 12.8033 2.56068Z"
    />
  </Svg>
);

export const AllPaymentIcon = () => (
  <Svg>
    <g clipPath="url(#clip0)">
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2292 8.6632C22.7626 8.80614 23.0792 9.35448 22.9363 9.88794L20.3481 19.5472C20.2051 20.0807 19.6568 20.3972 19.1233 20.2543L0.770742 15.3367C0.237276 15.1938 -0.0793066 14.6455 0.0636351 14.112L2.65183 4.45274C2.79477 3.91928 3.3431 3.60269 3.87657 3.74564L22.2292 8.6632ZM12.5352 8.13627C10.4014 7.5645 8.20802 8.83083 7.63625 10.9647C7.06448 13.0986 8.33081 15.2919 10.4647 15.8637C12.5985 16.4354 14.7919 15.1691 15.3637 13.0352C15.9354 10.9014 14.6691 8.70804 12.5352 8.13627Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 6C21.5523 6 22 6.44772 22 7V17C22 17.5523 21.5523 18 21 18H2C1.44772 18 1 17.5523 1 17V7C1 6.44772 1.44772 6 2 6H21ZM11.5 8C9.29086 8 7.5 9.79086 7.5 12C7.5 14.2091 9.29086 16 11.5 16C13.7091 16 15.5 14.2091 15.5 12C15.5 9.79086 13.7091 8 11.5 8ZM11.5 14C12.6046 14 13.5 13.1046 13.5 12C13.5 10.8954 12.6046 10 11.5 10C10.3954 10 9.5 10.8954 9.5 12C9.5 13.1046 10.3954 14 11.5 14Z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);

export const AgentIcon = () => (
  <Svg>
    <path
      opacity="0.3"
      d="M12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7C16 9.20914 14.2091 11 12 11Z"
    />
    <path d="M3.00065 20.1992C3.38826 15.4265 7.26191 13 11.9833 13C16.7712 13 20.7049 15.2932 20.9979 20.2C21.0096 20.3955 20.9979 21 20.2467 21C16.5411 21 11.0347 21 3.7275 21C3.47671 21 2.97954 20.4592 3.00065 20.1992Z" />
  </Svg>
);

export const ManagerIcon = () => (
  <Svg>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1597 8C19.1999 8 20.0664 8.79732 20.1528 9.83391L20.8195 17.8339C20.9112 18.9347 20.0932 19.9014 18.9925 19.9931C18.9372 19.9977 18.8818 20 18.8264 20H5.17358C4.06901 20 3.17358 19.1046 3.17358 18C3.17358 17.9446 3.17589 17.8892 3.18049 17.8339L3.84716 9.83391C3.93354 8.79732 4.80007 8 5.84025 8H18.1597ZM13.5 10H10.5C10.2238 10 9.99998 10.2239 9.99998 10.5V11.5C9.99998 11.7761 10.2238 12 10.5 12H13.5C13.7761 12 14 11.7761 14 11.5V10.5C14 10.2239 13.7761 10 13.5 10Z"
    />
    <path
      opacity="0.3"
      d="M10 8H8V7C8 5.34315 9.34315 4 11 4H13C14.6569 4 16 5.34315 16 7V8H14V7C14 6.44772 13.5523 6 13 6H11C10.4477 6 10 6.44772 10 7V8Z"
    />
  </Svg>
);

export const DashboardIcon = () => (
  <Svg>
    <path
      opacity="0.3"
      d="M9.5 4H5.5C4.67157 4 4 4.67157 4 5.5V9.5C4 10.3284 4.67157 11 5.5 11H9.5C10.3284 11 11 10.3284 11 9.5V5.5C11 4.67157 10.3284 4 9.5 4Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 13C10.3284 13 11 13.6716 11 14.5V18.5C11 19.3284 10.3284 20 9.5 20H5.5C4.67157 20 4 19.3284 4 18.5V14.5C4 13.6716 4.67157 13 5.5 13H9.5ZM18.5 13C19.3284 13 20 13.6716 20 14.5V18.5C20 19.3284 19.3284 20 18.5 20H14.5C13.6716 20 13 19.3284 13 18.5V14.5C13 13.6716 13.6716 13 14.5 13H18.5ZM18.5 4C19.3284 4 20 4.67157 20 5.5V9.5C20 10.3284 19.3284 11 18.5 11H14.5C13.6716 11 13 10.3284 13 9.5V5.5C13 4.67157 13.6716 4 14.5 4H18.5Z"
    />
  </Svg>
);
