import React, { useEffect, useState } from "react";
import { FormTitle, HomeFormContainer } from "../../../../styles";
import ButtonSubmit from "../../../ui/ButtonSubmit";
import Switcher from "../../../ui/Switch";
import { RegisterChooseHead } from "./style";
import MultiSelect from "../../../ui/MultiSelect";
import { Redirect } from "react-router-dom";
import {
  DASHBOARD_AGENTS,
  DASHBOARD_PAYMENTS,
} from "../../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../../../slices/countrySlice";
import { RootState } from "../../../../store";
import { userSetParam } from "../../../../slices/registerSlice";
import { Status } from "../../../../types";
import ErrorMessage from "../../../ui/ErrorMessage";

const SWITCH_ITEMS = [
  {
    id: 1,
    name: "Individuals",
  },
  {
    id: 2,
    name: "Businesses",
  },
];

const RegisterChoose = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state: RootState) => state.countries);

  const { status, isSetParam } = useSelector((state: RootState) => state.register);
  const { data: { type } } = useSelector((state: RootState) => state.user);

  const [error, setError] = useState(false);
  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);


  const [choose, setChoose] = useState({
    ids: new Array(0),
    type: 1,
  });


  const handleChangeCountry = (ids: Array<number>) => {
    setChoose(() => ({
      ...choose,
      ids
    }));
  }

  const handleChangeLeads = (id: number) => {
    setChoose(() => ({
      ...choose,
      type: id
    }));
  }

  const handleSubmit = async () => {

    if(!choose.ids.length) {
      setError(true);
      return;
    }

    await dispatch(userSetParam({type: choose.type, ids: choose.ids}));
  }

  console.log(isSetParam);

  if(isSetParam) {

    if (type === 1) {
      return <Redirect to={DASHBOARD_AGENTS}/>
    }

    if (type === 2) {
      return <Redirect to={DASHBOARD_PAYMENTS}/>
    }

  }

  return (
      <HomeFormContainer>

        <FormTitle style={{ textAlign: "left", maxWidth: "242px" }}>
          Choose parameters of your lead
        </FormTitle>

        <div style={{ width: "100%", marginBottom: "24px" }}>
          <RegisterChooseHead>
            Countries
          </RegisterChooseHead>

          <MultiSelect
              items={data}
              onCallbackChange={handleChangeCountry}
          />

        </div>
        <div style={{ marginBottom: "36px", width: "100%" }}>
          <RegisterChooseHead>
            Type of leads
          </RegisterChooseHead>
          <Switcher
              onCallbackChange={handleChangeLeads}
              items={SWITCH_ITEMS}
          />
        </div>

        <div style={{marginTop: "-24px", marginBottom: "12px"}}>
          {(error && !choose.ids.length) && <ErrorMessage errorText="Select a country" />}
        </div>

        <ButtonSubmit
            title={"Submit"}
            onClick={handleSubmit}
            isLoading={status === Status.LOADING}
        />
      </HomeFormContainer>
  );
};

export default RegisterChoose;
