import React from 'react';
import { HomeLayout } from "../../styles";
import HeaderHome from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import PasswordNew from "../../components/layout/PasswordNew";

const PasswordNewPage = () => {
  return (
      <HomeLayout>
        <HeaderHome/>
        <PasswordNew />
        <Footer />
      </HomeLayout>
  );
};

export default PasswordNewPage;
