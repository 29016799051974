import React, { useState } from 'react';
import { FormTitle, HomeFormContainer, ModalLayout } from "../../../styles";
import Input from "../../ui/Input";
import ButtonSubmit from "../../ui/ButtonSubmit";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearForgotState, newPassword } from "../../../slices/forgotSlice";
import { LOGIN } from "../../../constants/routes";
import { RootState } from "../../../store";
import { Status } from "../../../types";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const PasswordNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {isPasswordChange, status} = useSelector((state: RootState) => state.forgot)

  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(true);


  const query = useQuery()
  const token = query.get('token')

  const handlerChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    setPassword(value);
    setIsValid(true);
  }

  const handlerSubmit = () => {

    if (password.length > 0) {
      dispatch(newPassword({ password, token }));
    } else {
      setIsValid(false);
    }
  }

  return (
      <>
        <HomeFormContainer>
          <FormTitle>New password</FormTitle>

          <div style={{ width: "100%", marginBottom: "36px" }}>
            <Input
                labelText="Password"
                placeholder=""
                type="password"
                onChange={handlerChange}
                isError={!isValid}
            />
          </div>

          <ButtonSubmit
              title="Submit"
              onClick={handlerSubmit}
              isDisable={status === Status.LOADING}
          />

        </HomeFormContainer>
        <ModalLayout isShow={isPasswordChange}>
          <div style={{ margin: "0 auto" }}>
            <HomeFormContainer>
              <FormTitle style={{ textAlign: "left" }}>Your password was successfully changed</FormTitle>
              <ButtonSubmit title="Close" onClick={() => {
                dispatch(clearForgotState());
                history.push(LOGIN);
              }}/>
            </HomeFormContainer>
          </div>
        </ModalLayout>
      </>
  );
};

export default PasswordNew;
