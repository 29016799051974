import React, { useEffect, useState } from 'react';
import { SwitcherContainer, SwitcherItem, SwitcherTitle, SwitchMarker } from "./style";

type Item = {
  id: number,
  name: string
}

interface Props {
  onCallbackChange: (index: number) => void;
  items: Item[];
  currentSelected?: number
}

const Switcher: React.FunctionComponent<Props> = ({currentSelected, items, onCallbackChange}) => {

  const [current, setCurrent] = useState(currentSelected || 1);

  const handlerChange = (id: number) => {
    setCurrent(id);
    onCallbackChange(id);
  }

  useEffect(() => {
    if(currentSelected) {
      handlerChange(currentSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelected]);


  return (
      <SwitcherContainer>
        {items.map((item) => {
          return (
              <SwitcherItem
                  key={item.id + '_switch'}
                  isActive={item.id === current}
                  onClick={() => handlerChange(item.id)}
              >
                <SwitcherTitle
                    isActive={item.id === current}
                >
                  {item.name}
                </SwitcherTitle>
              </SwitcherItem>
          )
        })}
        <SwitchMarker isActive={current === 1} />
      </SwitcherContainer>
  );
};

export default Switcher;
