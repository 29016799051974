import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  DashboardTableWrapper,
  FormSubTitle,
  FormTitle,
  HomeFormContainer,
  ModalLayout,
} from "../../../../styles";
import Table from "../../../ui/Table";
import {
  changeStatusManagerAgent,
  deleteManagerAgent,
  getManagerAgentLeads,
} from "../../../../slices/leadtSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  TableActions,
  TableActionsItem,
  TableContainerWrapper,
} from "../../../ui/Table/style";
import useComponentVisible from "../../../../constants/hooks";
import { InputLabel } from "../../../ui/Input/style";
import ButtonSubmit from "../../../ui/ButtonSubmit";
import SelectSimple from "../../../ui/SelectSimple";
import NavManager from "../Navigation/NavManager";
import {
  PopupTransferBtnContainer,
  PopupTransferBtnWrap,
} from "../../../ui/PopupTransfer/style";
import ButtonDecline from "../../../ui/ButtonDecline";
import { TABLE_TITLES_MANAGER_AGENTS } from "../../../../constants/tableTitles";

const AGENT_STATUS = [
  {
    id: "active",
    name: "Active",
  },
  {
    id: "disable",
    name: "Disable",
  },
];

const DashboardManagerAgents = () => {
  const dispatch = useDispatch();

  const [actionsObj, setActionsObj] = useState({ x: 0, y: 0, id: 0 });
  const [showStatus, setShowStatus] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("reg");

  const refStatus = useRef<HTMLDivElement>(null);

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        refStatus.current &&
        !refStatus.current.contains(event.target as Node)
      ) {
        setShowStatus(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [refStatus]
  );

  useEffect(() => {
    dispatch(getManagerAgentLeads({ filter: "all" }));

    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { keyCode } = event;

      if (keyCode === 27) {
        setShowDelete(() => false);
        setIsComponentVisible(() => false);
        setShowStatus(() => false);
      }
    };

    document.addEventListener("keydown", handleUserKeyPress);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("keydown", handleUserKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, handleClickOutside]);

  const { managerAgentLeads } = useSelector((state: RootState) => state.leads);

  const handleChangeStatus = () => {
    setShowStatus(true);
  };

  const handleChange = async () => {
    await dispatch(
      changeStatusManagerAgent({
        user_id: actionsObj.id,
        status: currentStatus,
      })
    );
    await dispatch(getManagerAgentLeads({ filter: "all" }));
    setShowStatus(false);
  };

  const handleCurrentStatus = (value: number | string) => {
    setCurrentStatus(value.toString());
  };

  const handleDelete = async () => {
    await dispatch(deleteManagerAgent({ user_id: actionsObj.id }));
    await dispatch(getManagerAgentLeads({ filter: "all" }));

    setShowDelete(false);
  };

  const handleOrder = async (order: string, field: string) => {
    await dispatch(
      getManagerAgentLeads({ filter: "all", order: { type: order, field } })
    );
  };

  const findItem = managerAgentLeads.filter(
      (item: any) => item.id === actionsObj.id
  )[0];

  return (
    <div style={{ display: "inline-flex" }}>
      <NavManager />

      <DashboardTableWrapper>
        <TableContainerWrapper>
          <Table
            headTitles={TABLE_TITLES_MANAGER_AGENTS}
            tableItems={managerAgentLeads}
            onClickRow={() => {}}
            onClickCellAction={(e, id) => {
              const { left, top } = e.currentTarget.getBoundingClientRect();
              setIsComponentVisible(true);

              setActionsObj({ x: left, y: top, id });
            }}
            onOrderCallback={handleOrder}
          />
        </TableContainerWrapper>
      </DashboardTableWrapper>

      <ModalLayout isShow={showStatus}>
        <HomeFormContainer ref={refStatus} isModal>
          <FormTitle>Lead status</FormTitle>
          <InputLabel>Change to</InputLabel>

          <div style={{ marginBottom: "36px", width: "100%" }}>
            <SelectSimple
              items={AGENT_STATUS}
              onCallbackChange={handleCurrentStatus}
            />
          </div>

          <ButtonSubmit title="Change" onClick={handleChange} />
        </HomeFormContainer>
      </ModalLayout>

      <ModalLayout isShow={showDelete}>
        <HomeFormContainer isModal>
          <FormTitle style={{ marginBottom: 0 }}>Delete {findItem?.name}?</FormTitle>
          <FormSubTitle
            style={{
              fontSize: "16px",
              lineHeight: "28px",
              fontWeight: 500,
              marginTop: "12px",
              marginBottom: 0,
            }}
          >
            Are you sure? This action cannot be changed.
          </FormSubTitle>
          <PopupTransferBtnContainer style={{ marginTop: "36px" }}>
            <PopupTransferBtnWrap>
              <ButtonDecline
                title="Cancel"
                onClick={() => setShowDelete(false)}
              />
            </PopupTransferBtnWrap>

            <PopupTransferBtnWrap>
              <ButtonSubmit title="Delete" onClick={handleDelete} />
            </PopupTransferBtnWrap>
          </PopupTransferBtnContainer>
        </HomeFormContainer>
      </ModalLayout>

      <TableActions
        ref={ref}
        isVisible={isComponentVisible}
        x={actionsObj.x}
        y={actionsObj.y}
      >
        <TableActionsItem onClick={handleChangeStatus}>
          Change status
        </TableActionsItem>

        <TableActionsItem onClick={() => setShowDelete(true)}>
          Delete
        </TableActionsItem>
      </TableActions>
    </div>
  );
};

export default DashboardManagerAgents;
