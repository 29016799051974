import { DASHBOARD_MANAGER_AGENT_LEADS } from "./routes";

export const TABLE_TITLES_ADMIN_LEADS = [
  {
    title: "#",
    field_name: "index",
  },
  {
    title: "Date Referred",
    field_name: "date",
    order: "asc",
  },
  {
    title: "Company",
    field_name: "company",
    order: "asc",
  },
  {
    title: "Product",
    field_name: "product",
  },
  {
    title: "Status",
    field_name: "status",
    order: "asc",
  },
  {
    title: "Payment system",
    field_name: "payment_system",
    order: "asc",
  },
  {
    title: "Manager",
    field_name: "manager",
    order: "asc",
  },
  {
    title: "Agent",
    field_name: "agent",
    order: "asc",
  },
  {
    title: "Cost per Lead",
    field_name: "cost",
    order: "asc",
  },
  {
    title: "",
    field_name: "actions",
  },
];

export const TABLE_TITLES_ADMIN_PAYMENTS = [
  {
    title: "#",
    field_name: "index",
  },
  {
    title: "Payment system",
    field_name: "name",
    order: "asc",
  },
  {
    title: "Balance",
    field_name: "balance",
    order: "asc",
  },
  {
    title: "Status",
    field_name: "status",
    order: "asc",
  },
  {
    title: "Date of registration",
    field_name: "created_at",
    order: "asc",
  },
  {
    title: "Leads",
    field_name: "user_leads",
  },
  {
    title: "",
    field_name: "actions"
  },
];

export const TABLE_TITLES_ADMIN_AGENTS = [
  {
    title: "#",
    field_name: "index",
  },
  {
    title: "Agents",
    field_name: "name",
    order: "asc",
  },
  {
    title: "Manager",
    field_name: "manager",
    order: "asc",
  },
  {
    title: "Balance",
    field_name: "balance",
    order: "asc",
  },
  {
    title: "Status",
    field_name: "status",
    order: "asc",
  },
  {
    title: "Date of registration",
    field_name: "created_at",
    order: "asc",
  },
  {
    title: "Last leads",
    field_name: "user_leads",
  },
  {
    title: "",
    field_name: "actions",
  },
];

export const TABLE_TITLES_ADMIN_MANAGERS = [
  {
    title: "#",
    field_name: "index",
  },
  {
    title: "Managers",
    field_name: "name",
    order: "asc",
  },
  {
    title: "Balance",
    field_name: "balance",
    order: "asc",
  },
  {
    title: "Bonus",
    field_name: "bonus",
    order: "asc",
  },
  {
    title: "Leads",
    field_name: "user_leads_simple",
  },
  {
    title: "Agents",
    field_name: "user_leads_agents",
  },
  {
    title: "",
    field_name: "actions",
  },
];

export const TABLE_TITLES_ADMIN_MANAGER_LEADS = [
  {
    title: "#",
    field_name: "index",
  },
  {
    title: "Date Referred",
    field_name: "date",
  },
  {
    title: "Company",
    field_name: "company",
  },
  {
    title: "Product",
    field_name: "product",
  },
  {
    title: "Status",
    field_name: "status",
  },
  {
    title: "Payment system",
    field_name: "payment_system",
  },
  {
    title: "Manager",
    field_name: "manager",
  },
  {
    title: "Cost per Lead",
    field_name: "cost",
  },
  {
    title: "",
    field_name: "actions",
  },
];

export const TABLE_TITLES_AGENT_LEADS = [
  {
    title: "#",
    field_name: "index",
  },
  {
    title: "Date Referred",
    field_name: "date",
    order: "asc",
  },
  {
    title: "Company",
    field_name: "company",
    order: "asc",
  },
  {
    title: "Product",
    field_name: "product",
  },
  {
    title: "Status",
    field_name: "status",
    order: "asc",
  },
  {
    title: "Cost per Lead",
    field_name: "cost",
    order: "asc",
  },
];

export const TABLE_TITLES_PAYMENT = [
  {
    title: "#",
    field_name: "index",
  },
  {
    title: "Date",
    field_name: "date",
    order: "asc",
  },
  {
    title: "Name",
    field_name: "name",
    order: "asc",
  },
  {
    title: "Status",
    field_name: "status",
    order: "asc",
  },
  {
    title: "Country",
    field_name: "country",
  },
  {
    title: "Product",
    field_name: "product",
  },
  {
    title: "Industry",
    field_name: "industry",
    order: "asc",
  },
  {
    title: "Annual turnover",
    field_name: "annual",
    order: "asc",
  },
  {
    title: "Cost per lead",
    field_name: "cost",
    order: "asc",
  },
  {
    title: "",
    field_name: "button_actions",
  },
  {
    title: "",
    field_name: "actions",
  },
];

export const TABLE_TITLES_PAYMENT_HISTORY = [
  {
    title: "Date",
    field_name: "date",
    order: "asc",
  },
  {
    title: "Type",
    field_name: "type",
    order: "asc",
  },
  {
    title: "Description",
    field_name: "description_lead",
  },
  {
    title: "Amount",
    field_name: "amount_balance",
    order: "asc",
  },
];

export const TABLE_TITLES_MANAGER = [
  {
    title: "#",
    field_name: "index",
  },
  {
    title: "Date Referred",
    field_name: "date",
    order: "asc",
  },
  {
    title: "Company",
    field_name: "company",
    order: "asc",
  },
  {
    title: "Product",
    field_name: "product",
  },
  {
    title: "Status",
    field_name: "status",
    order: "asc",
  },
  {
    title: "Payment system",
    field_name: "payment_system",
    order: "asc",
  },
  {
    title: "Cost per Lead",
    field_name: "cost",
    order: "asc",
  },
  {
    title: "",
    field_name: "actions",
  },
];

export const TABLE_TITLES_MANAGER_AGENTS = [
  {
    title: "#",
    field_name: "index",
  },
  {
    title: "Agents",
    field_name: "name",
    order: "asc",
  },
  {
    title: "Balance",
    field_name: "balance",
    order: "asc",
  },
  {
    title: "Status",
    field_name: "status",
    order: "asc",
  },
  {
    title: "Date of registration",
    field_name: "date",
    order: "asc",
  },
  {
    title: "Last leads",
    field_name: "user_leads",
    path: DASHBOARD_MANAGER_AGENT_LEADS
  },
  {
    title: "",
    field_name: "actions",
  },
];

export const TABLE_TITLES_LEADS_VIEW = [
  {
    title: "#",
    field_name: "index",
  },
  {
    title: "Date Referred",
    field_name: "created_at",
  },
  {
    title: "Name",
    field_name: "name",
  },
  {
    title: "Country",
    field_name: "country",
  },
  {
    title: "Product",
    field_name: "product",
  },
  {
    title: "Industry",
    field_name: "industry",
  },
  {
    title: "Annual turnover",
    field_name: "annual_turnover",
  },
  {
    title: "Cost per lead",
    field_name: "cost",
  },
];

export const FILTER_ITEM_LEADS = [
  {
    name: "All",
    slug: "all",
  },
  {
    name: "Individuals",
    slug: "individual",
  },
  {
    name: "Business entity",
    slug: "business",
  },
];

export const FILTER_MANAGER_ITEMS = [
  {
    name: "All",
    slug: "all",
  },
  {
    name: "My",
    slug: "my",
  },
  {
    name: "Agent's",
    slug: "agents",
  },
  {
    name: "Public",
    slug: "public",
  },
];

export const FILTER_ITEM_PAYMENT_HISTORY = [
  {
    name: "All",
    slug: "all",
  },
  {
    name: "Top up",
    slug: "top_up",
  },
  {
    name: "Charge",
    slug: "charge",
  },
];
