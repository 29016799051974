import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCountriesApi, getCountriesPublicApi } from "../api/user";
import { CountryData, Status } from "../types";
import { getUserToken } from "../constants/api";

export interface CountryState {
  error: boolean,
  message: string,
  data: CountryData[],
  data_country: CountryData[],
  status: Status,
}

const initialState: CountryState = {
  status: Status.IDLE,
  error: false,
  message: "",
  data: [{
    id: 0,
    name: "",
    icon: "",
    code: "",
  }],
  data_country: []
};


export const getCountries = createAsyncThunk(
    "country/get",
    async () => {
      const token = getUserToken();
      return await getCountriesApi(token);
    }
);

export const getCountriesPublic = createAsyncThunk(
    "country/get-countries-public",
    async () => {
      return await getCountriesPublicApi();
    }
);

export const countriesSlice = createSlice({
  name: "country",
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(getCountries.pending, (state) => {
      state.status = Status.LOADING;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      state.data = action.payload;
    });

    builder.addCase(getCountriesPublic.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      state.data_country = action.payload;
    });
  }
})


export default countriesSlice.reducer;
