import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  DashboardTableWrapper,
  FormSubTitle,
  FormTitle,
  HomeFormContainer,
  ModalLayout,
} from "../../../../styles";
import Table from "../../../ui/Table";
import {
  deleteLead,
  getLeads,
  getManagerLeads,
  leadManagerChangeStatus,
} from "../../../../slices/leadtSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  TableActions,
  TableActionsItem,
  TableContainerWrapper,
} from "../../../ui/Table/style";
import useComponentVisible from "../../../../constants/hooks";
import { InputLabel } from "../../../ui/Input/style";
import ButtonSubmit from "../../../ui/ButtonSubmit";
import LeadAssignClient from "../../../../containers/LeadAssingClient";
import ClientDetailsContainer from "../../../../containers/ClientDetails";
import SelectSimple from "../../../ui/SelectSimple";
import Filter from "../../../ui/Filter";
import NavManager from "../Navigation/NavManager";
import LeadAssignAgent from "../../../../containers/LeadAssingAgent";
import {
  PopupTransferBtnContainer,
  PopupTransferBtnWrap,
} from "../../../ui/PopupTransfer/style";
import ButtonDecline from "../../../ui/ButtonDecline";
import { FILTER_MANAGER_ITEMS, TABLE_TITLES_MANAGER } from "../../../../constants/tableTitles";

const AGENT_STATUS = [
  {
    id: "reg",
    name: "Started registration",
  },
  {
    id: "reg_finish",
    name: "Identity verification",
  },
  {
    id: "verified",
    name: "Verified",
  },
  {
    id: "wait",
    name: "Pending",
  },
  {
    id: "approve",
    name: "Approved",
  },
  {
    id: "decline",
    name: "Decline",
  },
];

const DashboardManager = () => {
  const dispatch = useDispatch();

  const [actionsObj, setActionsObj] = useState({ x: 0, y: 0, id: 0 });
  const [showAssign, setShowAssign] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showAssingAgent, setShowAssingAgent] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("reg");
  const [currentSlug, setCurrentSlug] = useState("all");

  const refStatus = useRef<HTMLDivElement>(null);

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        refStatus.current &&
        !refStatus.current.contains(event.target as Node)
      ) {
        setShowStatus(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [refStatus]
  );

  useEffect(() => {
    dispatch(getManagerLeads({ filter: currentSlug }));

    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { keyCode } = event;

      if (keyCode === 27) {
        setShowAssign(() => false);
        setIsComponentVisible(() => false);
        setShowDetails(() => false);
        setShowStatus(() => false);
        setShowDelete(() => false);
      }
    };

    document.addEventListener("keydown", handleUserKeyPress);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("keydown", handleUserKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, handleClickOutside, currentSlug]);

  const { data } = useSelector((state: RootState) => state.leads);

  const handleChangeStatus = () => {
    setShowStatus(true);
  };

  const handleChange = async () => {
    await dispatch(
      leadManagerChangeStatus({ status: currentStatus, id: actionsObj.id })
    );

    await dispatch(getManagerLeads({ filter: currentSlug }));

    setShowStatus(false);
  };

  const handleCurrentStatus = (value: number | string) => {
    setCurrentStatus(value.toString());
  };

  const handleFilter = async (slug: string) => {
    await dispatch(getLeads({ filter: slug }));
    setCurrentSlug(slug);
  };

  const handleOrder = async (order: string, field: string) => {
    await dispatch(
      getManagerLeads({ filter: currentSlug, order: { type: order, field } })
    );
  };

  const handleDelete = async () => {
    await dispatch(deleteLead({user_lead_id: actionsObj.id}));
    await dispatch(
        getManagerLeads({ filter: currentSlug })
    );

    setShowDelete(false);
  };

  const findItem = data?.filter(
      (item: any) => item.id === actionsObj.id
  )[0];

  return (
    <div style={{ display: "inline-flex" }}>
      <NavManager />

      <DashboardTableWrapper>

        <Filter items={FILTER_MANAGER_ITEMS} onSelectCallback={handleFilter} />

        <TableContainerWrapper>
          <Table
            headTitles={TABLE_TITLES_MANAGER}
            tableItems={data ? data : []}
            onClickRow={() => {}}
            onClickCellAction={(e, id) => {
              const { left, top } = e.currentTarget.getBoundingClientRect();
              setIsComponentVisible(true);

              setActionsObj({ x: left, y: top, id });
            }}
            onOrderCallback={handleOrder}
          />
        </TableContainerWrapper>
      </DashboardTableWrapper>

      {showDetails && (
        <ClientDetailsContainer
          onCloseCallback={() => setShowDetails(false)}
          userLeadId={actionsObj.id}
        />
      )}

      {showAssign && (
        <LeadAssignClient
          isVisible={showAssign}
          userLeadId={actionsObj.id}
          onCloseCallback={() => {
            dispatch(getManagerLeads({ filter: currentSlug }));
            setShowAssign(false);
          }}
        />
      )}

      {showAssingAgent && (
        <LeadAssignAgent
          isVisible={showAssingAgent}
          userLeadId={actionsObj.id}
          onCloseCallback={() => {
            dispatch(getManagerLeads({ filter: currentSlug }));
            setShowAssingAgent(false);
          }}
          type="manager_agent"
        />
      )}

      <ModalLayout isShow={showStatus}>
        <HomeFormContainer ref={refStatus} isModal>
          <FormTitle>Lead status</FormTitle>
          <InputLabel>Change to</InputLabel>
          <div style={{ marginBottom: "36px", width: "100%" }}>
            <SelectSimple
                items={AGENT_STATUS}
                onCallbackChange={handleCurrentStatus}
            />
          </div>
          <ButtonSubmit title="Change" onClick={handleChange} />
        </HomeFormContainer>
      </ModalLayout>

      <ModalLayout isShow={showDelete}>
          <HomeFormContainer isModal>
            <FormTitle style={{ marginBottom: 0 }}>Delete {findItem?.company}?</FormTitle>
            <FormSubTitle
                style={{
                  fontSize: "16px",
                  lineHeight: "28px",
                  fontWeight: 500,
                  marginTop: "12px",
                  marginBottom: 0,
                }}
            >
              Are you sure? This action cannot be changed.
            </FormSubTitle>
            <PopupTransferBtnContainer style={{ marginTop: "36px" }}>
              <PopupTransferBtnWrap>
                <ButtonDecline
                    title="Cancel"
                    onClick={() => setShowDelete(false)}
                />
              </PopupTransferBtnWrap>
              <PopupTransferBtnWrap>
                <ButtonSubmit title="Delete" onClick={handleDelete} />
              </PopupTransferBtnWrap>
            </PopupTransferBtnContainer>
          </HomeFormContainer>
      </ModalLayout>

      <TableActions
        ref={ref}
        isVisible={isComponentVisible}
        x={actionsObj.x}
        y={actionsObj.y}
      >
        <TableActionsItem onClick={() => setShowDetails(true)}>
          Lead details
        </TableActionsItem>

        <TableActionsItem onClick={() => setShowAssign(true)}>
          Assign to payment system
        </TableActionsItem>
        <TableActionsItem onClick={() => setShowAssingAgent(true)}>
          Assign to agent
        </TableActionsItem>
        <TableActionsItem onClick={handleChangeStatus}>
          Change status
        </TableActionsItem>

        <TableActionsItem onClick={() => setShowDelete(true)}>
          Delete
        </TableActionsItem>
      </TableActions>
    </div>
  );
};

export default DashboardManager;
