import React from 'react';
import { BtnDeclineElement } from "./style";

interface Props {
  title: string;
  onClick: () => void;
  isDisable?: boolean;
}

const ButtonDecline: React.FunctionComponent<Props> = (props) => {
  return (
      <BtnDeclineElement onClick={props.onClick} disabled={props.isDisable}>
        {props.title}
      </BtnDeclineElement>
  );
};

export default ButtonDecline;
