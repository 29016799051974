import React from 'react';
import { HomeLayout } from "../../styles";
import HeaderHome from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import SelectUserType from "../../components/layout/SelectUserType";

const HomePage = () => {


  return (
      <HomeLayout>
        <HeaderHome/>
        <SelectUserType />
        <Footer />
      </HomeLayout>
  );
};

export default HomePage;
