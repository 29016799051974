import React, { useEffect } from "react";
import DashboardNav from "../../Navigation";
import {
  DashboardTableWrapper,
  DashboardTitle,
} from "../../../../../styles";
import Table from "../../../../ui/Table";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import Breadcrumb from "../../../../ui/Breadcrumb";
import { DASHBOARD_ADMIN_AGENTS } from "../../../../../constants/routes";
import { getLeadsByUser } from "../../../../../slices/leadtSlice";
import { TableContainerWrapper } from "../../../../ui/Table/style";
import { TABLE_TITLES_LEADS_VIEW } from "../../../../../constants/tableTitles";

interface Props {
  userLeadId: number;
}

const DashboardAdminViewLeads: React.FunctionComponent<Props> = ({
  userLeadId,
}) => {
  const dispatch = useDispatch();
  const { by_user } = useSelector((state: RootState) => state.leads);
  const {
    data: { is_admin },
  } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(getLeadsByUser({ user_lead_id: userLeadId }));
  }, [dispatch, userLeadId]);

  return (
    <div style={{ display: "flex" }}>
      <DashboardNav />
      <DashboardTableWrapper>
        <Breadcrumb
          rootPath={DASHBOARD_ADMIN_AGENTS}
          currentPage={by_user.name}
        />
        <DashboardTitle>{by_user.name}</DashboardTitle>

        <TableContainerWrapper>
          <Table
            headTitles={TABLE_TITLES_LEADS_VIEW}
            tableItems={by_user.user_leads}
            onClickRow={() => {}}
            onClickCellAction={(e, id) => {}}
            isAdmin={is_admin || false}
          />
        </TableContainerWrapper>
      </DashboardTableWrapper>
    </div>
  );
};

export default DashboardAdminViewLeads;
