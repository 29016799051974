import React from "react";
import {
  TableActionButton,
  TableCellActions,
  TableCellAmountBalance,
  TableContainer,
  TableNoData,
} from "./style";
import TableHeadRow from "./TableHead";
import TableCell from "./TableCell";
import { TableCellAllLeadLink, TableCellLeadLink } from "./TableCell/style";
import {
  DASHBOARD_ADMIN_MANAGER_AGENTS,
  DASHBOARD_ADMIN_MANAGER_LEADS,
  DASHBOARD_LEADS_VIEW,
  DASHBOARD_PAYMENTS,
} from "../../../constants/routes";
import { baseUrl } from "../../../constants/api";
import { LeadStatus } from "../../../types";
import locckIcon from "../../../assets/lock.svg";

interface Props {
  showActions?: boolean;
  onClickRow: (item: any) => void;
  onClickCellAction?: (
    e: React.SyntheticEvent<HTMLDivElement>,
    id: any
  ) => void;
  headTitles: Array<{
    title: string;
    field_name: string;
    order?: string;
    path?: string;
  }>;
  tableItems: Array<any>;
  isAdmin?: boolean;
  onOrderCallback?: (order: string, field: string) => void;
  showAcceptButton?: boolean;
  onAcceptCallback?: (id: any) => void;
  onDescriptionLeadCallback?: (id: any) => void;
}

const Table: React.FunctionComponent<Props> = (props) => {
  const renderTableCell = (item: any, index: number, isDecline?: boolean) => {
    return props.headTitles.map((title) => {
      if (title.field_name === "index") {
        return (
          <TableCell key={index + index + "_index"}>{index + 1}</TableCell>
        );
      }

      if (title.field_name === "status") {
        return (
          <TableCell
            typeStatus={item[title.field_name]}
            type="status"
            key={index + index + "_status"}
            className={isDecline ? "visible" : ""}
          >
            <p>
              {item[title.field_name] === "reg" ? "Started registration" : ""}
              {item[title.field_name] === "reg_finish"
                ? "Identity verification"
                : ""}
              {item[title.field_name] === "verified" ? "Verified" : ""}
              {item[title.field_name] === "approve" ? "Accepted" : ""}
              {item[title.field_name] === "wait" ? "Pending" : ""}
              {item[title.field_name] === "decline" ? "Declined" : ""}
              {item[title.field_name] === "active" ? "Active" : ""}
              {item[title.field_name] === "disable" ? "Disable" : ""}
            </p>
          </TableCell>
        );
      }

      if (title.field_name === "cost" || title.field_name === "balance") {
        return (
          <TableCell key={index + index + "_cost"}>
            {item[title.field_name] === 0
              ? " - "
              : `${item[title.field_name]} €`}
          </TableCell>
        );
      }

      if (title.field_name === "bonus") {
        return (
          <TableCell key={index + index + "_bonus"}>
            {item[title.field_name] === 0
              ? " - "
              : `${item[title.field_name]} %`}
          </TableCell>
        );
      }

      if (title.field_name === "amount_balance") {
        return (
          <TableCell key={index + Math.random() + "_amount_balance"}>
            {item[title.field_name] === 0 ? (
              " - "
            ) : (
              <TableCellAmountBalance balance={item[title.field_name]}>
                {item[title.field_name] > 0 ? "+" : ""} {item[title.field_name]}{" "}
                €
              </TableCellAmountBalance>
            )}
          </TableCell>
        );
      }

      if (title.field_name === "actions") {
        return (
          <TableCell
            className={
              isDecline ? "visible column-sticky-td" : "column-sticky-td"
            }
            key={index + Math.random() + "_actions"}
          >
            <div className="column-sticky-actions">
              <TableCellActions
                onClick={(e: React.SyntheticEvent<HTMLDivElement>) => {
                  if (props.onClickCellAction) {
                    props.onClickCellAction(e, item.id);
                  }
                }}
              />
            </div>
          </TableCell>
        );
      }

      if (title.field_name === "button_actions") {
        return (
          <TableCell
            className="column-sticky-btn-td"
            key={index + Math.random() + "_button_actions"}
          >
            {props.showAcceptButton && item["status"] === LeadStatus.PENDING && (
              <div className="column-sticky-actions-btn">
                <TableActionButton
                  onClick={() => {
                    if (props.onAcceptCallback) {
                      props.onAcceptCallback(item.id);
                    }
                  }}
                >
                  Accept
                </TableActionButton>
              </div>
            )}
          </TableCell>
        );
      }

      if (title.field_name === "user_leads") {
        return (
          <TableCell key={index + Math.random() + "_user_leads"}>
            {item[title.field_name] &&
              item[title.field_name].map((userLead: any) => {
                return (
                  <TableCellLeadLink
                    key={userLead.id + "_user_leads_link"}
                    to={
                      !!title.path
                        ? title.path + item.id
                        : DASHBOARD_LEADS_VIEW + item.id
                    }
                  >
                    {userLead.name}
                  </TableCellLeadLink>
                );
              })}
            {item[title.field_name] && item[title.field_name].length ? (
              <TableCellAllLeadLink
                to={
                  !!title.path
                    ? title.path + item.id
                    : DASHBOARD_LEADS_VIEW + item.id
                }
              >
                all leads
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.81846 5L0 8.88906L1.09077 10L6 5L1.09077 0L0 1.11094L3.81846 5Z"
                    fill="#FE5728"
                  />
                </svg>
              </TableCellAllLeadLink>
            ) : (
              "-"
            )}
          </TableCell>
        );
      }

      if (title.field_name === "description_lead") {
        return (
          <TableCell key={index + Math.random() + "_description_lead"}>
            {item[title.field_name] ? (
              <TableCellAllLeadLink
                to={DASHBOARD_PAYMENTS}
                onClick={(e: any) => {
                  e.preventDefault();

                  if (props.onDescriptionLeadCallback) {
                    props.onDescriptionLeadCallback(item["who"]);
                  }
                }}
              >
                {item[title.field_name]}
              </TableCellAllLeadLink>
            ) : (
              "-"
            )}
          </TableCell>
        );
      }

      if (title.field_name === "user_leads_simple") {
        return (
          <TableCell key={index + Math.random() + "_user_leads_simple"}>
            {item[title.field_name] && item[title.field_name].length ? (
              <TableCellAllLeadLink
                to={DASHBOARD_ADMIN_MANAGER_LEADS + item.id}
              >
                all leads
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.81846 5L0 8.88906L1.09077 10L6 5L1.09077 0L0 1.11094L3.81846 5Z"
                    fill="#FE5728"
                  />
                </svg>
              </TableCellAllLeadLink>
            ) : (
              "-"
            )}
          </TableCell>
        );
      }

      if (title.field_name === "user_leads_agents") {
        return (
          <TableCell key={index + Math.random() + "_user_leads_agents"}>
            {item[title.field_name] && item[title.field_name].length ? (
              <TableCellAllLeadLink
                to={DASHBOARD_ADMIN_MANAGER_AGENTS + item.id}
              >
                all agents
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.81846 5L0 8.88906L1.09077 10L6 5L1.09077 0L0 1.11094L3.81846 5Z"
                    fill="#FE5728"
                  />
                </svg>
              </TableCellAllLeadLink>
            ) : (
              "-"
            )}
          </TableCell>
        );
      }

      if (title.field_name === "country") {
        return (
          <TableCell key={index + Math.random() + "_country_row"}>
            {item[title.field_name] && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  height={16}
                  src={baseUrl + item[title.field_name].icon}
                  alt={item[title.field_name].name}
                  style={{ marginRight: "10px" }}
                />
                {item[title.field_name].name}
              </div>
            )}
          </TableCell>
        );
      }

      const isHidden =
        item[title.field_name] && item[title.field_name] === "hidden";

      return (
        <TableCell key={index + Math.random() + "_row_row"}>
          {isHidden ? (
            <img
              src={locckIcon}
              alt="Hidden"
              style={{ verticalAlign: "top" }}
            />
          ) : (
            item[title.field_name] || "-"
          )}
        </TableCell>
      );
    });
  };

  return (
    <>
      <TableContainer showActions={props.showActions}>
        <thead>
          <tr>
            {props.headTitles.map((head, index) => {
              return (
                <TableHeadRow
                  isSticky={head.field_name === "actions"}
                  isStickyBtn={head.field_name === "button_actions"}
                  key={index + "_head_table"}
                  order={head?.order}
                  onClickCallback={(order) => {
                    if (props.onOrderCallback) {
                      props.onOrderCallback(order, head.field_name);
                    }
                  }}
                >
                  {head.title}
                </TableHeadRow>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.tableItems.map((item, index) => {
            let isDecline = false;

            if (item["status"] && !props.isAdmin) {
              isDecline =
                item["status"] === LeadStatus.DECLINE ||
                item["status"] === LeadStatus.DISABLE;
            }
            return (
              <tr
                key={index + "_table_tr"}
                className={isDecline ? "decline" : ""}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  if (event.currentTarget === event.target)
                    props.onClickRow(item);
                }}
              >
                {renderTableCell(item, index, isDecline)}
              </tr>
            );
          })}
        </tbody>

      </TableContainer>
      {!props.tableItems.length && (
          <TableNoData>No data available in table</TableNoData>
      )}
    </>
  );
};

export default Table;
