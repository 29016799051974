import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  MultiSelectContainer,
  MultiSelectedImg,
  MultiSelectedText,
  MultiSelectInput,
  MultiSelectList,
  MultiSelectListItem,
  MultiSelectUpDown,
} from "../MultiSelect/style";
import { baseUrl } from "../../../constants/api";
import useComponentVisible from "../../../constants/hooks";
import { CountryData } from "../../../types";

interface Props {
  items: CountryData[];
  onCallbackChange: (id: string | number ) => void;
  initialSelected?: number;
}

const Select: React.FunctionComponent<Props> = ({
  items,
  onCallbackChange,
  initialSelected,
}) => {
  const [tmpItems, setTempItems] = useState(items);
  const [selected, setSelected] = useState(initialSelected ? initialSelected : 0);
  const [filterText, setFilterText] = useState("");

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  useEffect(() => {
    setTempItems(items);
    if (initialSelected) {
      setSelected(initialSelected);
      const findCountry = items.filter((item) => item.id === initialSelected );

      setFilterText(findCountry[0] ? findCountry[0].name : "");
    }
  }, [items, initialSelected]);

  useLayoutEffect(() => {
    onCallbackChange(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handlerInputFocus = () => {
    setIsComponentVisible(true);
  };

  const handleSelect = (id: number) => {
    setSelected(id);
    const findCountry = items.filter((item) => item.id === id );

    setFilterText(findCountry[0] ? findCountry[0].name : "");

    setIsComponentVisible(false);
  };

  return (
    <MultiSelectContainer ref={ref}>
      <div style={{ width: "100%", position: "relative" }}>
        <MultiSelectInput
          placeholder="Select"
          value={filterText}
          onFocus={handlerInputFocus}
          onInput={(e: React.SyntheticEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value || "";
            setFilterText(value);
            const re = new RegExp(value, "gi");

            const newItems = items.filter((item) => {
              if (item.name.match(re)) {
                return item;
              }
              return null;
            });
            setTempItems(newItems);
          }}
        />

        <MultiSelectUpDown
          onClick={() => setIsComponentVisible(true)}
          isOpen={isComponentVisible}
        />

        <MultiSelectList isVisible={isComponentVisible}>
          {tmpItems.map((item) => {
            return (
              <MultiSelectListItem
                key={item.id + "_country"}
                onClick={() => handleSelect(item.id)}
                isSelected={selected === item.id}
                style={{justifyContent: "flex-start"}}
              >
                <MultiSelectedImg
                  src={`${baseUrl}${item.icon}`}
                  alt={item.name}
                />
                <MultiSelectedText>{item.name}</MultiSelectedText>
              </MultiSelectListItem>
            );
          })}
        </MultiSelectList>
      </div>
    </MultiSelectContainer>
  );
};

export default Select;
