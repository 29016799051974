import styled from "styled-components";
import { poppinsFont } from "../../../styles/fonts";
import { lightLightBlue } from "../../../styles/colors";
import dotsImg from "../../../assets/dots.svg";
import dotsImgHover from "../../../assets/dots-hover.svg";

export const TableContainer = styled.table<{ showActions?: boolean }>`
  border-collapse: collapse;
  font-family: ${poppinsFont};
  overflow: scroll;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 20px;  
  
  thead, tr {    
    border-bottom: 1px solid rgba(146, 157, 194, 0.2);    
  }

  tbody {
    tr {
      height: 53px;

      td {
        padding-right: 24px;        
      }
      
      :nth-child( even ) {
        background: rgba(233, 235, 243, 0.2);

        .column-sticky-td {
          background: #FBFBFD;
        }

        .column-sticky-actions {
          background: #FBFBFD;
          box-shadow: -6px 0 6px rgba(37, 59, 134, 0.06);
        }

        .column-sticky-btn-td {
          background: #FBFBFD;
        }

        .column-sticky-actions-btn {
          background: #FBFBFD;
          box-shadow: -6px 0 6px rgba(37, 59, 134, 0.06);
        }

        :hover {
          background: rgba(224, 232, 254, 0.5);
        }

      }

      :hover {
        background: rgba(224, 232, 254, 0.5);

        .column-sticky-td {
          background: #EFF3FE;
          box-shadow: -6px 0 6px rgba(37, 59, 134, 0.06);
        }

        .column-sticky-actions {
          background: #EFF3FE;
          box-shadow: -6px 0 6px rgba(37, 59, 134, 0.06);
        }

        .column-sticky-btn-td {
          background: #EFF3FE;
        }

        .column-sticky-actions-btn {
          background: #EFF3FE;
          box-shadow: -6px 0 6px rgba(37, 59, 134, 0.06);
        }

      }      
    }
  }

  thead {
    border-radius: 12px;
    
    th {
      position: sticky;
      top: 0;      
      padding-right: 24px;
      padding-top: 14px;
      padding-bottom: 14px;
      color: #929DC2;
      z-index: 10;
      background: #FFFFFF;
      box-shadow: 0 6px 6px rgba(37, 59, 134, 0.06);
      
      :last-of-type {
        padding-right: ${props => props.showActions ? "48px" : "24px"};
      }
    }  
  }
  
  .column-sticky-th {
    position: sticky;
    right: 0;    
    padding: 0!important;
    width: 36px;
    box-shadow: -6px 0 6px rgba(37, 59, 134, 0.06);
  }

  .column-sticky-btn-th {
    position: sticky;
    right: 28px;    
    padding: 0!important;    
  }

  .column-sticky-th-div {
    box-shadow: -6px 0 6px rgba(37, 59, 134, 0.06); 
    height: 47px;
  }

  .column-sticky-td {
    position: sticky;
    right: 0;
    background: #FFFFFF;
    padding-right: 16px;
    max-width: 36px;
  }
  
  .column-sticky-actions {    
    height: 53px;    
    background: #FFFFFF;
    box-shadow: -6px 0 6px rgba(37, 59, 134, 0.06);
    padding-left: 16px;
    padding-top: 14px;
  }

  .column-sticky-btn-td {
    position: sticky;
    right: 28px;
    background: #FFFFFF;        
    padding-right: 0!important;
  }

  .column-sticky-actions-btn {
    display: flex;
    align-items: center;
    height: 53px;
    background: #FFFFFF;
    box-shadow: -6px 0 6px rgba(37, 59, 134, 0.06);
    padding-left: 8px;    
  }
 
  .decline {
    td {
      opacity: 0.2;
    }
  }
  
  .visible {
    opacity: 1!important;
  }

  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: #E9EBF3;
  }

  ::-webkit-scrollbar-thumb {
    background: #253B86;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-button {
    background: #f1f1f1;
  }
  
`

export const TableActions = styled.div<{ isVisible: boolean, x:number, y: number}>`
  position: absolute;  
  top: ${props => props.y + "px" };
  left: ${props => (props.x + 24) + "px" };
  display: ${props => props.isVisible ? "flex" : "none"};
  align-items: flex-start;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 6px 6px 24px rgba(37, 59, 134, 0.11);
  border-radius: 12px;
  padding: 12px;      
  z-index: 10;
`

export const TableActionsItem = styled.p`
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: ${lightLightBlue};
  padding: 0;
  margin: 0;
  margin-bottom: 6px;
  white-space: nowrap;

  :last-of-type {
    margin-bottom: 0;
  }
  
  :hover {
    cursor: pointer;
  }
`

export const TableCellActions = styled.div`  
  height: 24px;
  width: 4px;
  background-image: url("${dotsImg}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;  
  
  :hover {
    cursor: pointer;
    background-image: url("${dotsImgHover}");
  }
`

export const TableContainerWrapper = styled.div`
  width: 100%;  
  overflow: auto; 
  
  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: #E9EBF3;    
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(37, 59, 134, 0.2);    
  }

  ::-webkit-scrollbar-track {
    background: #E9EBF3;    
  }

  ::-webkit-scrollbar-button {
    
  }
`

export const TableActionButton = styled.button`
  font-family: ${poppinsFont};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  background: #FE5728;
  border-radius: 8px;
  height: 36px;
  border: none;
  margin-right: 16px;
  padding: 8px 16px;
`

export const TableActionWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;  
`

export const TableCellAmountBalance = styled.p<{ balance?: number }>`  
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  
  color: ${ props => props.balance && props.balance > 0 ? "#3AD496" : "#171717"};
  
`

export const TableNoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;  
  height: 52px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #7F7F7F;
  font-family: ${poppinsFont};
  border: 1px solid #E9EBF3;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: -20px;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`
