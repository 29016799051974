import styled, { css } from "styled-components";
import { PulseBlue, PulseBlueBlue, PulseGreen, PulseLightBlue, PulseOrange, PulseRed } from "../../../../styles/animations";
import { poppinsFont } from "../../../../styles/fonts";
import { Link } from "react-router-dom";

export const TableCellContainer = styled.td<{ showActions?: boolean }>`  
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;    
  white-space: nowrap;  
  padding-right: 24px;  

  :first-of-type {
    padding-left: 24px;    
  }

  :last-of-type {
    text-align: right;
    padding-right: ${props => props.showActions ? "48px" : "24px"};
  }
`

const statusReg = css`
  color: #929DC2;
  background-color: rgba(146, 157, 194, 0.12);
`

const statusRegFinish = css`
  color: #4690FF;
  background-color: rgba(70, 144, 255, 0.12);
`

const statusVerify = css`
  color: #6028FE;
  background-color: rgba(96, 40, 254, 0.12);
`

const statusPending = css`
  color: #FAB145;
  background-color: rgba(255, 181, 70, 0.12);
`

const statusApprove = css`
  color: #3AD496;
  background-color: rgba(58, 212, 150, 0.12);
`

const statusDecline = css`
  color: #FE2828;
  background: rgba(254, 40, 40, 0.12);
`

export const TableCellStatus = styled.div<{ typeStatus?: string }>`
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 20px;  
  border-radius: 20px;
  font-weight: normal;
  height: 28px;
  padding: 4px 12px;
  
  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
  }
    
  ${props => props.typeStatus === 'reg' ? statusReg : ""}
  ${props => props.typeStatus === 'reg_finish' ? statusRegFinish : ""}
  ${props => props.typeStatus === 'verified' ? statusVerify : ""}
  ${props => props.typeStatus === 'approve' ? statusApprove : ""}
  ${props => props.typeStatus === 'active' ? statusApprove : ""}
  ${props => props.typeStatus === 'wait' ? statusPending : ""}
  ${props => props.typeStatus === 'decline' ? statusDecline : ""}
  ${props => props.typeStatus === 'disable' ? statusDecline : ""}
`

export const TableCellStatusRegDot = styled.div`
  float: left;
  margin-top: 7px;
  height: 6px;
  width: 6px;
  background: #929DC2;
  border-radius: 50%;
  margin-right: 10px;
  animation: ${PulseLightBlue} 2s infinite;
`

export const TableCellStatusRegFinishDot = styled.div`
  float: left;
  margin-top: 7px;
  height: 6px;
  width: 6px;
  background: #4690FF;
  border-radius: 50%;
  margin-right: 10px;
  animation: ${PulseBlue} 2s infinite;
`

export const TableCellStatusApproveDot = styled.div`
  float: left;
  margin-top: 7px;
  height: 6px;
  width: 6px;
  background: #3AD496;
  border-radius: 50%;
  margin-right: 10px;
  animation: ${PulseGreen} 2s infinite;
`

export const TableCellStatusPendingDot = styled.div`
  float: left;
  margin-top: 7px;
  height: 6px;
  width: 6px;
  background: #FAB145;
  border-radius: 50%;
  margin-right: 10px;
  animation: ${PulseOrange} 2s infinite;
`

export const TableCellStatusVerifyDot = styled.div`
  float: left;
  margin-top: 7px;
  height: 6px;
  width: 6px;
  background: #253B86;
  border-radius: 50%;
  margin-right: 10px;
  animation: ${PulseBlueBlue} 2s infinite;
`

export const TableCellStatusDeclineDot = styled.div`
  float: left;
  margin-top: 7px;
  height: 6px;
  width: 6px;
  background: #FE2828;
  border-radius: 50%;
  margin-right: 10px;
  animation: ${PulseRed} 2s infinite;
`

export const TableCellLeadLink = styled(Link)`
  font-family: ${poppinsFont};
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  text-decoration-line: underline;
  color: #171717;
  margin-right: 8px;
  
  :last-of-type {
    margin-right: 0;
  }
`

export const TableCellAllLeadLink = styled(Link)`  
  font-family: ${poppinsFont};
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-decoration-line: none;
  color: #FE5728;
  margin-right: 8px;
  
  svg {
    margin-left: 8px;
  }
  
  :last-of-type {
    margin-right: 0;
  }
`
