import React from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { DashboardContent, DashboardLayout } from "../../../../styles";
import DashboardHeader from "../../../../components/layout/Dashboard/Header";
import DashboardAdminViewLeads from "../../../../components/layout/Dashboard/Admin/ViewLeads";
import { useParams } from "react-router-dom";

const DashboardAdminViewLeadsPage = () => {
  let { userLeadId } = useParams<{ userLeadId: string | undefined}>();

  const {data: { name}} = useSelector((state: RootState) => state.user);
  return (
      <DashboardLayout>
        <DashboardContent>
          <DashboardHeader refLink={null} name={name}/>
          <DashboardAdminViewLeads userLeadId={parseInt(userLeadId || "0")} />
        </DashboardContent>
      </DashboardLayout>
  );
};

export default DashboardAdminViewLeadsPage;
