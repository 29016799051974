import React, { useState } from "react";
import {
  TabBarProgress,
  TabBarProgressLine,
  TabBarTitle,
  TabBarWrapper,
  TabBarWrapperTitle,
} from "./styles";

type TabItem = {
  index: number;
  title: string;
};

interface Props {
  onChangeCallback: (state: number) => void;
  initialTab: number;
  tabItems: TabItem[];
}

const TabBar: React.FunctionComponent<Props> = ({
  onChangeCallback,
  initialTab,
  tabItems,
}) => {
  const [active, setActive] = useState(() => initialTab);

  const handleActive = (state: number) => {
    setActive(state);
    onChangeCallback(state);
  };

  return (
    <TabBarWrapper>
      <TabBarWrapperTitle>
        {tabItems.map((tabItem, index) => {
          return (
            <TabBarTitle
              key={index + "_tab_item"}
              isActive={active === tabItem.index}
              href="#"
              onClick={() => handleActive(tabItem.index)}
            >
              {tabItem.title}
              {active === tabItem.index && <TabBarProgressLine />}
            </TabBarTitle>
          );
        })}
      </TabBarWrapperTitle>

      <TabBarProgress />
    </TabBarWrapper>
  );
};

export default TabBar;
