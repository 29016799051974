import styled from "styled-components";
import { poppinsFont } from "../../../styles/fonts";

export const TabBarWrapper = styled.div`
  display: flex;
  flex-direction: column;  
  width: 100%;
  margin-bottom: 36px;
`

export const TabBarWrapperTitle = styled.div`
  display: flex;
  align-items: center; 
  
  width: 100%;  
`

export const TabBarTitle = styled.a<{ isActive: boolean}>`
  position: relative;
  font-family: ${poppinsFont};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.isActive ? "#253B86" : "#929DC2" } ;
  margin-right: 24px;  
  text-decoration: none;
  text-align: left;
  
  :hover {
    color: #929DC2;
  }
  
  :last-of-type {
    margin-right: 0;
  }
`

export const TabBarProgress = styled.div`
  position: relative;
  width: 100%;
  background-color: rgba(146, 157, 194, 0.2);
  box-sizing: border-box;
  height: 2px;
  margin-top: 12px;
`

export const TabBarProgressLine = styled.div<{ isActive?: boolean }>`
  position: absolute;
  top: 31.5px;
  left: 0;
  width: 100%;
  background-color: #253B86;
  box-sizing: border-box;
  height: 2px; 
  
  transition: transform .1s;
`
