import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setParamApi } from "../api/user";
import { RegisterData, Status, UserInfo, UserParamData } from "../types";
import { getUserToken } from "../constants/api";
import { registerApi, registerTokenRestoreApi } from "../api/auth";
import { getUser } from "./userSlice";

export interface UserState {
  error: boolean;
  message: string | undefined;
  data: UserInfo;
  status: Status;
  isReg: boolean;
  isSetParam: boolean;
  sumsub_token: { token: string; userId: string };
}

const initialState: UserState = {
  status: Status.IDLE,
  error: false,
  message: "",
  isReg: false,
  isSetParam: false,
  data: {
    id: 0,
    email: "",
    name: "",
    type: null,
    is_admin: false,
    created_at: "",
    avatar_url: "",
    ref_link: null,
    balance: 0,
  },
  sumsub_token: { token: "", userId: "" },
};

export const register = createAsyncThunk(
  "register/register",
  async (data: RegisterData, thunkAPI) => {
    const regResponse = await registerApi(data);
    await thunkAPI.dispatch(getUser());
    return regResponse;
  }
);

export const userSetParam = createAsyncThunk(
  "register/set-param",
  async (data: UserParamData, thunkAPI) => {
    const token = getUserToken();
    await setParamApi(token, data);
    return thunkAPI.dispatch(getUser());
  }
);

export const registerTokenRestore = createAsyncThunk(
  "register/token-restore",
  async (hash: string, thunkAPI) => {
    const response = await registerTokenRestoreApi(hash);
    await thunkAPI.dispatch(getUser());
    return response;
  }
);

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    clearReg: (state) => {
      state.isReg = false;
      state.isSetParam = false;
      state.status = Status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.status = Status.LOADING;
      state.isReg = false;
      state.message = "";
    });
    builder.addCase(register.rejected, (state, action) => {
      state.status = Status.FAILED;
      state.isReg = false;
      state.message = action.error.message;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      state.isReg = true;
      state.data.is_admin = action.payload?.data.is_admin || null;
      state.data.type = action.payload?.data.type || null;

      if (action.payload?.data?.sumsub_token) {
        state.sumsub_token = action.payload?.data?.sumsub_token;
      }
    });

    builder.addCase(userSetParam.pending, (state) => {
      state.status = Status.LOADING;
      state.isSetParam = false;
    });

    builder.addCase(userSetParam.rejected, (state) => {
      state.status = Status.FAILED;
      state.isSetParam = false;
    });

    builder.addCase(userSetParam.fulfilled, (state) => {
      state.status = Status.SUCCEEDED;
      state.isSetParam = true;
    });

    builder.addCase(registerTokenRestore.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;

      if (action.payload?.sumsub_token) {
        state.sumsub_token = action.payload?.sumsub_token;
      }
    });
  },
});

export const { clearReg } = registerSlice.actions;
