import React, { useState } from "react";
import {
  RadioGroupBgCheck,
  RadioGroupCheck,
  RadioGroupContainer,
  RadioGroupText,
  RadioGroupWrapper,
} from "./styles";

type Props = {
  onChangeCallback: (type: number) => void;
  items: Array<{ id: number; name: string }>;
  initial: number
};

const RadioGroupMulti = ({ onChangeCallback, items, initial }: Props) => {
  const [isClient, setIsClient] = useState(() => initial);

  const handleChange = (isClient: number) => {
    setIsClient(isClient);

    onChangeCallback(isClient);
  };

  return (
    <>
      {items.map((item, index) => {
        const isActive = item.id === isClient;

        return (
          <RadioGroupContainer
            key={index + "_radio_multi"}
            onClick={() => handleChange(item.id)}
            isActive={isActive}
          >
            <RadioGroupWrapper>
              <RadioGroupBgCheck isActive={isActive}>
                {isActive && <RadioGroupCheck />}
              </RadioGroupBgCheck>
              <RadioGroupText>
                {item.name}
              </RadioGroupText>
            </RadioGroupWrapper>
          </RadioGroupContainer>
        );
      })}
    </>
  );
};

export default RadioGroupMulti;
