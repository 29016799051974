import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProductsApi } from "../api/user";
import { ProductData, Status } from "../types";

export interface CountryState {
  error: boolean,
  message: string,
  data: ProductData[],
  status: Status,
}

const initialState: CountryState = {
  status: Status.IDLE,
  error: false,
  message: "",
  data: [{
    id: 0,
    name: "",
    icon: "",
  }],
};


export const getProducts = createAsyncThunk(
    "country/get",
    async () => {
      return await getProductsApi();
    }
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(getProducts.rejected, (state, action) => {
      state.status = Status.FAILED;
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      state.data = action.payload;
    });
  }
})


export default productSlice.reducer;
