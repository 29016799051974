import React from 'react';
import { DashboardContent, DashboardLayout } from "../../../styles";
import DashboardHeader from "../../../components/layout/Dashboard/Header";
import DashboardAdmin from "../../../components/layout/Dashboard/Admin";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const DashboardAdminPage = () => {
  const {data: { name}} = useSelector((state: RootState) => state.user);
  return (
      <DashboardLayout>
        <DashboardContent>
          <DashboardHeader refLink={null} name={name}/>
          <DashboardAdmin />
        </DashboardContent>
      </DashboardLayout>
  );
};

export default DashboardAdminPage;
