import styled from "styled-components";
import { poppinsFont } from "../../../../styles/fonts";

export const UserFormMessengerText = styled.p`
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #171717;
  margin: 0;
  padding: 0;
`
