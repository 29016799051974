import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from '../types';
import { newPasswordApi, resetPasswordApi } from '../api/auth';

interface ForgotState {
  error: boolean;
  email: string;
  message: string;
  status: Status;
  isSent: boolean;
  isPasswordChange: boolean;
}

const initialState: ForgotState = {
  status: Status.IDLE,
  email: '',
  error: false,
  message: '',
  isSent: false,
  isPasswordChange: false,
};

interface ResetData {
  password: string;
  token: string | null;
}

export const resetPassword = createAsyncThunk('passwordReset/reset-password', async (email: string) => {
  return await resetPasswordApi(email);
});

export const newPassword = createAsyncThunk('passwordReset/new-password', async (data: ResetData) => {
  return await newPasswordApi(data.password, data.token);
});

export const forgotSlice = createSlice({
  name: 'passwordReset',
  initialState,
  reducers: {
    clearForgotState: (state) => {
      state.status = Status.IDLE;
      state.isPasswordChange = false;
      state.email = "";
      state.isSent = false;
    },
    setForgotEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetPassword.pending, (state) => {
      state.status = Status.LOADING;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.isSent = false;
      state.status = Status.FAILED;
    });

    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.status = Status.SUCCEEDED;
      state.isSent = true;
      state.message = action.payload?.message || "";
    });

    builder.addCase(newPassword.pending, (state) => {
      state.isPasswordChange = false;
      state.status = Status.LOADING;
    });

    builder.addCase(newPassword.rejected, (state) => {
      state.isPasswordChange = false;
    });

    builder.addCase(newPassword.fulfilled, (state) => {
      state.status = Status.SUCCEEDED;
      state.isPasswordChange = true;
    });
  },
});

export const { clearForgotState, setForgotEmail } = forgotSlice.actions;
