import React, { useState } from 'react';
import { CopySvgImg, CopySvgImgSuc, RefLink, RefLinkContainer, RefLinkCopied, RefLinkText } from "./style";

interface Props {
  link: null | string;
  title?: string;
}

const ReferralLink: React.FunctionComponent<Props> = ({link, title = "Referral link:"}) => {

  const [copied, setCopied] = useState(false);

  const handlerCopy = () => {
    navigator.clipboard.writeText('https://dashboard.mig.finance' + link || "");
    setCopied(true);

    setTimeout(() => setCopied(false), 2000);
  }

  return (
      <RefLinkContainer isCopied={copied} onClick={handlerCopy}>

        {!copied &&
        <>
          <RefLinkText>
            {title}
          </RefLinkText>
          <RefLink>
            {link}
            <CopySvgImg/>
          </RefLink>
        </>
        }
        {copied && <>
          <RefLinkCopied>
            <RefLinkText>
              Referral link copy
            </RefLinkText>
          </RefLinkCopied>
          <CopySvgImgSuc />
        </>}

      </RefLinkContainer>
  );
};

export default ReferralLink;
