import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  MultiSelectContainer,
  MultiSelectedText,
  MultiSelectInput,
  MultiSelectList,
  MultiSelectListItem,
  MultiSelectUpDown,
} from "../MultiSelect/style";
import useComponentVisible from "../../../constants/hooks";
import { SelectSimpleData } from "../../../types";

interface Props {
  items: SelectSimpleData[];
  onCallbackChange: (id: string | number) => void;
  initialSelected?: number | string;
  customPlaceholder?: string;
}

const SelectSimple: React.FunctionComponent<Props> = ({
  items,
  onCallbackChange,
  initialSelected,
  customPlaceholder,
}) => {
  const [tmpItems, setTempItems] = useState(items);
  const [selected, setSelected] = useState<number | string>(
    initialSelected ? initialSelected : 0
  );
  const [filterText, setFilterText] = useState("");

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  useEffect(() => {
    setTempItems(items);
    if (initialSelected) {
      setSelected(initialSelected);
      const findCountry = items.filter((item) => item.id === initialSelected);

      setFilterText(findCountry[0] ? findCountry[0].name : "");
    }
  }, [items, initialSelected]);

  useLayoutEffect(() => {
    onCallbackChange(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handlerInputFocus = () => {
    setIsComponentVisible(true);
  };

  const handleSelect = (id: number | string) => {
    setSelected(id);
    const findCountry = items.filter((item) => item.id === id);

    setFilterText(findCountry[0] ? findCountry[0].name : "");

    setIsComponentVisible(false);
  };

  return (
    <MultiSelectContainer ref={ref}>
      <div style={{ width: "100%", position: "relative" }}>
        <MultiSelectInput
          customPlaceholder={!!customPlaceholder}
          placeholder={customPlaceholder ? customPlaceholder : "Select"}
          value={filterText}
          onFocus={handlerInputFocus}
          onInput={(e: React.SyntheticEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value || "";
            setFilterText(value);
            const re = new RegExp(value, "gi");

            const newItems = items.filter((item) => {
              if (item.name.match(re)) {
                return item;
              }
              return null;
            });
            setTempItems(newItems);
          }}
        />

        <MultiSelectUpDown
          onClick={() => setIsComponentVisible(true)}
          isOpen={isComponentVisible}
        />

        <MultiSelectList isVisible={isComponentVisible}>
          {tmpItems.map((item) => {
            return (
              <MultiSelectListItem
                key={item.id + "_select_simple"}
                onClick={() => handleSelect(item.id)}
                isSelected={selected === item.id}
              >
                <MultiSelectedText>{item.name}</MultiSelectedText>
              </MultiSelectListItem>
            );
          })}
        </MultiSelectList>
      </div>
    </MultiSelectContainer>
  );
};

export default SelectSimple;
