import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { DashboardContent, DashboardLayout } from "../../../../styles";
import DashboardHeader from "../../../../components/layout/Dashboard/Header";
import DashboardAdminManagers from "../../../../components/layout/Dashboard/Admin/Managers";

const DashboardAdminManagersPage = () => {
  const {
    data: { name },
  } = useSelector((state: RootState) => state.user);

  return (
    <DashboardLayout>
      <DashboardContent>
        <DashboardHeader refLink={null} name={name} />
        <DashboardAdminManagers />
      </DashboardContent>
    </DashboardLayout>
  );
};

export default DashboardAdminManagersPage;
