import React from 'react';
import { DashboardContent, DashboardLayout } from '../../../styles';
import DashboardHeader from '../../../components/layout/Dashboard/Header';
import DashboardPayments from '../../../components/layout/Dashboard/Payments';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const DashboardPaymentsPage = () => {

  const {
    data: { name, balance },
  } = useSelector((state: RootState) => state.user);

  return (
    <DashboardLayout>
      <DashboardContent>
        <DashboardHeader refLink={null} name={name} showBalance balance={balance} showCommunity />
        <DashboardPayments />
      </DashboardContent>
    </DashboardLayout>
  );
};

export default DashboardPaymentsPage;
