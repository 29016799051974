import styled from "styled-components";
import checkedIcon from "../../../assets/checked-icon-white.svg";

export const CheckboxLbl = styled.label`
  display: flex;
  align-items: center;  
  position: relative;  
  box-sizing: border-box;  
  user-select: none;  
  margin-right: 4px;  
  padding: 8px;   
  
  :hover {
    input ~ span {      
      border: 1px solid rgba(127, 127, 127, 0.4);
    }
  }
  
  input:checked ~ span {
    background-image: url("${checkedIcon}");
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid rgba(127, 127, 127, 0.4);
    background-color: #253B86;
  }

  input:checked ~ span::after {
    display: block;
  }
  
  span::after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;    
    transform: rotate(45deg);
  }
`

export const CheckboxMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid rgba(127, 127, 127, 0.2);
  border-radius: 4px;
  background-color: #fff;  
  
  :hover {
    cursor: pointer;
  }
`
export const CheckboxInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  height: 16px;
  width: 16px;
`
