import React, { useState } from "react";
import {
  InputContainer,
  InputElement,
  InputErrorText,
  InputEyeOff,
  InputEyeOn,
  InputEyeWrapper,
  InputForgotLink,
  InputLabel,
  InputPrePend,
  InputPrePendWrap,
  InputRow,
  InputRowWrapper,
} from "./style";
import errorIcon from "../../../assets/error-warning.svg";

interface Props {
  labelText: string;
  placeholder: string;
  type: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  showForgot?: boolean;
  showForgotCallback?: () => void;
  isError?: boolean;
  errorMessage?: string;
  showHint?: boolean;
  hintText?: string;
  isPrePend?: boolean;
  prePendText?: string | React.ReactElement;
  name?: string,
  isRequire?: boolean
}

const Input: React.FunctionComponent<Props> = (props) => {
  const [stateHint, setStateHint] = useState(false);
  const [prePendFocus, setPrePendFocus] = useState(false);
  const [showEye, setShowEye] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleBlur = () => {
    if (props.showHint) {
      setStateHint(false);
    }

    if (props.isPrePend) {
      setPrePendFocus(false);
    }
  };

  const handleFocus = () => {
    if (props.showHint) {
      setStateHint(true);
    }

    if (props.isPrePend) {
      setPrePendFocus(true);
    }

    if (props.type === "password") {
      setShowEye(true);
    }
  };

  const handleToggleEye = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    props.onChange(e);
  }

  return (
    <InputContainer>
      <InputRow>
        <InputLabel>{props.labelText}{props.isRequire && <span>*</span>}</InputLabel>
        {props.showForgot && (
          <InputForgotLink onClick={props.showForgotCallback}>
            Forgot a password?
          </InputForgotLink>
        )}
      </InputRow>
      <InputRow>
        <InputRowWrapper>
          <InputPrePendWrap isFocus={prePendFocus}>
            {props.isPrePend && (
              <InputPrePend isFocus={prePendFocus}>
                {props.prePendText}
              </InputPrePend>
            )}
            <InputElement
              placeholder={props.placeholder}
              isError={props.isError}
              onChange={handleChange}
              type={showPassword ? "text" : props.type}
              onBlur={handleBlur}
              onFocus={handleFocus}
              isPrePend={props.isPrePend}
              name={props.name}
            />
            {showEye && (
              <InputEyeWrapper onClick={handleToggleEye}>
                {showPassword ? <InputEyeOff /> : <InputEyeOn />}
              </InputEyeWrapper>
            )}
          </InputPrePendWrap>
          {props.isError && (
            <InputErrorText>
              <img src={errorIcon} alt="Error" />
              {props.errorMessage}
            </InputErrorText>
          )}
          {stateHint && !props.isError && (
            <InputErrorText isHint>{props.hintText}</InputErrorText>
          )}
        </InputRowWrapper>
      </InputRow>
    </InputContainer>
  );
};

export default Input;
