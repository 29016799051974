import styled from "styled-components";
import { Link } from "react-router-dom";
import { poppinsFont } from "../../../styles/fonts";
import arrowRight from "../../../assets/arrow-right.svg";

export const BreadWrapper = styled.div`
  margin-bottom: 16px;
`

export const BreadList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
`

export const BredListItem = styled.li`
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #929DC2;
  margin: 0;
  padding: 0;
  margin-right: 32px;
  
  :not(:last-of-type) {
    :after {
      position: absolute;
      margin-left: 6px;
      content: url("${arrowRight}");
    }
  }
`

export const BreadListItemLink = styled(Link)`
  font-family: ${poppinsFont};
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #253B86;
  text-decoration: none;
  
  
`
