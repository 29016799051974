import React from 'react';
import {
  AgentIcon,
  AllLeadsIcon,
  AllPaymentIcon,
  DashboardIcon,
  DashboardNavContainer,
  DashboardNavItem,
  DashboardNavLink,
  DashboardNavList,
  ManagerIcon
} from "./style";
import {
  DASHBOARD,
  DASHBOARD_ADMIN_AGENTS,
  DASHBOARD_ADMIN_MANAGER_LEADS,
  DASHBOARD_ADMIN_MANAGERS,
  DASHBOARD_ADMIN_PAYMENTS, DASHBOARD_STAT
} from "../../../../constants/routes";
import { useLocation } from "react-router-dom";

const DashboardNav = () => {

  const { pathname } = useLocation();

  return (
      <DashboardNavContainer>
        <DashboardNavList>
          <DashboardNavItem isHere={DASHBOARD_STAT === pathname}>
            <DashboardNavLink to={DASHBOARD_STAT} ishere={DASHBOARD_STAT === pathname}>
              <DashboardIcon />
              Dashboard
            </DashboardNavLink>
          </DashboardNavItem>
          <DashboardNavItem isHere={DASHBOARD === pathname}>
            <DashboardNavLink to={DASHBOARD} ishere={DASHBOARD === pathname}>
              <AllLeadsIcon />
              Leads
            </DashboardNavLink>
          </DashboardNavItem>
          <DashboardNavItem isHere={DASHBOARD_ADMIN_PAYMENTS === pathname}>
            <DashboardNavLink to={DASHBOARD_ADMIN_PAYMENTS} ishere={DASHBOARD_ADMIN_PAYMENTS === pathname}>
              <AllPaymentIcon />
              Payment systems
            </DashboardNavLink>
          </DashboardNavItem>
          <DashboardNavItem isHere={DASHBOARD_ADMIN_AGENTS === pathname}>
            <DashboardNavLink to={DASHBOARD_ADMIN_AGENTS} ishere={DASHBOARD_ADMIN_AGENTS === pathname}>
              <AgentIcon />
              Agents
            </DashboardNavLink>
          </DashboardNavItem>
          <DashboardNavItem isHere={DASHBOARD_ADMIN_MANAGERS === pathname || DASHBOARD_ADMIN_MANAGER_LEADS === pathname}>
            <DashboardNavLink to={DASHBOARD_ADMIN_MANAGERS} ishere={DASHBOARD_ADMIN_MANAGERS === pathname || DASHBOARD_ADMIN_MANAGER_LEADS === pathname}>
              <ManagerIcon />
              Managers
            </DashboardNavLink>
          </DashboardNavItem>
        </DashboardNavList>
      </DashboardNavContainer>
  );
};

export default DashboardNav;
