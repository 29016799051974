import axios from "axios";
import { baseUrl, getPaymentHistoryPath } from "../constants/api";
import { axiosGlobalConfig } from "./auth";
import { FilterOrderType } from "../types";

export const getPaymentsHistoryApi = async (data: FilterOrderType) => {
  try {
    const response = await axios.post(
        `${baseUrl}${getPaymentHistoryPath}`,
        data,
        axiosGlobalConfig()
    );

    return response.data.data;
  } catch (err) {
    return await Promise.reject("Failed get data!");
  }
};
