export enum Status {
  IDLE = 'idle',
  FAILED = 'failed',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
}

export enum LeadStatus {
  REG = 'reg',
  REG_FINISH = 'reg_finish',
  VERIFIED = 'verified',
  APPROVE = 'approve',
  PENDING = 'wait',
  DECLINE = 'decline',
  ACTIVE = 'active',
  DISABLE = 'disable',
}

export enum UserLeadType {
  INDIVIDUAL = 1,
  BUSINESS = 2,
}

export enum UserType {
  AGENT = 1,
  PAYMENT = 2,
  MANAGER = 3,
  TOKEN_HOLDER = 4,
}

export interface LoginData {
  email: string;
  password: string;
}


export interface RegisterData {
  email: string;
  password: string;
  type: number;
  name: string;
}

export type UserInfo = {
  id: number,
  email: string,
  name: string,
  type: number | null,
  is_admin: null | boolean,
  avatar_url: string,
  balance: number,
  ref_link: null | string,
  created_at: string
}

export type UserParamData = {
  type: number,
  ids: Array<number>
}

export type CountryData = {
  id: number,
  name: string,
  code: string,
  icon: string
}

export type ProductData = {
  id: number,
  name: string,
  icon: string
}

// USER_LEAD REG
export type UserLeadData = {
  ref_id: string | undefined,
  type_lead: number,
  name: string,
  email: string,
  phone: string,
  whatsup: boolean,
  telegram: boolean,
  company_name: string,
  company_website: string,
  linkedin?: string
}

export type UserLeadFeeData = {
  email: string;
  onboarding_fee: number;
  transaction_fee: number;
  month_maintenance: number;
}

export type UserLeadProductData = {
  email: string;
  product_ids: Array<Number>;
}

// END USER_LEAD REG

export type AgentLeadData = {
  id: number,
  date: string,
  company: string,
  product: string,
  status: string,
  cost: number,
  manager: string
}

export type ManagerAgentLeadData = {
  id: number,
  name: string,
  status: string,
  date: string,
  user_leads: Array<AgentTypeLead>,
}

export type AgentTypeLead = {
  id: number,
  name: string,
}

export type AgentType = {
  id: number,
  name: string,
  status: string,
  created_at: number,
  balance: number,
  user_leads: Array<AgentTypeLead>,
  percent: number,
}

export type ManagerType = {
  id: number,
  name: string,
  bonus: number,
  balance: number,
  user_leads: Array<AgentTypeLead>,
  user_leads_agents: Array<AgentTypeLead>,
}

export type AgentAssignType = {
  id: number,
  name: string,
  balance: number,
  percent: number,
}

export type PaymentSystemType = {
  id: number,
  name: string,
  email: string,
  status: string,
  balance: number
}

export type LeadUserData = {
  id: number,
  type_lead: number,
  name: string,
  email: string,
  company_name: string,
  company_website: string,
  created_at: string,
  product: string,
  industry?: string,
  annual_turnover?: string,
  cost: number,
  status: string,
  country: CountryData | {},
}

export type LeadByUser = {
  name: string;
  user_leads: Array<LeadUserData>
}

export interface LeadInfoData {
  [key: string]: string | number,
  id: number,
  type_lead: number,
  name: string,
  email: string,
  phone: string,
  company_name: string,
  company_website: string,
  created_at: string,
  status: string,
  jurisdiction: string,
  mainBusinessActiviti: string,
  uboCitizenship: string,
  yearEstablished: string,
  numberOfEmployees: string,
  financialInformation: string,
  paymentSentTo: string,
  typeOfTransactions: string,
  annualTurnover: string,
  incomingTransactions: string,
  outgoingTransactions: string,
  currenciesNeeded: string,
  accountsAtOtherBanks: string,
  license: string,
  in_payment: string,
  out_payment: string,
  month_payment: string,
  citizenship: string,
  residence: string,
  register_link: string
}

export type SelectSimpleData = {
  id: number | string;
  name: string;
}

export type FilterOrderType = {
  filter: string,
  order?: {
    type: string,
    field: string
  }
}

export type OrderType = {
  order?: {
    type: string,
    field: string
  }
}
